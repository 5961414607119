import classNames from 'classnames';
import React, { useContext } from 'react';
import * as events from '../../lib/analytics/events';
import { centsToDollars } from '../../lib/util';
import styles from '../../styles/lineItem.module.scss';
import ErrorMessage from '../ErrorMessage';
import RemoveLineItemButton from '../RemoveLineItemButton';
import { useDispatch } from 'react-redux';
import CheckoutContext from '../../lib/CheckoutContext';

interface CouponLineItemProps {
  allowRemoveCoupon?: boolean;
  appliedDiscountInCents?: number;
  couponCode: string;
  validationError?: string | null;
  appliedDiscountInMonths?: number;
}

export default function CouponLineItem({
  allowRemoveCoupon,
  appliedDiscountInCents,
  couponCode,
  validationError,
  appliedDiscountInMonths,
}: CouponLineItemProps) {
  const dispatch = useDispatch();
  const { checkoutActions } = useContext(CheckoutContext);

  if (validationError) {
    return null;
  }

  const couponCodeApplied = !!appliedDiscountInCents || !!appliedDiscountInMonths;

  return (
    <div className={classNames(styles.lineItem, { [styles.noRemove]: !allowRemoveCoupon })}>
      {allowRemoveCoupon && (
        <div className={styles.removeButtonRow}>
          <RemoveLineItemButton
            onClick={() => {
              dispatch(checkoutActions.removeCoupon({}));
              events.cartPage.couponRemoved(couponCode);
            }}
          />
        </div>
      )}
      <div className={styles.itemRow}>
        <div className={classNames(styles.lineItemImage, styles.empty)}></div>
        <div className={styles.lineItemBody}>
          <div className={styles.lineItemPriceRows}>
            <div className={styles.lineItemPriceRow}>
              <div>
                <div>Promo code {couponCodeApplied && `applied`}</div>
                <div>
                  <strong>{couponCode}</strong>
                </div>
                {!!appliedDiscountInMonths && (
                  <div className={styles.lineItemPrice}>
                    +{appliedDiscountInMonths} extra month{appliedDiscountInMonths > 1 && 's'} FREE (total)
                  </div>
                )}
              </div>
              {!!appliedDiscountInCents && !appliedDiscountInMonths && (
                <div className={styles.lineItemPrice}>-${centsToDollars(appliedDiscountInCents)}</div>
              )}
            </div>
            {!!validationError && (
              <div className={styles.lineItemPriceRow}>
                <ErrorMessage errors={[validationError]} />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
