import classNames from 'classnames';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { Image as FiImage } from '../../../../components/Image';
import { centsToDollars } from '../../../../lib/util';
import AppPaths from '../../../../AppPaths';
import { ReactComponent as AddIcon } from '../../../../assets/images/icons/add.svg';
import { ReactComponent as PetImagePlaceholderIcon } from '../../../../assets/images/icons/a_very_good_dog.svg';
import UpgradeablePet from '../../../../models/UpgradeablePet';
import styles from './PetList.module.scss';

interface PetListProps {
  darkBackground?: boolean;
  upgradeablePets: UpgradeablePet[];
}

function PetImage({ pet }: { pet: UpgradeablePet }) {
  if (!pet.imageUrl) {
    return (
      <div className={classNames(styles.petImage, styles.noImage)}>
        <PetImagePlaceholderIcon />
      </div>
    );
  }

  return (
    <div className={styles.petImage}>
      <FiImage
        image={{
          url: pet.imageUrl,
          retinaHeight: 160,
          retinaWidth: 160,
        }}
      />
    </div>
  );
}

export default function PetList({ darkBackground, upgradeablePets }: PetListProps) {
  const history = useHistory();

  return (
    <div
      className={classNames(styles.petList, {
        [styles.darkBackground]: darkBackground,
      })}
    >
      {upgradeablePets.map((pet) => {
        return (
          <div
            className={styles.pet}
            key={pet.moduleId}
            onClick={() => {
              history.replace(AppPaths.Series3Upgrade.ProductSelector(pet.moduleId));
            }}
          >
            <PetImage pet={pet} />
            <div className={styles.petDetails}>
              <div className={styles.petName}>{pet.name}</div>
              <div className={styles.petSubtitle}>
                Customize your Series 3 and get{' '}
                <span className={styles.creditAmount}>${centsToDollars(pet.proratedCreditAmountInCents)} credit</span>.
              </div>
            </div>
            <div className={styles.addIcon}>
              <AddIcon />
            </div>
          </div>
        );
      })}
    </div>
  );
}
