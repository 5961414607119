import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import AppPaths from '../../../AppPaths';
import ImageGallery from '../../../components/ImageGallery/ImageGallery';
import * as events from '../../../lib/analytics/events';
import { cartActions as storeCartActions } from '../../../reducers/storeCart';
import AddToBagButton from '../components/AddToBagButton';
import ProductTitle from '../components/ProductTitle';
import styles from '../ProductDetails.module.scss';
import classNames from 'classnames';

interface NanoDetailsProps {
  productName: string;
  priceInCents: number;
  sku: string;
}

export default function NanoDetails({ productName, priceInCents, sku }: NanoDetailsProps) {
  useEffect(() => {
    events.nanoViewed({ priceInCents, productName, sku });
  }, [priceInCents, productName, sku]);

  const dispatch = useDispatch();
  const history = useHistory();

  const onAddToBag = useCallback(() => {
    dispatch(storeCartActions.addSingleLineCartItem({ sku }));

    events.nanoAddedToBag({
      priceInCents,
      productName,
      sku,
    });

    history.push(AppPaths.Bag);

    return true;
  }, [dispatch, history, priceInCents, productName, sku]);

  return (
    <>
      <div className={styles.productContainer}>
        <div className={classNames(styles.imageGalleryContainer, styles.imageGallerySquare)}>
          <ImageGallery
            images={[
              {
                url: `/product_images/nano-20.jpg`,
                retinaWidth: 1200,
                retinaHeight: 1200,
              },
              {
                url: `/lifestyle_images/nano-20/01.jpg`,
                retinaWidth: 1200,
                retinaHeight: 1200,
              },
              {
                url: `/lifestyle_images/nano-20/02.jpg`,
                retinaWidth: 1200,
                retinaHeight: 1200,
              },
              {
                url: `/lifestyle_images/nano-20/03.jpg`,
                retinaWidth: 1200,
                retinaHeight: 1200,
              },
            ]}
            squareAspectRatio
          />
        </div>
        <div className={styles.productDetails}>
          <div className={styles.productDetailsHeader}>
            <div className={styles.productDetailsTitle}>
              <ProductTitle productName={productName} price={{ priceInCents }} />
            </div>
            <p className={styles.description}>
              A new generation of microchip. This box includes 20 ISO and ICAR registered Fi Nano microchips, small
              gauge needles and syringes. Internationally scannable on all existing scanners and shipped in air sealed
              envelopes to maintain sterile environment.
            </p>
          </div>

          <div className={styles.productAction}>
            <AddToBagButton onAddToBag={onAddToBag} />
          </div>
        </div>
      </div>
    </>
  );
}
