import React from 'react';

import Button from '../../../../../components/Button';
import ErrorMessage from '../../../../../components/ErrorMessage';
import * as events from '../../../../../lib/analytics/events';
import { ISupplementSubscriptionProduct, SupplementCheckoutFlow } from '../../../../../types';
import styles from '../ProductInfo/ProductInfo.module.scss';

interface SupplementPurchaseButtonsProps {
  selectedProduct: ISupplementSubscriptionProduct;
  purchaseMutationLoading: boolean;
  handlePurchase: () => Promise<void> | void;
  handleNoThanks?: () => void;
  showNoThanks?: boolean;
  error?: string;
  errorId?: string;
  flow: SupplementCheckoutFlow;
}

export default function SupplementPurchaseButtons({
  selectedProduct,
  purchaseMutationLoading,
  handlePurchase,
  handleNoThanks,
  error,
  errorId,
  showNoThanks = true,
  flow,
}: SupplementPurchaseButtonsProps) {
  return (
    <>
      {' '}
      <div className={styles.getItButtonContainer}>
        <div className={styles.selectedDetails}>
          <span>{selectedProduct.name}</span>
          {String.fromCharCode(183)}
          <span>
            {selectedProduct?.displayedRate}/{selectedProduct?.displayedRateUnit}
          </span>
          {String.fromCharCode(183)}
          <span>First bag FREE</span>
        </div>
        <div className={styles.buttons}>
          <Button
            className={styles.button}
            onClick={(evt) => {
              evt.preventDefault();
              events.supplements.getItClicked(flow);
              handlePurchase();
            }}
            disabled={purchaseMutationLoading}
          >
            Get Free Bag
          </Button>
          {showNoThanks && (
            <Button
              className={styles.button}
              secondary={true}
              onClick={() => {
                events.supplements.noThanksClicked();
                handleNoThanks && handleNoThanks();
              }}
            >
              No, Thanks
            </Button>
          )}
        </div>
        <div className={styles.error}>{error && <ErrorMessage errors={[error]} errorID={errorId} />}</div>
      </div>
    </>
  );
}
