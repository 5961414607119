import React from 'react';
import styles from '../../styles/SubscriptionContainer.module.scss';
import { usePurchaseSubscriptionContext } from '../../../PurchaseSubscription/context/PurchaseSubscriptionContext';
import Button from '../../../../components/Button';
import { gqlTypes } from '../../../../types';
import { maybeTruncatePetName } from '../helpers';
import SubscriptionAppBar from '../../components/SubscriptionAppBar';
import checkMarkImage from '../../../../assets/images/in_app_cancellation/check_mark_confetti.gif';
import checkMarkBackground from '../../../../assets/images/in_app_cancellation/check_mark_background.png';
import { inAppCancellation as events } from '../../../../lib/analytics/events';

export default function KeepMembershipConfirmation() {
  const { device } = usePurchaseSubscriptionContext();
  if (!device.pet) {
    throw new Error('No pet found.');
  }
  if (!device.subscription?.id) {
    throw new Error('No recurly subscription found.');
  }

  const petName = device.pet.name ? device.pet.name : 'your dog';
  const shortPetName = maybeTruncatePetName(petName);
  const petPronoun = device.pet.gender === gqlTypes.Gender.MALE ? 'his' : 'her';
  const petAvatarPath = device.pet.photos.first?.image?.fullSize;

  return (
    <>
      <div className={styles.fullWidthWrapper}>
        <SubscriptionAppBar
          backButtonAnalyticsEvent={() => events.keepMembershipConfirmationBack({ petId: device.pet?.id })}
          closeButtonAnalyticsEvent={() => events.keepMembershipConfirmationClose({ petId: device.pet?.id })}
        />
        <div className={styles.subscriptionContainer}>
          <h1>We're glad you and {shortPetName} are sticking with Fi!</h1>

          <div className={styles.content}>
            <p>
              Congratulate yourself for being the best dog parent possible by staying invested in {petName}'s long-term
              health.
            </p>
            <p>
              You let {petName} keep doing {petPronoun} thing, and we'll continue delivering the data you need to stay a
              step ahead.
            </p>

            {petAvatarPath && (
              <>
                <div className={styles.keepMembershipPetImage}>
                  <div className={styles.keepMembershipPetImages}>
                    <div className={styles.keepMembershipPetImageAvatar}>
                      <img src={petAvatarPath} alt={petName} />
                    </div>
                    <div className={styles.keepMembershipPetImageCheckMarkBackground}>
                      <img src={checkMarkBackground} alt="Check mark" />
                    </div>
                    <div className={styles.keepMembershipPetImageCheckMark}>
                      <img src={checkMarkImage} alt="Check mark" />
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
        <div className={styles.buttonContainer}>
          <Button
            className={styles.button}
            onClick={() => {
              window.open('fi://closeappview');
            }}
          >
            Done
          </Button>
          {/* Spacer ensures primary button is aligned with buttons on neighboring pages in in-app cancel flow */}
          <div className={styles.buttonSpacer}></div>
        </div>
      </div>
    </>
  );
}
