import React, { useState, ReactNode } from 'react';
import * as events from '../../../../../lib/analytics/events';

import styles from './Question.module.scss';

export default function Question({ title, children }: { title: string; children: ReactNode }) {
  const [expanded, setExpanded] = useState(false);

  return (
    <div className={styles.container}>
      <div
        className={styles.titleContainer}
        onClick={() => {
          setExpanded((value) => !value);
          events.series3CollarFAQExpand();
        }}
      >
        <div className={styles.titleText}>{title}</div>
        <div className={styles.toggle}>{expanded ? '-' : '+'}</div>
      </div>
      {expanded && <div className={styles.body}>{children}</div>}
    </div>
  );
}
