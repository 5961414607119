import React from 'react';

import { BillingSubscription } from '../../Supplements/SplashPage';
import Button from '../../../../components/Button';
import ConfirmModal from '../../../../components/modals/ConfirmModal';
import * as events from '../../../../lib/analytics/events';
import styles from '../../styles/SubscriptionContainer.module.scss';
import { supplementCancellationReason } from '../../../../types/gql-op-types';

type SubscriptionType = 'collar' | 'supplement';

interface ICancelConfirmationModalProps {
  onContinue: () => void;
  pageName: string;
  petId?: string;
  subscription?: BillingSubscription;
  trigger?: React.ReactElement;
  subscriptionType?: SubscriptionType;
  cancellationReason?: supplementCancellationReason;
  cancellationText?: string;
}

export default function CancelConfirmationModal({
  onContinue,
  pageName,
  petId,
  trigger,
  subscription,
  subscriptionType = 'collar',
  cancellationReason,
  cancellationText,
}: ICancelConfirmationModalProps) {
  const onAccept = () => {
    if (subscriptionType === 'collar') {
      events.inAppCancellation.cancelConfirmationModalContinue({ petId }, pageName);
    }
    if (subscriptionType === 'supplement') {
      events.supplementsManagement.cancelConfirmationModalContinue(
        { subscriptionId: subscription?.id, cancellationReason, cancellationText },
        pageName,
      );
    }
    onContinue();
  };
  const onDecline = () => {
    if (subscriptionType === 'collar') {
      events.inAppCancellation.cancelConfirmationModalGoBack({ petId }, pageName);
    }
    if (subscriptionType === 'supplement') {
      events.supplementsManagement.cancelConfirmationModalGoBack(
        { subscriptionId: subscription?.id, cancellationReason, cancellationText },
        pageName,
      );
    }
  };
  return (
    <ConfirmModal
      trigger={
        trigger ?? (
          <Button className={styles.button} tertiary={true}>
            Continue with Cancellation
          </Button>
        )
      }
      body={
        <>
          <h2>Are you sure?</h2>
          {subscriptionType === 'collar' ? (
            <p>This will create a customer support ticket to end your subscription with Fi.</p>
          ) : (
            <p>
              This will stop shipment and subscription of your {subscription?.subscriptionOption.inAppManagementName}{' '}
              supplement.
            </p>
          )}
          {subscriptionType === 'collar' && (
            <p>There will be a $20 reactivation fee if you decide to restart your subscription after you cancel.</p>
          )}
        </>
      }
      acceptText={'Yes, continue'}
      declineText={'No, go back'}
      onAccept={onAccept}
      onDecline={onDecline}
    />
  );
}
