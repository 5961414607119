import * as Sentry from '@sentry/react';
import * as gqlTypes from '../../types/gql-op-types';
import client from '../fi-api/client';
import analytics, { IdentifyTraits } from './index';
import * as types from '../../types';
import { userIdentityQuery } from '../../graphql-operations';
import { logInternalError } from '../errors';
import { removeEmpty } from '../util';

export function identifyUser(
  id: string,
  {
    name,
    email,
    billingInfo,
    shippingAddress,
  }: {
    name?: {
      fullName?: string;
      firstName?: string;
      lastName?: string;
    };
    email?: string;
    billingInfo?: Omit<types.BillingInfo, 'paymentInfo'> | null;
    shippingAddress?: Partial<types.Address> | null; // Partial becuase ApplePay shipping addresses don't always include all the info
  },
) {
  const traits: IdentifyTraits = {};

  if (email) {
    traits.email = email;
  }

  // Prioritize values given in the name object over the billing info object
  const firstName = name?.firstName ?? billingInfo?.firstName;
  const lastName = name?.lastName ?? billingInfo?.lastName;
  const fullName = name?.fullName ?? [firstName, lastName].filter((x) => !!x).join(' ');

  if (firstName) {
    traits.firstName = firstName;
  }

  if (lastName) {
    traits.lastName = lastName;
  }

  if (fullName) {
    traits.name = fullName;
  }

  const address = billingInfo?.address ?? shippingAddress;
  if (address) {
    const street = [address.line1, address.line2].filter((x) => !!x).join(' ');
    const partialAddress = removeEmpty({
      street: street.length > 0 ? street : undefined,
      city: address.city,
      state: address.state,
      postalCode: address.zip,
      country: address.country,
    });

    if (Object.keys(partialAddress).length > 0) {
      traits.address = partialAddress;
    }

    if (address.phone) {
      traits.phone = address.phone;
    }
  }

  // Only identify if we have traits
  if (Object.keys(traits).length > 0) {
    analytics.identify(id, traits);
  }

  // Also identify the user for Sentry
  Sentry.setUser({
    id,
    email,
  });
}

export async function lookupAndIdentifyUser(session: types.Session) {
  try {
    // Fetch the latest user identify data from the server
    // ignore any errors so even if we get partial data we can use it
    const { data } = await client.query<gqlTypes.userIdentity>({
      query: userIdentityQuery,
      fetchPolicy: 'network-only',
      errorPolicy: 'ignore',
    });

    identifyUser(session.userId, {
      email: session.email,
      billingInfo: data.currentUser.billingAccount?.billingInfo,
      shippingAddress: data.currentUser.billingAccount?.address,
    });
  } catch (err) {
    logInternalError(err);

    // Identifying the user is for analytics and is not mission critial for the site to work, so we won't rethrow,
    // but at this point we can at least identify the user's email from the session
    identifyUser(session.userId, { email: session.email });
  }
}
