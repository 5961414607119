import classNames from 'classnames';
import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import AppPaths from '../../AppPaths';
import { ReactComponent as CloseIcon } from '../../assets/images/close-navigation.svg';
import useDefaultProduct from '../../hooks/useDefaultProduct';
import * as types from '../../types';
import { series3CollarId, ProductCategory } from '../../types';
import Breadcrumbs from '../Breadcrumbs';
import styles from './AppBar.module.scss';
import LoginLogoutButton from './LoginLogoutButton';

interface IMainNavigationProps {
  dismissMobileMenu(): void;
}

function MainNavigation({ dismissMobileMenu }: IMainNavigationProps) {
  const isReseller = useSelector((state: types.AppState) => state.config.siteConfig.isReseller);
  const defaultProduct = useDefaultProduct();

  return (
    <div className={styles.nav}>
      <div className={styles.close}>
        <CloseIcon style={{ fill: 'black' }} onClick={dismissMobileMenu} className={styles.toggle} />
      </div>
      <div className={styles.container}>
        <div className={classNames(styles.linkGroup, styles.checkoutLinks)}>
          <Breadcrumbs />
        </div>
        <div className={classNames(styles.linkGroup, styles.storeLinks)}>
          {isReseller && defaultProduct.category === ProductCategory.MICROCHIP && (
            <Link to={AppPaths.Products(defaultProduct.id)} className={styles.link} onClick={dismissMobileMenu}>
              Fi Nano
            </Link>
          )}
          <Link to={AppPaths.Products(series3CollarId)} className={styles.link} onClick={dismissMobileMenu}>
            Series 3
          </Link>

          <Link to={AppPaths.Accessories} className={styles.link} onClick={dismissMobileMenu}>
            Accessories
          </Link>
          <Link to={AppPaths.Gift} className={styles.link} onClick={dismissMobileMenu}>
            Gift cards
          </Link>
        </div>
        <div className={classNames(styles.linkGroup, styles.marketingLinks)}>
          <a className={styles.link} href="https://tryfi.com/learn">
            How it Works
          </a>
          <a className={styles.link} href="https://tryfi.com/collar">
            Collar
          </a>
          <a className={styles.link} href="https://tryfi.com/theapp">
            App
          </a>
          <a className={styles.link} href="https://tryfi.com/sleeptracking">
            Sleep
          </a>
          <a className={styles.link} href="https://tryfi.com/reviews">
            Reviews
          </a>
          <a className={styles.link} href="https://blog.tryfi.com">
            Off Leash
          </a>
          <a className={styles.link} href="https://nano.tryfi.com">
            Nano
          </a>
        </div>
        <div className={classNames(styles.linkGroup, styles.accountLinks)}>
          <LoginLogoutButton onClick={dismissMobileMenu} />
        </div>
      </div>
    </div>
  );
}

export default MainNavigation;
