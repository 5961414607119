import classNames from 'classnames';
import React, { useCallback, useMemo, useRef } from 'react';
import { ReactComponent as ArrowIcon } from '../../assets/images/icons/arrow.svg';
import { sanitize } from '../../lib/util';
import styles from './SurveyQuestion.module.scss';

interface SurveyQuestionProps {
  question: string;
  onChoose: (answer: string) => void;
  options: string[];
  className?: string;
}

export default function SurveyQuestion({ question, onChoose, options, className }: SurveyQuestionProps): JSX.Element {
  const inputRef = useRef<HTMLInputElement>(null);

  const handleChoose = useCallback(
    (answer: string) => {
      onChoose(answer);
    },
    [onChoose],
  );

  const handleInputAndSubmit = useCallback(
    (input: string | undefined) => {
      if (!input) {
        return;
      }

      const answer = `Other-${sanitize(input)}`;
      handleChoose(answer);
    },
    [handleChoose],
  );

  const answers = useMemo(() => {
    const optionsWithoutOther = options.map((option) => {
      return (
        <div className={styles.option} onClick={() => handleChoose(option)} key={option}>
          {option}
        </div>
      );
    });

    /** Split out the response 'Other', because it accepts input so
     * requires different behavior from the other options.
     */
    const other = (
      <div className={styles.option}>
        Other
        <div className={classNames(styles.form)}>
          <input
            className={styles.input}
            type="text"
            placeholder={'Please specify'}
            required={true}
            ref={inputRef}
            maxLength={500}
          />
          <button
            type="submit"
            onClick={() => handleInputAndSubmit(inputRef?.current?.value)}
            className={styles.submit}
            aria-label="How did you hear about us"
          >
            <ArrowIcon width={64} height={64} />
          </button>
        </div>
      </div>
    );

    return (
      <>
        {optionsWithoutOther}
        {other}
      </>
    );
  }, [options, handleChoose, handleInputAndSubmit]);

  return (
    <div className={classNames([styles.surveyQuestionWrapper, className])}>
      <div className={styles.question}>{question}</div>
      {answers}
    </div>
  );
}
