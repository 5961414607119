import classNames from 'classnames';
import React, { useMemo, useEffect } from 'react';
import Popup from 'reactjs-popup';
import { centsToDollarsNumber, getPriceForRateUnit } from '../../lib/util';
import * as types from '../../types';
import Button from '../Button';
import styles from './SubscriptionUpsellModal.module.scss';
import { Image } from '../Image';
import * as events from '../../lib/analytics/events';

interface SubscriptionUpsellModalProps {
  heroImageUrl?: string;
  onAccept: () => void;
  onClose: () => void;
  onIgnore: () => void;
  open: boolean;
  selectedSubscription: types.ISubscriptionProduct;
  subscriptionToUpsell: types.ISubscriptionProduct;
  title?: string;
  upgradeValueProp?: string;
}

function subscriptionName(subscriptionProduct: types.ISubscriptionProduct) {
  if (subscriptionProduct.renewalMonths === 1) {
    return 'Monthly';
  }

  return `${subscriptionProduct.renewalMonths} Month`;
}

function subscriptionNameForCTA(subscriptionProduct: types.ISubscriptionProduct) {
  if (subscriptionProduct.renewalMonths === 1) {
    return 'Monthly';
  }

  return `${subscriptionProduct.renewalMonths} Months`;
}

function HeaderForSubscription({ subscriptionProduct }: { subscriptionProduct: types.ISubscriptionProduct }) {
  const { unit, displayRate } = getPriceForRateUnit(subscriptionProduct, 'week');
  return (
    <>
      {subscriptionName(subscriptionProduct)} - {displayRate}/{unit}
    </>
  );
}

function subscriptionTermDescription(subscriptionProduct: types.ISubscriptionProduct) {
  if (subscriptionProduct.renewalMonths === 1) {
    return 'month';
  }

  return `${subscriptionProduct.renewalMonths} months`;
}

export default function SubscriptionUpsellModal({
  heroImageUrl = '/upsell-hero.jpg',
  onAccept,
  onClose,
  onIgnore,
  open,
  selectedSubscription,
  subscriptionToUpsell,
  title = 'Prepay Your Membership and Save',
  upgradeValueProp = 'Prioritized Shipping',
}: SubscriptionUpsellModalProps) {
  const analyticsEventProperties: events.SubscriptionEventProps = useMemo(
    () => ({
      priceInCents: subscriptionToUpsell.priceInCents,
      productId: subscriptionToUpsell.id,
      productName: subscriptionToUpsell.name,
      sku: subscriptionToUpsell.sku,
    }),
    [subscriptionToUpsell],
  );

  useEffect(() => {
    if (open) {
      events.subscriptionUpsell.viewed(analyticsEventProperties);
    }
  }, [analyticsEventProperties, open]);

  return (
    <Popup className="s3-popup" onClose={onClose} open={open} closeOnDocumentClick={false}>
      <div className={styles.container}>
        <div className={styles.headerSection}>
          <h1>{title}</h1>
          <div className={classNames(styles.subscriptionHeader, styles.selectedSubscriptionHeader)}>
            <h2>
              <HeaderForSubscription subscriptionProduct={selectedSubscription} />
            </h2>
          </div>
          <div className={classNames(styles.subscriptionHeader, styles.subscriptionToUpsellHeader)}>
            <h2>
              <HeaderForSubscription subscriptionProduct={subscriptionToUpsell} />
            </h2>
            <div className={styles.upgradeValueProp}>{upgradeValueProp}</div>
          </div>
        </div>
        <div className={styles.heroImageSection}>
          <Image image={{ url: heroImageUrl }} />
        </div>
        <div className={styles.ctaSection}>
          <div className={styles.ctaButtons}>
            <Button
              className={styles.ctaPrimary}
              onClick={() => {
                events.subscriptionUpsell.acceptedUpsell(analyticsEventProperties);
                onAccept();
              }}
            >
              Upgrade to {subscriptionNameForCTA(subscriptionToUpsell)}
            </Button>
            <Button
              onClick={() => {
                events.subscriptionUpsell.ignoredUpsell(analyticsEventProperties);
                onIgnore();
              }}
              secondary
            >
              Continue with {subscriptionNameForCTA(selectedSubscription)}
            </Button>
          </div>
          <div className={styles.ctaFooter}>
            <p>
              {subscriptionName(subscriptionToUpsell)} membership is billed as $
              {centsToDollarsNumber(subscriptionToUpsell.priceInCents)} every{' '}
              {subscriptionTermDescription(subscriptionToUpsell)}.
            </p>
            <p>
              {subscriptionName(selectedSubscription)} memberships are billed as $
              {centsToDollarsNumber(selectedSubscription.priceInCents)} every{' '}
              {subscriptionTermDescription(selectedSubscription)}
            </p>
          </div>
        </div>
      </div>
    </Popup>
  );
}
