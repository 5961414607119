import AppPaths from '../../../AppPaths';
import SentryRoute from '../../../SentryRoute';
import { CartModeProvider, CartModes } from '../../../lib/cartModes';
import CancelSubscription from './CancelSubscription/CancelSubscription';
import RequestSent from './RequestSent/RequestSent';
import ChangeSubscription from './ChangeSubscription/ChangeSubscription';
import ContactCx from './ContactCx/ContactCx';
import PurchaseSubscriptionCheckout from '../../PurchaseSubscription/PurchaseSubscriptionCheckout/PurchaseSubscriptionCheckout';
import PurchaseSubscriptionComplete from '../../PurchaseSubscription/PurchaseSubscriptionComplete/PurchaseSubscriptionComplete';
import { PurchaseSubscriptionContextProvider } from '../../PurchaseSubscription/context/PurchaseSubscriptionContext';
import PurchaseSubscriptionPayment from '../../PurchaseSubscription/PurchaseSubscriptionPayment/PurchaseSubscriptionPayment';
import React from 'react';
import { Switch } from 'react-router';
import SubscriptionSplash from './SubscriptionSplash/SubscriptionSplash';
import UpdatePayment from './SubscriptionSplash/components/UpdatePayment/UpdatePayment';
import MultidogDiscount from './MultidogDiscount/MultidogDiscount';
import MultidogDiscountConfirmation from './MultidogDiscount/MultidogDiscountConfirmation';
import UpgradeSeries2 from './UpgradeSeries2/UpgradeSeries2';
import ReplaceCollar from './ReplaceCollar/ReplaceCollar';
import DogDoesNotRunAway from './DogDoesNotRunAway/DogDoesNotRunAway';
import FreeMonthOffer from './FreeMonthOffer/FreeMonthOffer';
import FreeMonthConfirmed from './FreeMonthOffer/FreeMonthConfirmed';
import PauseMonthOffer from './PauseMonthOffer/PauseMonthOffer';
import PauseMonthConfirmed from './PauseMonthOffer/PauseMonthConfirmed';
import KeepMembershipConfirmation from './KeepMembership/KeepMembershipConfirmation';
import DowngradeS2 from './DowngradeS2/DowngradeS2';
import DowngradeS2Confirmed from './DowngradeS2/DowngradeS2Confirmed';
import DowngradeS3 from './DowngradeS3/DowngradeS3';
import DowngradeS3Confirmed from './DowngradeS3/DowngradeS3Confirmed';
import TooExpensive from './TooExpensive/TooExpensive';
import TellUsMore from './TellUsMore/TellUsMore';
import MeetMakers from './MeetMakers/MeetMakers';
import RenewConfirmation from './RenewSubscription/RenewConfirmation';

export default function Subscription() {
  return (
    <PurchaseSubscriptionContextProvider>
      <CartModeProvider mode={CartModes.manageSubscription}>
        <Switch>
          <SentryRoute exact path={AppPaths.Subscription.SplashPageForDevice()} component={SubscriptionSplash} />
          <SentryRoute exact path={AppPaths.Subscription.PlanSelection()} component={ChangeSubscription} />
          <SentryRoute exact path={AppPaths.Subscription.RenewConfirmation()} component={RenewConfirmation} />
          <SentryRoute exact path={AppPaths.Subscription.Upgrade()} component={UpgradeSeries2} />
          <SentryRoute exact path={AppPaths.Subscription.Cancel()} component={CancelSubscription} />
          <SentryRoute exact path={AppPaths.Subscription.RequestSent()} component={RequestSent} />
          <SentryRoute exact path={AppPaths.Subscription.ContactCx()} component={ContactCx} />
          {/* The checkout flow mimics exactly the PurchaseSubscription checkout flow so we'll just reuse those components and functionality */}
          <SentryRoute exact path={AppPaths.Subscription.Checkout()} component={PurchaseSubscriptionCheckout} />
          <SentryRoute exact path={AppPaths.Subscription.Payment()} component={PurchaseSubscriptionPayment} />
          <SentryRoute exact path={AppPaths.Subscription.Complete()} component={PurchaseSubscriptionComplete} />
          <SentryRoute exact path={AppPaths.Subscription.UpdatePayment()} component={UpdatePayment} />
          <SentryRoute exact path={AppPaths.Subscription.MultidogDiscount()} component={MultidogDiscount} />
          <SentryRoute
            exact
            path={AppPaths.Subscription.MultidogDiscountConfirmation()}
            component={MultidogDiscountConfirmation}
          />
          <SentryRoute exact path={AppPaths.Subscription.Replace()} component={ReplaceCollar} />
          <SentryRoute exact path={AppPaths.Subscription.DogDoesNotRunAway()} component={DogDoesNotRunAway} />
          <SentryRoute exact path={AppPaths.Subscription.FreeMonthOffer()} component={FreeMonthOffer} />
          <SentryRoute exact path={AppPaths.Subscription.FreeMonthConfirmed()} component={FreeMonthConfirmed} />
          <SentryRoute exact path={AppPaths.Subscription.PauseMonthOffer()} component={PauseMonthOffer} />
          <SentryRoute exact path={AppPaths.Subscription.PauseMonthConfirmed()} component={PauseMonthConfirmed} />
          <SentryRoute
            exact
            path={AppPaths.Subscription.KeepMembershipConfirmation()}
            component={KeepMembershipConfirmation}
          />
          <SentryRoute exact path={AppPaths.Subscription.DowngradeS2()} component={DowngradeS2} />
          <SentryRoute exact path={AppPaths.Subscription.DowngradeS2Confirmed()} component={DowngradeS2Confirmed} />
          <SentryRoute exact path={AppPaths.Subscription.DowngradeS3()} component={DowngradeS3} />
          <SentryRoute exact path={AppPaths.Subscription.DowngradeS3Confirmed()} component={DowngradeS3Confirmed} />
          <SentryRoute exact path={AppPaths.Subscription.TooExpensive()} component={TooExpensive} />
          <SentryRoute exact path={AppPaths.Subscription.TellUsMore()} component={TellUsMore} />
          <SentryRoute exact path={AppPaths.Subscription.MeetMakers()} component={MeetMakers} />
        </Switch>
      </CartModeProvider>
    </PurchaseSubscriptionContextProvider>
  );
}
