import React, { useMemo } from 'react';
import styles from '../../styles/SubscriptionContainer.module.scss';
import SubscriptionAppBar from '../../components/SubscriptionAppBar';
import Button from '../../../../components/Button';
import { useHistory, useLocation } from 'react-router-dom';
import { usePurchaseSubscriptionContext } from '../../../PurchaseSubscription/context/PurchaseSubscriptionContext';
import * as gqlTypes from '../../../../types/gql-op-types';
import { inAppCancellation as events } from '../../../../lib/analytics/events';
import AppPaths from '../../../../AppPaths';
import { useMutation, useQuery } from '@apollo/client';
import { downgradeS3Mutation, getDowngradeablePlans } from '../../../../graphql-operations';
import { getLoadingOrErrorElement } from '../../../../lib/util';
import Chooser from '../../../../components/Chooser';
import { useDispatch, useSelector } from 'react-redux';
import { cartActions } from '../../../../reducers/subscriptionCart';
import { useSubscriptionChooserGroups } from '../../../../components/SubscriptionOptions/SubscriptionOptions';
import { useSubscriptionCartMode } from '../../../../lib/cartModes';
import { SubscriptionOption } from '../../../../components/SubscriptionOptionDetails';
import { gqlBillingCadenceToBillingCadence } from '../../../../lib/subscription';

export interface DowngradeS3State {
  selectedCancelReason: gqlTypes.zendeskCancellationReason;
}

export default function DowngradeS3() {
  const { device } = usePurchaseSubscriptionContext();
  const dispatch = useDispatch();
  const history = useHistory();
  const { cartSelector } = useSubscriptionCartMode();
  const cart = useSelector(cartSelector);
  const location = useLocation<DowngradeS3State>();
  const { selectedCancelReason } = location.state;
  if (!device.pet) {
    throw new Error('No pet found');
  }

  events.pageLoad({ petId: device.pet.id }, 'Downgrade S3');

  const { data } = useQuery<
    gqlTypes.ECOMMERCE_getAvailableS3DowngradeableSubscriptionOptions,
    gqlTypes.ECOMMERCE_getAvailableS3DowngradeableSubscriptionOptionsVariables
  >(getDowngradeablePlans, {
    variables: { input: { moduleId: device.moduleId } },
  });

  const applicableSubscriptionProducts: SubscriptionOption[] = useMemo(() => {
    const result: SubscriptionOption[] = [];
    if (!data) {
      return result;
    }
    const subOptions = data.getAvailableS3DowngradeableSubscriptionOptions.subscriptionOptions;
    for (const option of subOptions) {
      if (!option) {
        continue;
      }
      result.push({
        ...option,
        billingCadence: gqlBillingCadenceToBillingCadence(option.billingCadence),
      });
    }
    return result;
  }, [data]);

  const selectedSubscriptionSku = useMemo(() => {
    const cartItem = Object.values(cart.cartItems)[0];
    if (!cartItem) {
      return undefined;
    }

    return cartItem.lineItem.sku;
  }, [cart.cartItems]);

  const chooserGroups = useSubscriptionChooserGroups({
    disablePrioritizedShippingUpsell: true,
    subscriptionProducts: applicableSubscriptionProducts,
    showUpsellsInChooser: true,
  });

  const currentSubscriptionSku = device.subscription?.subscriptionOption.sku;

  const [downgradeS3, downgradeS3State] = useMutation<
    gqlTypes.ECOMMERCE_downgradeS3,
    gqlTypes.ECOMMERCE_downgradeS3Variables
  >(downgradeS3Mutation, {
    onCompleted: (downgradeData) => {
      const success = !!downgradeData.downgradeSeries3?.success;
      if (!success) {
        throw new Error('Failed to downgrade S3 membership: contact customer support.');
      }

      history.push({
        pathname: AppPaths.Subscription.DowngradeS3Confirmed(device.moduleId),
        search: window.location.search,
      });
    },
  });
  const loadingOrErrorElement = getLoadingOrErrorElement(downgradeS3State.loading, downgradeS3State.error);
  if (loadingOrErrorElement) {
    return loadingOrErrorElement;
  }

  const handleSwitchPlan = () => {
    events.downgradeS3SelectPlan({
      petId: device.pet?.id,
      oldSku: currentSubscriptionSku,
      newSku: selectedSubscriptionSku,
    });

    downgradeS3({
      variables: {
        input: {
          moduleId: device.moduleId,
          sku: selectedSubscriptionSku!,
        },
      },
    });
  };

  const handleCancel = () => {
    events.downgradeS3Cancel({ petId: device.pet?.id });
    history.push({
      pathname: AppPaths.Subscription.FreeMonthOffer(device.moduleId),
      state: { selectedCancelReason },
      search: window.location.search,
    });
  };

  return (
    <>
      <div className={styles.fullWidthWrapper}>
        <SubscriptionAppBar
          backButtonAnalyticsEvent={() => events.downgradeS3Back({ petId: device.pet?.id })}
          closeButtonAnalyticsEvent={() => events.downgradeS3Close({ petId: device.pet?.id })}
        />
        <div className={styles.subscriptionContainer}>
          <div className={styles.content}>
            <div className={styles.largeHeader}>
              <h1>Switch to a shorter plan?</h1>
            </div>

            <p className={styles.info}>
              Switch to a shorter Series 3 membership and enjoy the same features with increased flexibility. Switch
              back anytime to take advantage of prepaid membership savings.
            </p>

            <Chooser
              onSelect={(selectedSku) => {
                if (!selectedSku) {
                  return;
                }

                dispatch(
                  cartActions.setCartItem({
                    moduleId: device.moduleId,
                    sku: selectedSku,
                  }),
                );
              }}
              selectedOption={selectedSubscriptionSku}
              groups={chooserGroups}
            />
          </div>
        </div>
        <div className={styles.buttonContainer}>
          <Button className={styles.button} onClick={() => handleSwitchPlan()}>
            Select a Plan
          </Button>

          <Button className={styles.button} tertiary={true} onClick={() => handleCancel()}>
            Continue with Cancellation
          </Button>
        </div>
      </div>
    </>
  );
}
