import { useRecurly } from '@recurly/react-recurly';
import { useContext, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useCartPricing } from '../../contexts/CartPricingContext';
import CheckoutContext from '../../lib/CheckoutContext';
import {
  FiApplePayError,
  IRecurlyApplePay,
  RecurlyApplePayCallbackResult,
  RecurlyApplePayPaymentAuthorizedEvent,
  RecurlyApplePayUpdateResult,
  ShippingContactField,
} from '../../lib/RecurlyProvider';
import { cartHasSomePhysicalProducts } from '../../lib/cart';
import { APPLE_PAY_ERROR_CODE_CUSTOM_ACCOUNT_EXISTS, APPLE_PAY_ERROR_CODE_CUSTOM_API } from '../../lib/errors';
import { centsToDollars } from '../../lib/util';
import * as types from '../../types';
import ApplePayPaymentState from './ApplePayPaymentState';
import ApplePayPurchaseResults from './ApplePayPurchaseResults';
import { IApplePayEvents } from './Events';
import usePaymentAuthorized from './usePaymentAuthorized';
import useUpdatePaymentSheet from './useUpdatePaymentSheet';

const APPLE_PAY_LABEL = 'Fi Collars';

const APPLE_PAY_REQUIRED_BASIC_CONTACT_FIELDS: ShippingContactField[] = ['email', 'name'];

// We only need these additional 2 fields if we are shipping physical products
const APPLE_PAY_REQUIRED_FIELDS_FOR_SHIPPING: ShippingContactField[] = [
  ...APPLE_PAY_REQUIRED_BASIC_CONTACT_FIELDS,
  'phone',
  'postalAddress',
];

interface UseApplePayProps {
  events: IApplePayEvents;
  onCancel: () => void;
  onCustomError: (error: FiApplePayError) => void;
  onError: (error: Error) => void;
  onReady: () => void;
  onSuccess(results: ApplePayPurchaseResults): void;
}

/**
 * @returns applePay A Recurly.js ApplePay instance.
 */
export default function useApplePay({
  events,
  onCancel,
  onCustomError,
  onError,
  onReady,
  onSuccess,
}: UseApplePayProps) {
  const recurly = useRecurly();
  const paymentState = useMemo(() => new ApplePayPaymentState(), []);

  const { cart } = useContext(CheckoutContext);
  const products = useSelector((state: types.AppState) => state.config.products);
  const upgradeCreditsPurchaseInvoice = useSelector(
    (state: types.AppState) => state.config.siteConfig.series3UpgradeCreditsPurchaseInvoice ?? false,
  );
  const addressRequired = useMemo(() => cartHasSomePhysicalProducts(cart, products), [cart, products]);

  // This is cart pricing from the context provider, it provides an initial total for the Apple Pay payment sheet
  const contextCartPricing = useCartPricing();

  // This is a local version of cart pricing that gets updated as the user changes shipping options in the Apple Pay
  // payment sheet
  const [cartPricing, setCartPricing] = useState<types.CartPricing>(contextCartPricing);

  // This is the Total that is used when initially displaying the Apple Pay payment sheet. We can reuse the total
  // already calculated by the pricing context provider, which is what what is displayed on the cart page. However,
  // the difference between the Apple Pay payment sheet and the cart page is that the Apple Pay payment sheet total
  // should reflect the amount being charged to their card by Apple Pay, which will not include the upgrade credits.
  // The useApplePay hook will update pricing as the user changes shipping options in the Apple Pay payment sheet.
  //
  // UPDATE: upgradeCreditsPurchaseInvoice is now a site config flag that determines whether or not we are using the
  // new upgrade charge process. This new process will be crediting the prorated refund of the S2 subscription to the
  // invoice from purchasing the S3 upgrade so it will be _excluded_ from the total charged to the card.
  const initialTotalInCents = upgradeCreditsPurchaseInvoice
    ? cartPricing.totalInCents
    : cartPricing.totalInCents + (cartPricing.upgradeCreditAmountInCents ?? 0);

  const updatePaymentSheet = useUpdatePaymentSheet({
    events,
    paymentState,
    setCartPricing,
  });

  const onPaymentAuthorized = usePaymentAuthorized({
    addressRequired,
    cartPricing,
    events,
    onSuccess,
    paymentState,
  });

  // Cast to our own typings because the Recurly.JS typings are missing some things
  return useMemo(() => {
    const applePay = (recurly.ApplePay as IRecurlyApplePay)({
      country: 'US',
      currency: 'USD',
      label: APPLE_PAY_LABEL,
      requiredShippingContactFields: addressRequired
        ? APPLE_PAY_REQUIRED_FIELDS_FOR_SHIPPING
        : APPLE_PAY_REQUIRED_BASIC_CONTACT_FIELDS,

      total: centsToDollars(initialTotalInCents),
      callbacks: {
        onPaymentAuthorized: onPaymentAuthorized,
        onPaymentMethodSelected: async (_evt: ApplePayJS.ApplePayPaymentMethodSelectedEvent) => {
          return await updatePaymentSheet();
        },
        onShippingContactSelected: async (evt: ApplePayJS.ApplePayShippingContactSelectedEvent) => {
          paymentState.updateFromShippingContactSelected(evt);

          // We need to make sure to set shipping method options after a shipping address is provided
          return await updatePaymentSheet({
            updateShippingMethods: true,
          });
        },
        onShippingMethodSelected: async (evt: ApplePayJS.ApplePayShippingMethodSelectedEvent) => {
          paymentState.updateFromShippingMethodSelected(evt);
          return await updatePaymentSheet();
        },
      },
    });

    applePay.on('cancel', () => {
      // Reset paymentState (selected address and shipping method) and cart pricing
      paymentState.clear();
      setCartPricing(contextCartPricing);

      events.applePayCancel();
      onCancel();
    });

    applePay.on('error', (error: Error) => {
      events.applePayError(error.message);
      onError(error);
    });

    applePay.on('ready', onReady);

    // We need to override recurly.js callback handlers to account for custom error codes:
    // https://github.com/recurly/recurly-js/blob/9accefa4627a92fe6990eeb4d850881c67c9c56c/lib/recurly/apple-pay/apple-pay.js#L326-L377
    // Our overrides add a special case for our custom error codes because we want to exit out of the payment sheet
    // and handle the error in our own UI.
    const maybeHandleCustomError = (errors: FiApplePayError[] | undefined, originalHandler: () => void) => {
      if (typeof errors === 'object' && errors.length > 0) {
        // For any errors that aren't something we can display on the payment sheet, we need to abort the payment
        // session and handle the error in our own UI. In these cases, we can assume there is only 1 error returned.
        const firstError = errors[0];
        if (
          firstError.code === APPLE_PAY_ERROR_CODE_CUSTOM_ACCOUNT_EXISTS ||
          firstError.code === APPLE_PAY_ERROR_CODE_CUSTOM_API
        ) {
          applePay.session.abort();
          onCustomError(firstError);
          return;
        }
      }

      originalHandler();
    };

    const originalPaymentMethodSelected = applePay.onPaymentMethodSelected.bind(applePay);
    applePay.onPaymentMethodSelected = (
      event: ApplePayJS.ApplePayPaymentMethodSelectedEvent,
      update: RecurlyApplePayUpdateResult = {},
    ) => {
      maybeHandleCustomError(update.errors, () => {
        originalPaymentMethodSelected(event, update);
      });
    };

    const originalShippingContactSelected = applePay.onShippingContactSelected.bind(applePay);
    applePay.onShippingContactSelected = (
      event: ApplePayJS.ApplePayShippingContactSelectedEvent,
      update: RecurlyApplePayUpdateResult = {},
    ) => {
      maybeHandleCustomError(update.errors, () => {
        originalShippingContactSelected(event, update);
      });
    };

    const originalShippingMethodSelected = applePay.onShippingMethodSelected.bind(applePay);
    applePay.onShippingMethodSelected = (
      event: ApplePayJS.ApplePayShippingMethodSelectedEvent,
      update: RecurlyApplePayUpdateResult = {},
    ) => {
      maybeHandleCustomError(update.errors, () => {
        originalShippingMethodSelected(event, update);
      });
    };

    const originalPaymentAuthorized = applePay.onPaymentAuthorized.bind(applePay);
    applePay.onPaymentAuthorized = (
      event: RecurlyApplePayPaymentAuthorizedEvent,
      result: RecurlyApplePayCallbackResult = {},
    ) => {
      maybeHandleCustomError(result.errors, () => {
        originalPaymentAuthorized(event, result);
      });
    };

    return applePay;
  }, [
    recurly.ApplePay,
    addressRequired,
    initialTotalInCents,
    onPaymentAuthorized,
    onReady,
    updatePaymentSheet,
    paymentState,
    contextCartPricing,
    events,
    onCancel,
    onError,
    onCustomError,
  ]);
}
