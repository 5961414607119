import * as React from 'react';

import { centsToDollarsNumber } from '../../../../../lib/util';
import { ISupplementSubscriptionProduct, SupplementCheckoutFlow } from '../../../../../types';
import styles from './ProductSelector.module.scss';
import { DesktopPlanSelection, MobilePlanSelection } from '../ProductInfo/PlanSelection/PlanSelection';

export interface ProductSelectorProps {
  supplementProducts: ISupplementSubscriptionProduct[];
  selectedProduct: ISupplementSubscriptionProduct;
  onSelectionChange: (product: ISupplementSubscriptionProduct) => void;
  handlePurchase: () => Promise<void>;
  handlePurchaseLoading: boolean;
  flow: SupplementCheckoutFlow;
}

export const billingElement = (product: any) => {
  if (!product.freeTrialDays || !product.billingCadence) return '';
  return (
    <span className={styles.billingCadence}>Billed as ${centsToDollarsNumber(product.priceInCents)}/shipment.</span>
  );
};

export default function ProductSelector({
  supplementProducts,
  selectedProduct,
  onSelectionChange,
  handlePurchase,
  handlePurchaseLoading,
  flow,
}: ProductSelectorProps) {
  return (
    <>
      <div className={styles.planSelectionContainer}>
        <DesktopPlanSelection
          supplementProducts={supplementProducts}
          selected={selectedProduct}
          setSelected={(product) => onSelectionChange(product)}
        />
        <MobilePlanSelection
          selected={selectedProduct}
          handlePurchase={handlePurchase}
          setSelected={(product) => onSelectionChange(product)}
          handlePurchaseLoading={handlePurchaseLoading}
          flow={flow}
        />
      </div>
    </>
  );
}
