import React from 'react';

import styles from './ProductInfo.module.scss';
import { ReactComponent as IconCheck } from '../../../../../assets/images/icons/check.svg';
import classNames from 'classnames';

export default function TermsAndConditions() {
  return (
    <div className={styles.terms}>
      <div className={styles.term}>
        <IconCheck />
        Recurring subscription starts with shipment of 2nd bag
      </div>
      <div className={styles.term}>
        <IconCheck />
        Free shipping
      </div>
      <div className={styles.term}>
        <IconCheck />
        Try risk free, cancel anytime
      </div>
      <div className={classNames(styles.term, styles.purchaseDisclaimer)}>
        By purchasing, you agree to our{' '}
        <a href="https://tryfi.com/tos" target="_blank" rel="noopener noreferrer">
          terms and conditions
        </a>
        .
      </div>
    </div>
  );
}
