import React, { useMemo } from 'react';
import usStates from '../../lib/usStates';
import Dropdown from '../Dropdown';

interface USStateSelectorProps {
  state: string | undefined;
  onChange: (newState: string) => void;
}

export default function USStateSelector({ state, onChange }: USStateSelectorProps) {
  const options = useMemo(
    () =>
      usStates.map((item) => ({
        value: item.abbreviation,
        label: item.name,
      })),
    [],
  );

  const selectedState = useMemo(() => {
    if (!state) {
      return;
    }

    return options.find(({ value }) => value === state);
  }, [options, state]);

  return (
    <Dropdown
      id="state"
      options={options}
      selectedOption={selectedState}
      placeholder="State"
      onChange={(option) => {
        if (option) {
          onChange(option.value);
        }
      }}
    />
  );
}
