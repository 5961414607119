import React, { useContext, useEffect } from 'react';
import { identifyUser } from '../../../../lib/analytics/identifyUser';
import Breadcrumbs from '../../../../components/Breadcrumbs';
import CheckoutContext from '../../../../lib/CheckoutContext';
import * as events from '../../../../lib/analytics/events';
import { expectUnreachable } from '../../../../lib/util';
import CheckoutSection from '../../../../models/CheckoutSection';
import styles from './StepContainer.module.scss';

function titleForSection(checkoutSection: CheckoutSection, requiresShippingDetails: boolean) {
  if (checkoutSection === CheckoutSection.shipping) {
    return requiresShippingDetails ? 'Shipping' : 'Account';
  } else if (checkoutSection === CheckoutSection.payment) {
    return 'Payment';
  } else if (checkoutSection === CheckoutSection.confirm) {
    return 'Review';
  } else if (checkoutSection === CheckoutSection.thankYou) {
    return 'Thank you!';
  } else {
    expectUnreachable(checkoutSection);
  }
}

interface StepContainerProps {
  checkoutSection: CheckoutSection;
  children: React.ReactNode;
}

export default function StepContainer({ checkoutSection, children }: StepContainerProps) {
  const { checkoutState, requiresShippingDetails, session } = useContext(CheckoutContext);

  // When the user proceeds to a new section, log that impression
  useEffect(() => {
    switch (checkoutSection) {
      case CheckoutSection.confirm:
        events.confirmation.viewed();
        break;
      case CheckoutSection.payment:
        events.payment.viewed();
        break;
      case CheckoutSection.shipping:
        events.shipping.viewed();
        break;
      default:
    }
  }, [checkoutSection]);

  // Update user identity information as it is filled in
  useEffect(() => {
    if (session?.userId) {
      const fullName = [checkoutState.customerName?.firstName, checkoutState.customerName?.lastName]
        .filter((name) => !!name)
        .join(' ');

      identifyUser(session.userId, {
        name: { fullName },
        billingInfo: checkoutState.billingInfo,
        shippingAddress: checkoutState.shippingAddress,
      });
    }
  }, [session, checkoutState.customerName, checkoutState.billingInfo, checkoutState.shippingAddress]);

  return (
    <>
      <div className={styles.mobileBreadcrumbs}>
        <Breadcrumbs />
      </div>
      <div className={styles.container}>
        <div className={styles.titleColumn}>
          <h2>{titleForSection(checkoutSection, requiresShippingDetails)}</h2>
        </div>
        {children}
      </div>
    </>
  );
}
