import AppPaths from '../../../AppPaths';
import SentryRoute from '../../../SentryRoute';
import React from 'react';
import { Switch } from 'react-router';
import SplashPage from './SplashPage';
import ManageSubscription from './ManageSubscription';
import Survey from './Survey';
import AddPayment from './PurchaseSelection/AddPayment';

export default function Supplements() {
  return (
    <Switch>
      <SentryRoute exact path={AppPaths.Supplements.Root} component={SplashPage} />
      <SentryRoute exact path={AppPaths.Supplements.Manage()} component={ManageSubscription} />
      <SentryRoute exact path={AppPaths.Supplements.Survey()} component={Survey} />
      <SentryRoute exact path={AppPaths.Supplements.AddPayment} component={AddPayment} />
    </Switch>
  );
}
