import classNames from 'classnames';
import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import AppPaths from '../../../AppPaths';
import Button from '../../../components/Button';
import { Image as FiImage } from '../../../components/Image';
import WebViewAppBar from '../../../components/WebViewAppBar';
import { useSeries3UpgradeContext } from '../../../contexts/Series3UpgradeContext';
import useSubscriptionProducts from '../../../hooks/useSubscriptionProducts';
import { getFiGrowthBook } from '../../../lib/growthbook';
import { centsToDollars } from '../../../lib/util';
import UpgradeablePet from '../../../models/UpgradeablePet';
import { cartActions as upgradeCartActions } from '../../../reducers/series3UpgradeCart';
import { petContextActions } from '../../../reducers/series3UpgradePetContext';
import { series3UpgradeShopActions } from '../../../reducers/series3UpgradeShop';
import * as types from '../../../types';
import ValuePropsBox from '../../ProductDetails/Series3CollarDetails/components/ValuePropsBox';
import { WHATS_NEW_VALUE_PROPS } from '../../ProductDetails/Series3CollarDetails/components/ValuePropsBox/WhatsNewValueProps';
import { SelectableUpgradeOffer } from './UpgradeOffer';
import styles from './UpgradeSplash.module.scss';
import { inAppCancellation as events } from '../../../lib/analytics/events';
import WebViewAppBarContext from '../../../contexts/WebViewAppBarContext';

interface OfferDetailsProps {
  offeredPlan: types.ISubscriptionProduct;
  petToUpgrade: UpgradeablePet;
}

function OfferDetails({ offeredPlan, petToUpgrade }: OfferDetailsProps) {
  const dispatch = useDispatch();
  const history = useHistory();
  const contextSubscriptionSku = useSelector(
    (state: types.AppState) => state.series3UpgradeShop.upgradePetContext.preSelectedSubscriptionSku,
  );
  const inAppCancellationFlow = useSelector(
    (state: types.AppState) => state.series3UpgradeShop.upgradePetContext.inAppCancellationFlow,
  );

  const selectedSubscriptionSku = contextSubscriptionSku ?? offeredPlan.sku;

  // Initialized the preselected subscription sku with the offered plan returned to us from the backend.
  useEffect(() => {
    if (!contextSubscriptionSku) {
      dispatch(petContextActions.setPreSelectedSubscriptionSku(offeredPlan.sku));
    }
  }, [contextSubscriptionSku, dispatch, offeredPlan.sku]);

  const onContinue = useCallback(() => {
    inAppCancellationFlow &&
      events.series3UpgradeSplashContinueClicked({
        petId: petToUpgrade.id,
        newSku: selectedSubscriptionSku,
      });
    history.push(AppPaths.Series3Upgrade.ProductSelector(petToUpgrade.moduleId));
  }, [history, petToUpgrade.moduleId, petToUpgrade.id, selectedSubscriptionSku, inAppCancellationFlow]);

  const onSubscriptionSelected = useCallback(
    (plan: types.ISubscriptionProduct) => {
      dispatch(petContextActions.setPreSelectedSubscriptionSku(plan.sku));
    },
    [dispatch],
  );

  // The backend gives us an offered plan for a particular module, but we want to consider that to be the
  // _minimum_ plan that the user can upgrade to. They are free to choose a plan with a longer duration than the
  // offered plan if they'd like.
  const availableSubscriptions = useSubscriptionProducts(types.series3CollarId).filter((subscriptionProduct) => {
    return !subscriptionProduct.buyItMembership && subscriptionProduct.renewalMonths >= offeredPlan.renewalMonths;
  });

  return (
    <>
      {availableSubscriptions.map((subscriptionProduct) => {
        const promoDiscount = petToUpgrade.promoDiscounts.find(
          (discount) => discount.planCode === subscriptionProduct.sku,
        );

        return (
          <SelectableUpgradeOffer
            offeredPlan={subscriptionProduct}
            onSelect={onSubscriptionSelected}
            promotionalDiscountAmountInCents={promoDiscount?.discountAmountInCents ?? 0}
            selected={subscriptionProduct.sku === selectedSubscriptionSku}
          />
        );
      })}
      <Button className={styles.ctaButton} onClick={onContinue}>
        Continue
      </Button>
      <div className={styles.valuePropsSection}>
        <h2>What's new?</h2>
        <ValuePropsBox valueProps={WHATS_NEW_VALUE_PROPS} />
      </div>
    </>
  );
}

export default function UpgradeSinglePetSplash() {
  const dispatch = useDispatch();
  const { offeredPlan, upgradeablePets } = useSeries3UpgradeContext();
  const series3UpgradeCart = useSelector((state: types.AppState) => state.series3UpgradeShop.cart);
  const contextPetId = useSelector((state: types.AppState) => state.series3UpgradeShop.upgradePetContext.petId);
  const blackFridayCyberMondayAutoApplyCouponCode = getFiGrowthBook().getFeatureValue<string>(
    'black-friday-cyber-monday-upgrade-flow-coupon-code',
    '',
  );

  // Legacy upgrade flow would allow multiple pets to be upgraded in one transaction, but we're only supporting one pet
  // at a time for the new upgrade flow with promotional discounts. The backend API did not change so upgradeablePets
  // is still an array but it should only ever have one element when the :petId URL param is present.
  const petToUpgrade: UpgradeablePet | undefined = upgradeablePets.length > 0 ? upgradeablePets[0] : undefined;

  useEffect(() => {
    // Clear cart and checkout progress upon mount
    if (Object.keys(series3UpgradeCart.cartItems).length > 0) {
      dispatch(series3UpgradeShopActions.resetCheckout());
      dispatch(upgradeCartActions.resetCart());
    }

    // If black friday coupon code is flagged on, add it but only if the cart doesn't already have it attached
    if (
      blackFridayCyberMondayAutoApplyCouponCode !== '' &&
      series3UpgradeCart.couponCode !== blackFridayCyberMondayAutoApplyCouponCode
    ) {
      dispatch(upgradeCartActions.addCoupon(blackFridayCyberMondayAutoApplyCouponCode));
    }

    // Black friday coupon is the only coupon code available for upgrades so if we turn off the black friday promotion
    // and the user has a coupon in their cart, remove it
    if (blackFridayCyberMondayAutoApplyCouponCode === '' && series3UpgradeCart.couponCode) {
      dispatch(upgradeCartActions.removeCoupon());
    }

    if (petToUpgrade?.id !== contextPetId) {
      dispatch(petContextActions.setPetId(petToUpgrade?.id));
    }
  }, [
    blackFridayCyberMondayAutoApplyCouponCode,
    contextPetId,
    dispatch,
    petToUpgrade?.id,
    series3UpgradeCart.cartItems,
    series3UpgradeCart.couponCode,
  ]);

  return (
    <div className={classNames({ [styles.skinBlackFriday]: blackFridayCyberMondayAutoApplyCouponCode !== '' })}>
      <div className={styles.fixedBackground} />
      <div className={styles.fullWidthWrapper}>
        <WebViewAppBarContext.Provider
          value={{
            analyticsEvents: {
              closeButton: () => {
                events.upgradeS2CheckoutFlow(
                  {
                    petId: petToUpgrade?.id,
                  },
                  '> Splash > Close',
                );
              },
            },
          }}
        >
          <WebViewAppBar darkBackground />
        </WebViewAppBarContext.Provider>
        <div className={styles.container}>
          <div className={styles.header}>
            {petToUpgrade?.imageUrl && (
              <div className={styles.petImage}>
                <FiImage
                  image={{
                    url: petToUpgrade.imageUrl,
                    retinaHeight: 120,
                    retinaWidth: 120,
                  }}
                />
              </div>
            )}
            <div className={styles.primaryHeader}>
              <div className={styles.headerDefault}>
                <h1>Series 3</h1>
              </div>
            </div>
            {petToUpgrade?.name && (
              <div className={styles.secondaryHeader}>
                Transfer {petToUpgrade.name}'s{' '}
                <span className={styles.creditAmount}>
                  ${centsToDollars(petToUpgrade.proratedCreditAmountInCents)} credit
                </span>{' '}
                and enjoy one of these additional special offers:
              </div>
            )}
          </div>
          {!!petToUpgrade && offeredPlan ? (
            <OfferDetails offeredPlan={offeredPlan} petToUpgrade={petToUpgrade} />
          ) : (
            <h3>No upgradeable collars</h3>
          )}
        </div>
      </div>
    </div>
  );
}
