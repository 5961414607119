import * as qs from 'query-string';
import { useCallback, useMemo, useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { exactVariantForOptions } from '../../../lib/product';
import * as types from '../../../types';

interface ProductDetailsQueryParams {
  color?: types.ColorOption;
  size?: types.SizeOption;
  type?: types.CollarTypeOption;
}

function useDefaultVariantOptions(variants: types.IVariant[], newEcomFlow: boolean): types.IVariantOptions {
  const location = useLocation();

  const { initialCollarType, initialColor, initialSize } = useMemo(() => {
    const queryParams: ProductDetailsQueryParams = qs.parse(location.search);
    return {
      initialCollarType: queryParams.type,
      initialColor: queryParams.color,
      initialSize: queryParams.size,
    };
  }, [location.search]);

  return useMemo(() => {
    const backupOptions = variants[0].options;

    const preferredTypes = [initialCollarType, types.CollarTypeOption.Standard];
    const availablePreferredType = preferredTypes.find((t) => variants.some((v) => v.options.collarType === t));
    const collarType = availablePreferredType ?? backupOptions.collarType;

    const preferredColors = [initialColor, newEcomFlow ? types.ColorOption.Gray : types.ColorOption.Yellow].filter(
      (colorOption) => !!colorOption,
    );
    const availablePreferredColor = preferredColors.find((c) => variants.some((v) => v.options.color === c));
    const color = availablePreferredColor ?? backupOptions.color;

    const preferredSizes = [initialSize, types.SizeOption.Medium];
    const availablePreferredSize = preferredSizes.find((s) => variants.some((v) => v.options.size === s));
    const size = availablePreferredSize ?? backupOptions.size;

    return {
      collarType,
      color,
      size,
    };
  }, [initialCollarType, initialColor, initialSize, variants, newEcomFlow]);
}

interface VariantSelectorProps {
  onColorChange: (newVariant: types.IVariant) => void;
  onSelectedVariantChange: (newVariant: types.IVariant) => void;
  onSizeChange: (newVariant: types.IVariant) => void;
  variants: types.IVariant[];
  newEcomFlow: boolean;
}

export default function useVariantSelector({
  onColorChange,
  onSelectedVariantChange,
  onSizeChange,
  variants,
  newEcomFlow,
}: VariantSelectorProps) {
  const history = useHistory();
  const defaultOptions = useDefaultVariantOptions(variants, newEcomFlow);

  const [selectedVariant, setSelectedVariant] = useState<types.IVariant>(
    exactVariantForOptions(variants, defaultOptions) ?? variants[0],
  );
  const location = useLocation();
  const queryParams: ProductDetailsQueryParams = qs.parse(location.search);
  const [hasUpdatedSize, setHasUpdatedSize] = useState<boolean>(!!queryParams.size);

  const changeVariantOptions = useCallback(
    (o: Partial<types.IVariantOptions>) => {
      const newOptions = { ...selectedVariant?.options, ...o };
      const newVariant = exactVariantForOptions(variants, newOptions) ?? variants[0];

      if (!o.color && o.size) {
        setHasUpdatedSize(true);
      }

      if (newVariant.options.color !== selectedVariant?.options.color) {
        onColorChange(newVariant);
      }

      if (newVariant.options.size !== selectedVariant?.options.size) {
        onSizeChange(newVariant);
      }

      setSelectedVariant(newVariant);

      const queryString = qs.stringify(newOptions);
      history.replace({ search: queryString, state: { noScroll: true } });
    },
    [history, onColorChange, onSizeChange, selectedVariant?.options, variants],
  );

  useEffect(() => {
    onSelectedVariantChange(selectedVariant);
  }, [onSelectedVariantChange, selectedVariant]);

  /**
   * If we no longer have a valid variant, switch into new default options. This is useful where the selected
   * options might be for S3 options, but then toggled to S2 that do not have the same variants (e.g. scribble-out
   * color or XS size). This will keep any applicable options, but choose new defaults for the ones that are not
   * applicable.
   */
  useEffect(() => {
    if (!variants.find((variant) => variant.sku === selectedVariant.sku)) {
      changeVariantOptions(defaultOptions);
    }
  }, [changeVariantOptions, defaultOptions, selectedVariant, variants]);

  return {
    changeVariantOptions,
    selectedVariant,
    hasUpdatedSize,
  };
}
