import { gql } from '@apollo/client';

export const userIdentityQuery = gql`
  query userIdentity {
    currentUser {
      id
      firstName
      lastName
      email
      billingAccount {
        billingInfo {
          firstName
          lastName
          address {
            line1
            line2
            city
            state
            zip
            country
          }
        }
        address {
          line1
          line2
          city
          state
          zip
          country
          phone
        }
      }
    }
  }
`;

export const userPetsQuery = gql`
  query userPets {
    currentUser {
      userHouseholds {
        household {
          pets {
            id
            name
            weight
            photos {
              first {
                image {
                  fullSize
                }
              }
            }
            device {
              subscription {
                id
                unitAmountInCents
              }
            }
          }
        }
      }
    }
  }
`;
