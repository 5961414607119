import classNames from 'classnames';
import { DateTime } from 'luxon';
import React from 'react';

import { BillingSubscription } from '../SplashPage';
import pillStyles from '../styles/SupplementPill.module.scss';

interface SupplementPillProps {
  paused?: boolean;
  subscription: BillingSubscription;
  handleClick: () => void;
  selected: boolean;
}

export default function PausePill({ subscription, paused, handleClick, selected }: SupplementPillProps) {
  const previewResumingShipmentDate = subscription.supplementShipmentDates?.previewResumingShipmentDate;
  const previewSkippingShipmentDate = subscription.supplementShipmentDates?.previewSkippingShipmentDate;
  const { weeksBetweenShipments } = subscription.subscriptionOption;
  if (!previewResumingShipmentDate) {
    throw new Error('Missing skipped shipment date. Please contact support@tryfi.com.');
  }
  if (!previewSkippingShipmentDate) {
    throw new Error('Missing unskipped shipment date. Please contact support@tryfi.com.');
  }
  if (!weeksBetweenShipments) {
    throw new Error('Missing shipping frequency. Please contact support@tryfi.com.');
  }

  const today = DateTime.utc();
  const resumeShipmentDate = subscription.supplementShipmentDates?.previewResumingShipmentDate;
  // Can resume shipment only if skipped shipment date is in future.
  const canResume =
    paused && resumeShipmentDate && DateTime.fromISO(resumeShipmentDate).startOf('day') > today.startOf('day');

  const pausedNextShipmentText = () => {
    const next = paused ? previewResumingShipmentDate : previewSkippingShipmentDate;
    return `Your next shipment will be on ${DateTime.fromISO(next).toFormat('LL/dd/yy')}`;
  };

  return (
    <>
      {paused ? (
        canResume ? (
          <div
            className={classNames(pillStyles.supplementPillContainer, pillStyles.pause, {
              [pillStyles.selected]: selected,
            })}
            onClick={handleClick}
          >
            <div className={pillStyles.shipping}>Restart your skipped shipment</div>
            <div className={pillStyles.recommended}>{pausedNextShipmentText()}</div>
          </div>
        ) : (
          <p>Skipped shipment date has already passed and cannot be undone.</p>
        )
      ) : (
        <div
          className={classNames(pillStyles.supplementPillContainer, pillStyles.pause, {
            [pillStyles.selected]: selected,
          })}
          onClick={handleClick}
        >
          <div className={pillStyles.shipping}>Skip your upcoming shipment</div>
          <div className={pillStyles.recommended}>{pausedNextShipmentText()}</div>
        </div>
      )}
    </>
  );
}
