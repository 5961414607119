import React from 'react';
import styles from '../../../ProductDetails.module.scss';
import ValuePropsBox from '../ValuePropsBox';
import { WHATS_NEW_VALUE_PROPS } from '../ValuePropsBox/WhatsNewValueProps';

export default function NewFeatures() {
  return (
    <div className={styles.valuePropsSection}>
      <div className={styles.sectionHeader}>What's new</div>
      <ValuePropsBox valueProps={WHATS_NEW_VALUE_PROPS} />
    </div>
  );
}
