import AppPaths from '../../../../AppPaths';
import { createZendeskCancellationTicket, createZendeskReplacementTicket } from '../../../../graphql-operations';
import { getLoadingOrErrorElement } from '../../../../lib/util';
import * as gqlTypes from '../../../../types/gql-op-types';
import { useMutation } from '@apollo/client';
import { RequestReason } from '../RequestSent/RequestSent';
import { useHistory } from 'react-router-dom';

export interface useZendeskCancellationTicketProps {
  analyticsEvent: (input: any) => void;
  selectedCancelReason: gqlTypes.zendeskCancellationReason;
  device: gqlTypes.purchaseSubscriptionDeviceDetails;
}

export function useZendeskCancellationTicket({
  analyticsEvent,
  selectedCancelReason,
  device,
}: useZendeskCancellationTicketProps) {
  const history = useHistory();
  const [mutation, { loading, error }] = useMutation<
    gqlTypes.ECOMMERCE_createZendeskCancellationTicket,
    gqlTypes.ECOMMERCE_createZendeskCancellationTicketVariables
  >(createZendeskCancellationTicket, {
    onCompleted: (data) => {
      if (data.createZendeskCancellationTicket?.ticketCreated) {
        analyticsEvent({
          petId: device.pet?.id,
          cancellationReason: selectedCancelReason,
        });
        history.push({
          pathname: AppPaths.Subscription.RequestSent(device.moduleId),
          search: window.location.search,
          state: {
            requestType: RequestReason.Cancellation,
            cancellationReason: selectedCancelReason,
          },
        });
      } else {
        throw new Error(
          'Unable to send request. Please try again later. If problem persists, reach out to support@tryfi.com.',
        );
      }
    },
  });

  const loadingOrErrorElement = getLoadingOrErrorElement(loading, error);

  return { mutation, loading: loadingOrErrorElement };
}

export interface useZendeskReplacementTicketProps {
  analyticsEvent: (input: any) => void;
  device: gqlTypes.purchaseSubscriptionDeviceDetails;
  replacementReason: string;
}

export function useZendeskReplacementTicket({
  analyticsEvent,
  device,
  replacementReason,
}: useZendeskReplacementTicketProps) {
  const history = useHistory();
  const [mutation, { loading, error }] = useMutation<
    gqlTypes.ECOMMERCE_createZendeskReplacementTicket,
    gqlTypes.ECOMMERCE_createZendeskReplacementTicketVariables
  >(createZendeskReplacementTicket, {
    onCompleted: (data) => {
      if (data.createZendeskReplacementTicket?.ticketCreated) {
        analyticsEvent({ petId: device.pet?.id, replacementReason });
        history.push({
          pathname: AppPaths.Subscription.RequestSent(device.moduleId),
          search: window.location.search,
          state: {
            requestType: RequestReason.Replacement,
            replacementReason,
          },
        });
      } else {
        throw new Error(
          'Unable to send request. Please try again later. If problem persists, reach out to support@tryfi.com.',
        );
      }
    },
  });

  const loadingOrErrorElement = getLoadingOrErrorElement(loading, error);

  return { mutation, loading: loadingOrErrorElement };
}
