import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import * as yup from 'yup';
import ActionContainer from '../../../../components/ActionContainer';
import Button from '../../../../components/Button';
import ErrorMessage from '../../../../components/ErrorMessage';
import CheckoutContext from '../../../../lib/CheckoutContext';
import { addressValidationSchema } from '../../../../lib/addressValidation';
import * as events from '../../../../lib/analytics/events';
import { signup } from '../../../../lib/authentication';
import { skusForCartItem } from '../../../../lib/cart';
import {
  BillingAccountCreationError,
  UserExistsSignUpError,
  UserFacingError,
  logInternalError,
} from '../../../../lib/errors';
import { isPreorderSku } from '../../../../lib/isPreorderSku';
import { isExpeditedShippingAvailable } from '../../../../lib/shipping';
import { generateID } from '../../../../lib/util';
import styles from '../../../../styles/form.module.scss';
import * as types from '../../../../types';
import { gqlTypes } from '../../../../types';
import ShippingOptionsList from '../../components/ShippingOptionsList';
import { AccountDetails, ContactInfo, ShippingAddressDetails } from './ShippingFormFields';
import AppPaths from '../../../../AppPaths';
import { useHistory } from 'react-router';

interface ShippingAddressFormProps {
  loggedInUserId?: string;
  submitting: boolean;
  onComplete(addressUpdate?: {
    firstName: string;
    lastName: string;
    address: types.Address;
    addressValidationStatus: types.AddressValidationNeedsVerify;
    shippingCode: string;
  }): void;
}

interface FormFields {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
  phone: string;
  line1: string;
  line2: string;
  city: string;
  state: string;
  zip: string;
  country: string;
  shippingCode: types.ShippingCode | undefined;
}

export default function ShippingAddressForm({ loggedInUserId, submitting, onComplete }: ShippingAddressFormProps) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { cart, checkoutState, checkoutActions, session, requiresShippingDetails } = useContext(CheckoutContext);

  const initialFormValues: FormFields = useMemo(
    () => ({
      line1: checkoutState.shippingAddress?.line1 ?? '',
      line2: checkoutState.shippingAddress?.line2 ?? '',
      city: checkoutState.shippingAddress?.city ?? '',
      state: checkoutState.shippingAddress?.state ?? '',
      zip: checkoutState.shippingAddress?.zip ?? '',
      phone: checkoutState.shippingAddress?.phone ?? '',
      country: checkoutState.shippingAddress?.country ?? 'US',

      email: '',
      password: '',
      firstName: checkoutState.customerName?.firstName ?? '',
      lastName: checkoutState.customerName?.lastName ?? '',

      shippingCode: checkoutState.shippingCode,
    }),
    [checkoutState.shippingAddress, checkoutState.customerName, checkoutState.shippingCode],
  );

  const [errors, setErrors] = useState<string[]>([]);
  const [errorID, setErrorID] = useState<string | undefined>(undefined);
  const [userExistsSignUpError, setUserExistsSignUpError] = useState<boolean>(false);

  const [formValues, setFormValues] = useState<FormFields>(initialFormValues);
  const [submittingForm, setSubmittingForm] = useState(false);

  const loggedIn = !!session;
  const requiresAccountDetails = !loggedIn;
  const loginUrl = `${AppPaths.Login}?returnTo=${AppPaths.CheckoutShipping}`;

  const anyPreorder = useMemo(
    () =>
      Object.values(cart.cartItems)
        .flatMap((cartItem) => skusForCartItem(cartItem))
        .some(isPreorderSku),
    [cart.cartItems],
  );

  const canExpedite = useMemo(() => {
    // If there's no address yet, we don't know if we can or cannot expedite, so don't disable the expedited ones yet.
    if (!checkoutState.shippingAddress && !formValues.line1 && !formValues.line2) {
      return true;
    }

    return isExpeditedShippingAvailable({
      ...checkoutState.shippingAddress,
      ...formValues,
    });
  }, [checkoutState.shippingAddress, formValues]);

  // Update form when checkoutState shippingAddress changes (e.g. it's loaded from the user account)
  useEffect(() => {
    setFormValues(initialFormValues);
  }, [initialFormValues]);

  // Reset form to empty on user change (impersonation)
  const [previousUserId, setPreviousUserId] = useState(loggedInUserId);

  useEffect(() => {
    if (loggedInUserId && loggedInUserId !== previousUserId) {
      setFormValues(initialFormValues);
      setPreviousUserId(loggedInUserId);
    }
  }, [previousUserId, loggedInUserId, initialFormValues]);

  const onChange = useCallback(
    (fieldName: string, value: string) => {
      setFormValues({
        ...formValues,
        [fieldName]: value,
      });
    },
    [formValues],
  );

  const handleErrors = useCallback(
    (newErrors: string[]) => {
      if (newErrors.length > 0) {
        setErrors(newErrors);
        setErrorID(generateID());

        events.shipping.continueError(newErrors.join('; '));
      }
    },
    [setErrorID, setErrors],
  );

  const handleSubmit = useCallback(async () => {
    if (submitting) {
      return;
    }

    setSubmittingForm(true);

    // Reset errors
    setErrors([]);
    setUserExistsSignUpError(false);

    const email = formValues.email.trim();
    const password = formValues.password.trim();
    const firstName = formValues.firstName.trim();
    const lastName = formValues.lastName.trim();
    const shippingCode = formValues.shippingCode;

    const addressInput: gqlTypes.AddressInput = {
      line1: formValues.line1.trim(),
      line2: formValues.line2.trim(),
      city: formValues.city.trim(),
      state: formValues.state.trim(),
      zip: formValues.zip.trim(),
      phone: formValues.phone.trim(),
      country: formValues.country.trim(),
    };

    try {
      addressValidationSchema.validateSync(
        {
          ...addressInput,
          email,
          password,
          firstName,
          lastName,
          shippingCode,
        },
        {
          abortEarly: false,
          context: {
            requiresAccountDetails,
            requiresShippingDetails,
          },
        },
      );
    } catch (err) {
      if (err instanceof yup.ValidationError) {
        handleErrors(err.errors);
        setSubmittingForm(false);
        return;
      }

      logInternalError(err);
      handleErrors(['An unknown error occurred.']);
      setSubmittingForm(false);
      return;
    }

    if (requiresAccountDetails) {
      try {
        await signup(email, password, firstName, lastName);
      } catch (error) {
        /**
         * Our signup helper will make a couple of API calls
         * 1) create the Fi user account
         * 2) create a Recurly billing account
         *
         * If the second call fails, we want to redirect the user to the login page because they already have a
         * Fi user account so they can't signup again and logging in will make another attempt at creating their
         * Recurly billing account if they don't already have one.
         */
        if (error instanceof BillingAccountCreationError) {
          history.push(loginUrl, {
            billingAccountCreationError: true,
          });
          return;
        }

        if (error instanceof UserExistsSignUpError) {
          // Don't automatically redirect to the login screen for this error. We'll show a special error message
          // with a link to login, but by keeping them here they also have the option to sign up with a different
          // email instead.
          setUserExistsSignUpError(true);
        } else {
          const errorMessage =
            error instanceof UserFacingError
              ? error.message
              : 'An error occurred while signing up. Please try again or contact support@tryfi.com if the problem persists.';

          handleErrors([errorMessage]);
        }

        setSubmittingForm(false);
        return;
      }
    }

    if (!requiresShippingDetails) {
      dispatch(checkoutActions.setCustomerName({ firstName, lastName }));
      onComplete();
      setSubmittingForm(false);
      return;
    }

    onComplete({
      firstName,
      lastName,
      address: addressInput,
      // If this is the first time they're entering an address (i.e. creating a new account), address validation
      // should allow an autocorrect of the address without prompting the user as long as the validation backend
      // said it was a verified address
      addressValidationStatus: requiresAccountDetails
        ? types.AddressValidationStatus.NeedsVerifyWithAutocorrect
        : types.AddressValidationStatus.NeedsVerifyWithPrompt,
      shippingCode: shippingCode!,
    });
    setSubmittingForm(false);
  }, [
    checkoutActions,
    dispatch,
    formValues.city,
    formValues.country,
    formValues.email,
    formValues.firstName,
    formValues.lastName,
    formValues.line1,
    formValues.line2,
    formValues.password,
    formValues.phone,
    formValues.shippingCode,
    formValues.state,
    formValues.zip,
    handleErrors,
    history,
    loginUrl,
    onComplete,
    requiresAccountDetails,
    requiresShippingDetails,
    submitting,
  ]);

  // wrap handleSubmit in another callback for the form action because onSubmit expects a void return type but
  // handleSubmit is async
  const onSubmitCallback = useCallback(
    (evt: React.FormEvent<HTMLFormElement>) => {
      evt.preventDefault();

      // handleSubmit should already have its own error handling but we catch and log here for good measure
      handleSubmit().catch((err) => {
        logInternalError(err);
      });
    },
    [handleSubmit],
  );

  return (
    <>
      <form className={styles.form} onSubmit={onSubmitCallback} autoComplete="on">
        {requiresAccountDetails && <AccountDetails onChange={onChange} {...formValues} />}
        <ContactInfo onChange={onChange} showPhoneNumberInput={requiresShippingDetails} {...formValues} />
        {requiresShippingDetails && (
          <>
            <ShippingAddressDetails onChange={onChange} {...formValues} />
            <ShippingOptionsList
              anyPreorder={anyPreorder}
              canExpedite={canExpedite}
              currentlySelected={formValues.shippingCode}
              onSelect={(shippingCode) => onChange('shippingCode', shippingCode)}
            />
          </>
        )}
        <ActionContainer>
          <Button disabled={submitting || submittingForm} type="submit">
            Save and continue
          </Button>
        </ActionContainer>
        {/* Special case error because it contains HTML elements (link to login page) */}
        {userExistsSignUpError && (
          <div className={styles.formError}>
            A Fi account already exists for the email address you entered. Please <a href={loginUrl}>sign in</a> with
            your existing account.
          </div>
        )}
        <ErrorMessage errorID={errorID} errors={errors} />
      </form>
    </>
  );
}
