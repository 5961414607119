import { IECommerceSiteConfig, IProduct, IShippingOption, ShippingCode } from './Product';
import { Cart, Series3UpgradeCart, StoreCart, SubscriptionCart } from './carts';

export * from './Product';
export * from './carts';
export * from './gql';

//
// General Types
//
export type Money = string;

export type ProductID = string;
export type CartItemID = string;

export type Result<S, F> = { kind: 'success'; value: S } | { kind: 'failure'; err: F };

export interface AffiliateData {
  cjevent?: string;
}

/**
 * The config that is loaded from the server.
 */
export interface RemoteConfig {
  products: IProduct[];
  config: IECommerceSiteConfig;
  shippingOptions: IShippingOption[];
}

/**
 * The config that is stored in Redux.
 */
export interface ECommerceConfig {
  loaded: boolean;
  products: IProduct[];
  shippingOptions: IShippingOption[];
  siteConfig: IECommerceSiteConfig;
}

export interface InPersonEvent {
  uuid: string;
  name: string;
}

export interface MakerBandDetails {
  bandMakerName: string;
  bandPriceInCents?: number;
}

//
// Session Interface
//
export interface Session {
  userId: string;
  noPassword?: boolean;
  email: string | undefined;
  impersonating?: boolean;
}

//
// Checkout Interfaces
//
export enum CheckoutStep {
  AddShippingAddress,
  AddPaymentDetails,
  ConfirmPurchase,
  PurchaseComplete,
}

export interface CustomerFullName {
  firstName: string;
  lastName: string | null;
}

export interface Address {
  line1: string;
  line2?: string | null;
  city: string;
  state: string;
  zip: string;
  country: string;
  phone?: string | null;
}

export interface ShippingInfo {
  customerName: CustomerFullName;
  address: Address;
}

// Address validation
export enum AddressValidationStatus {
  Verified = 'verified',
  NeedsVerifyWithPrompt = 'needs_verify_with_prompt',
  NeedsVerifyWithAutocorrect = 'needs_verify_with_autocorrect',
}

export type AddressValidationNeedsVerify =
  | AddressValidationStatus.NeedsVerifyWithAutocorrect
  | AddressValidationStatus.NeedsVerifyWithPrompt;

export interface BillingToken {
  type: 'paypal';
  id: string;
}

export enum PaymentMethod {
  CreditCard,
  PayPal,
  ApplePay,
  Default = CreditCard,
}

// Components/flows that use PurchaseSubscriptionContextProvider
export enum PaymentContext {
  PURCHASE_SUBSCRIPTION = 'Purchase Subscription',
  MEMBERSHIP_UPGRADE = 'Membership Upgrade',
  MANAGE_SUBSCRIPTION = 'Manage Subscription',
}

export interface CreditCardInfo {
  __typename: 'ObfuscatedCardInfo';
  lastFour: number;
  type: string;
  month: number;
  year: number;
}

export interface PayPalInfo {
  __typename: 'PayPalInfo';
}

export type PaymentInfo = CreditCardInfo | PayPalInfo;

export interface BillingInfo {
  firstName: string;
  lastName: string;
  address: Address;
  paymentInfo: PaymentInfo;
}

export interface CheckoutState {
  addressValidationStatus?: AddressValidationStatus;
  billingInfo?: BillingInfo;
  billingToken?: BillingToken;
  customerName?: CustomerFullName;
  orderId?: string;
  shippingAddress?: Address;
  shippingCode?: ShippingCode;
  unlinkBillingInfo?: boolean;
}

/**
 * Cart + checkout states for one of our few "shops" on the e-commerce
 * site. The main store shop, the upgrade shop, and the subscription
 * shop.
 */
export interface BaseShopState {
  cart: Cart;
  checkout: CheckoutState;
}

export interface StoreShopState extends BaseShopState {
  cart: StoreCart;
}

export interface Series3UpgradePetContext {
  /**
   * We are migrating the upgrade flow that is able to upgrade multiple pets at once to only upgrade one pet at a time.
   * For the legacy flow, there won't be a petId in the context.
   */
  petId?: string;
  preSelectedSubscriptionSku?: string;

  // True if upgrade is taking place inside of in-app cancellation.
  inAppCancellationFlow?: boolean;
}

export interface Series3UpgradeShopState extends BaseShopState {
  cart: Series3UpgradeCart;
  upgradePetContext: Series3UpgradePetContext;
}

export interface SubscriptionShopState extends BaseShopState {
  cart: SubscriptionCart;
}

//
// App Redux state
//
export interface AppState {
  affiliateData: AffiliateData;
  config: ECommerceConfig;
  session: Session | null;

  // For shopping on the main store
  storeShop: StoreShopState;

  // For shopping in-app for a Series 3 upgrade
  series3UpgradeShop: Series3UpgradeShopState;
  inPersonEvent: IInPersonEvent | null;

  // For purchasing a subscription for a specific module
  subscriptionShop: SubscriptionShopState;
}

//
// Promo code types
//
export interface ReferralDetails {
  referralCode: string;
  referrerName?: string;
}

export interface CouponAmountDiscount {
  type: 'dollars';
  discountAmountInCents: number;
}

export interface CouponPercentDiscount {
  type: 'percent';
  percent: number;
}

export interface CouponMonthDiscount {
  type: 'months';
  months: number;
}

export type CouponDiscount = CouponAmountDiscount | CouponPercentDiscount | CouponMonthDiscount;

export interface CouponDetails {
  code: string;
  discount: CouponDiscount;
}

export interface GiftCardDetails {
  redemptionCode: string;
  totalValueOfGiftCardInCents: number;
}

export interface IGiftCardDelivery {
  deliverAt: string | null;
  emailAddress: string;
  firstName: string | null;
  lastName: string | null;
  gifterName: string | null;
  personalMessage: string | null;
}

export interface IInPersonEvent {
  uuid: string;
  name: string;
}

export interface IImage {
  url: string;
  descriptors?: string[];
  retinaWidth?: number;
  retinaHeight?: number;
}

export type SupplementCheckoutFlow = 'ecomm' | 'in-app';
