import React from 'react';
import { ReactComponent as IconKit } from '../../../../../assets/images/icons/icon_kit.svg';
import { ReactComponent as IconWarranty } from '../../../../../assets/images/icons/icon_warranty.svg';
import { ReactComponent as IconMessage } from '../../../../../assets/images/icons/icon_message.svg';
import { ReactComponent as IconPhone } from '../../../../../assets/images/icons/icon_phone.svg';
import { colorAndDesignVariantName } from '../../../../../lib/product';
import { sizeDetailsForSizeOption } from '../../../../../lib/size';
import * as types from '../../../../../types';
import styles from '../../../ProductDetails.module.scss';
import ValuePropsBox from '../ValuePropsBox';
import { WHATS_INCLUDED_VALUE_PROPS } from '../ValuePropsBox/WhatsIncludedValueProps';

interface MembershipValuePropsProps {
  selectedVariant: types.IVariant;
  newEcomFlow: boolean;
}

export default function MembershipValueProps({ selectedVariant, newEcomFlow }: MembershipValuePropsProps) {
  if (!newEcomFlow) {
    return (
      <div className={styles.valuePropsSection}>
        <div className={styles.subSectionHeader}>What's included in your membership?</div>
        <ValuePropsBox
          valueProps={[
            {
              icon: <IconKit />,
              text: (
                <div className={styles.valueProp}>
                  <span className={styles.valuePropHeader}>
                    Your Series 3 Kit -{' '}
                    {
                      sizeDetailsForSizeOption({
                        sizeOption: selectedVariant.options.size,
                        bandSeries: types.BandSeries.F3,
                      })?.sizeName
                    }{' '}
                    {colorAndDesignVariantName(selectedVariant.options.color)}.
                  </span>
                </div>
              ),
            },
            ...WHATS_INCLUDED_VALUE_PROPS,
          ]}
        />
      </div>
    );
  }

  return (
    <div className={styles.valuePropsSection}>
      <ValuePropsBox
        header={<div className={styles.subSectionHeader}>Membership includes</div>}
        valueProps={[
          {
            icon: <IconWarranty />,
            text: (
              <div className={styles.valueProp}>
                <span className={styles.valuePropHeader}>
                  <span className={styles.valuePropHeaderBold}>Fi Series 3 Warranty</span> with unlimited replacements
                  on active memberships.
                </span>
              </div>
            ),
          },
          {
            icon: <IconMessage />,
            text: (
              <div className={styles.valueProp}>
                <div className={styles.valuePropHeader}>
                  <span className={styles.valuePropHeaderBold}>7 days a week customer service</span> with email and live
                  chat support.
                </div>
              </div>
            ),
          },
          {
            icon: <IconPhone />,
            text: (
              <div className={styles.valueProp}>
                <span className={styles.valuePropHeader}>
                  <span className={styles.valuePropHeaderBold}>Premium Phone Support</span> with all pre-paid plans.
                </span>
              </div>
            ),
          },
        ]}
      />
      <div className={styles.multipleDogsHeader}>Have multiple dogs?</div>
      <div className={styles.multipleDogsDescription}>Get 5% off all Fi memberships & no activation fee.</div>
    </div>
  );
}
