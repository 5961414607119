import React from 'react';

/**
 * Adds styles to hide the Zendesk chat widget
 */
export default function HideChatWidget({ mobileOnly }: { mobileOnly?: boolean }) {
  return (
    <style
      type="text/css"
      dangerouslySetInnerHTML={{
        __html: `
        ${mobileOnly && '@media (max-width: 768px) {'}
          iframe#launcher, iframe#ada-button-frame {
            display: none !important;
          }
        ${mobileOnly && '}'}
        `,
      }}
    />
  );
}
