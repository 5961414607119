import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import WebViewAppBar from '../../components/WebViewAppBar';
import styles from './MembershipUpgradeSelection.module.scss';
import { usePurchaseSubscriptionContext } from '../PurchaseSubscription/context/PurchaseSubscriptionContext';
import Button from '../../components/Button';
import { SubscriptionOption } from '../../components/SubscriptionOptionDetails/SubscriptionOptionDetails';
import { useSubscriptionCartMode } from '../../lib/cartModes';
import { gqlBillingCadenceToBillingCadence } from '../../lib/subscription';
import { cartActions } from '../../reducers/subscriptionCart';
import { useSubscriptionChooserGroups } from '../../components/SubscriptionOptions/SubscriptionOptions';
import Chooser from '../../components/Chooser';
import { PurchaseSubscriptionCheckoutState } from '../PurchaseSubscription/PurchaseSubscriptionCheckout/PurchaseSubscriptionCheckout';
import AppPaths from '../../AppPaths';

function getRateUnitToDisplay(displayedRateUnit: string): string {
  if (displayedRateUnit === 'one time') {
    return 'ONE TIME';
  } else if (displayedRateUnit === 'month') {
    return 'MONTHLY';
  } else {
    return 'WEEKLY';
  }
}

export default function MembershipUpgradeSelection() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { device } = usePurchaseSubscriptionContext();
  const { cartSelector } = useSubscriptionCartMode();
  const cart = useSelector(cartSelector);

  const petName = device?.pet?.name;
  const avatarUrl = device?.pet?.photos.first?.image.fullSize ?? '/dog_placeholder.png';

  const applicableSubscriptionProducts = device.purchasableSubscriptionOptions.reduce(
    (applicableProducts: SubscriptionOption[], subscriptionOption) => {
      if (!subscriptionOption.supportPlus) {
        return applicableProducts;
      }

      applicableProducts.push({
        ...subscriptionOption,
        billingCadence: gqlBillingCadenceToBillingCadence(subscriptionOption.billingCadence),
      });
      return applicableProducts;
    },
    [],
  );

  const selectedSubscriptionSku = useMemo(() => {
    const cartItem = Object.values(cart.cartItems)[0];
    if (!cartItem) {
      return undefined;
    }

    return cartItem.lineItem.sku;
  }, [cart.cartItems]);

  const selectedSubscriptionOption = useMemo(() => {
    return applicableSubscriptionProducts.find((option) => option.sku === selectedSubscriptionSku);
  }, [applicableSubscriptionProducts, selectedSubscriptionSku]);

  const chooserGroups = useSubscriptionChooserGroups({
    disablePrioritizedShippingUpsell: true,
    subscriptionProducts: applicableSubscriptionProducts,
  });

  // Navigate to the checkout page
  const goToCheckout = useCallback(() => {
    history.push({
      pathname: AppPaths.MembershipUpgrade.Checkout(device.moduleId),
      state: { subscriptionOption: selectedSubscriptionOption } as PurchaseSubscriptionCheckoutState,
    });
  }, [device.moduleId, history, selectedSubscriptionOption]);
  const checkoutDisabled = selectedSubscriptionOption ? false : true;

  return (
    <>
      <div className={styles.fullWidthWrapper}>
        <WebViewAppBar />
        <div className={styles.container}>
          <h1 className={styles.mainHeader}>Upgrade Your Membership</h1>
          <div className={styles.sectionHeaderContainer}>
            <h2 className={styles.sectionHeader}>Current plan for {petName}</h2>
          </div>
          <div className={styles.currentPlanContainer}>
            <img className={styles.petAvatar} src={avatarUrl} alt="Pet avatar" />
            <div className={styles.currentPlanDescription}>
              <div className={styles.currentPlanUnit}>
                {getRateUnitToDisplay(device.subscription!.subscriptionOption.displayedRateUnit)}
              </div>
              <div className={styles.currentPlanRate}>
                {device.subscription?.subscriptionOption.displayedRate}/
                {device.subscription?.subscriptionOption.displayedRateUnit}
              </div>
            </div>
          </div>
          <div className={styles.sectionHeaderContainer}>
            <h2 className={styles.sectionHeader}>Select a new plan</h2>
            <h3 className={styles.sectionSubheader}>Prepay and Save</h3>
          </div>
          <Chooser
            onSelect={(selectedSku) => {
              if (!selectedSku) {
                return;
              }

              dispatch(
                cartActions.setCartItem({
                  moduleId: device.moduleId,
                  sku: selectedSku,
                }),
              );
            }}
            selectedOption={selectedSubscriptionSku}
            groups={chooserGroups}
          />
          <Button
            className={styles.button}
            disabled={checkoutDisabled}
            onClick={() => {
              goToCheckout();
            }}
          >
            Continue
          </Button>
        </div>
      </div>
    </>
  );
}
