import { applyMiddleware, compose, createStore } from 'redux';
import thunk, { ThunkMiddleware } from 'redux-thunk';
import { affiliateDataInitialState } from '../reducers/affiliateData';
import rootReducer, { initialAppState } from '../reducers';
import { configInitialState } from '../reducers/config';
import { USER_EMAIL_ADDRESS_VARIABLE_NAME } from '../reducers/session';
import { AppState } from '../types';

const REDUX_STATE_ITEM_NAME = 'ecommerceStoreState';

/**
 * This dictionary overrides things in the app state before it is persisted and
 * when it is loaded from persistence.
 *
 * These items are not meant to be persisted -- they should be loaded every time.
 */
const overwriteStateItemsForPersistence = {
  ...configInitialState,
  ...affiliateDataInitialState,
};

const storedState = localStorage.getItem(REDUX_STATE_ITEM_NAME);
const persistedState: AppState = storedState
  ? {
      ...initialAppState,
      ...JSON.parse(storedState),
      ...overwriteStateItemsForPersistence,
    }
  : initialAppState;

const devToolsCompose = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
const composeEnhancers: typeof compose =
  process.env.NODE_ENV !== 'production' && devToolsCompose ? devToolsCompose : compose;

if (persistedState.session?.email) {
  (window as any)[USER_EMAIL_ADDRESS_VARIABLE_NAME] = persistedState.session.email || undefined;
}

const store = createStore(
  rootReducer,
  persistedState,
  composeEnhancers(applyMiddleware(thunk as ThunkMiddleware<AppState>)),
);

store.subscribe(() => {
  const state = store.getState();

  // Remove couponCode from cart, the source-of-truth between page loads for coupon code is the cookie.
  const { couponCode, ...cart } = state.storeShop.cart;

  const newPersistedState: AppState = {
    ...store.getState(),
    // Persist the redux state except for the products, we want to pull those every time
    // the page reloads
    ...overwriteStateItemsForPersistence,
    storeShop: {
      ...state.storeShop,
      cart,
    },
  };
  localStorage.setItem(REDUX_STATE_ITEM_NAME, JSON.stringify(newPersistedState));
});

export default store;
