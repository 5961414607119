import React from 'react';
import styles from '../../styles/SubscriptionContainer.module.scss';
import SubscriptionAppBar from '../../components/SubscriptionAppBar';
import Button from '../../../../components/Button';
import CancelConfirmationModal from '../../components/CancelConfirmationModal';
import { useHistory, useLocation } from 'react-router-dom';
import { usePurchaseSubscriptionContext } from '../../../PurchaseSubscription/context/PurchaseSubscriptionContext';
import { useZendeskCancellationTicket } from '../hooks/useZendesk';
import { inAppCancellation as events } from '../../../../lib/analytics/events';
import AppPaths from '../../../../AppPaths';
import { claimFreeMonthMutation } from '../../../../graphql-operations';
import { useMutation } from '@apollo/client';
import { getLoadingOrErrorElement } from '../../../../lib/util';
import giftImage from '../../../../assets/images/in_app_cancellation/gift.png';
import retentionOfferDogImage from '../../../../assets/images/in_app_cancellation/retention_offer_dog.jpeg';
import { SeriesAbbrev } from '../../../../types/Series';
import { AppState, gqlTypes } from '../../../../types';
import { useSelector } from 'react-redux';

export interface FreeMonthState {
  selectedCancelReason: gqlTypes.zendeskCancellationReason;
}

const monthToMonthSubscriptionId = 'month-to-month-subscription';

export default function FreeMonthOffer() {
  const { device } = usePurchaseSubscriptionContext();
  const history = useHistory();
  const location = useLocation<FreeMonthState>();
  const { selectedCancelReason } = location.state;
  if (!device.pet) {
    throw new Error('No pet found');
  }

  events.pageLoad({ petId: device.pet.id }, 'Free Month');

  const petName = device.pet.name;
  const petAvatarPath = device.pet.photos.first?.image?.fullSize ?? retentionOfferDogImage;

  const products = useSelector((state: AppState) => state.config.products);
  const freeMonthValueCents = products.find((p) => p.id === monthToMonthSubscriptionId)?.priceInCents ?? 1900;
  const freeMonthValueDollars = Math.round(freeMonthValueCents / 100);

  const { mutation: zendeskMutation, loading: zendeskMutationLoading } = useZendeskCancellationTicket({
    analyticsEvent: events.cancelFromFreeMonthPage,
    selectedCancelReason,
    device,
  });

  const [freeMonthMutation, freeMonthMutationState] = useMutation<
    gqlTypes.ECOMMERCE_claimFreeMonth,
    gqlTypes.ECOMMERCE_claimFreeMonthVariables
  >(claimFreeMonthMutation, {
    onCompleted: (data) => {
      const success = !!data.claimFreeMonth?.success;
      if (!success) {
        throw new Error('Failed to claim free month: contact customer support.');
      }
      history.push({
        pathname: AppPaths.Subscription.FreeMonthConfirmed(device.moduleId),
        search: window.location.search,
      });
    },
  });

  const loadingOrErrorElement =
    zendeskMutationLoading || getLoadingOrErrorElement(freeMonthMutationState.loading, freeMonthMutationState.error);
  if (loadingOrErrorElement) {
    return loadingOrErrorElement;
  }

  const downgradeableModels: SeriesAbbrev[] = ['S1', 'S2'];
  const collarModel = device.currentCollarModel as SeriesAbbrev;
  const offerDowngrade = downgradeableModels.includes(collarModel) && !!device.shouldOfferDowngradeDuringCancellation;

  const handleDowngrade = () => {
    history.push({
      pathname: AppPaths.Subscription.DowngradeS2(device.moduleId),
      state: {
        selectedCancelReason,
      },
      search: window.location.search,
    });
  };

  const handleCancel = () => {
    zendeskMutation({
      variables: {
        input: {
          recurlySubscriptionId: device.subscription!.id,
          cancellationReason: selectedCancelReason,
          additionalText: undefined,
        },
      },
    });
  };

  const handleClaimFreeMonth = () => {
    events.claimFreeMonth({ petId: device.pet!.id });
    freeMonthMutation({
      variables: {
        input: {
          moduleId: device.moduleId,
        },
      },
    });
  };

  return (
    <>
      <div className={styles.fullWidthWrapper}>
        <SubscriptionAppBar
          backButtonAnalyticsEvent={() => events.claimFreeMonthBack({ petId: device.pet?.id })}
          closeButtonAnalyticsEvent={() => events.claimFreeMonthClose({ petId: device.pet?.id })}
        />
        <div className={styles.subscriptionContainer}>
          <div className={styles.content}>
            <div className={styles.largeHeader}>
              <h1>How about a free month?</h1>
            </div>

            <p>We think there's more to Fi, and hope you and {petName} will accept this extra month, on us.</p>

            <p>
              Valued at ${freeMonthValueDollars}, this might give you more time to explore step counting, sleep
              tracking, and see how {petName} ranks.
            </p>

            <div className={styles.freeMonthOffer}>
              <div className={styles.freeMonthOfferImages}>
                <div className={styles.freeMonthOfferAvatar}>
                  <img src={petAvatarPath} alt={petName} />
                </div>
                <div className={styles.freeMonthOfferGiftBox}>
                  <img src={giftImage} alt="Gift box" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.buttonContainer}>
          <Button className={styles.button} onClick={() => handleClaimFreeMonth()}>
            Claim Free Month
          </Button>

          {offerDowngrade ? (
            <Button className={styles.button} tertiary={true} onClick={() => handleDowngrade()}>
              Continue with Cancellation
            </Button>
          ) : (
            <CancelConfirmationModal onContinue={() => handleCancel()} pageName={'Free Month'} petId={device.pet.id} />
          )}
        </div>
      </div>
    </>
  );
}
