import React from 'react';
import { useHistory } from 'react-router-dom';
import { ReactComponent as BackIcon } from '../../../../assets/images/icons/back.svg';
import { ReactComponent as CloseIcon } from '../../../../assets/images/icons/close.svg';
import styles from './SubscriptionAppBar.module.scss';

/** Used on confirmation screens where no back button is needed, but we still want consistent whitespace at top of screen. */
export function SubscriptionAppBarSpacer() {
  return <div className={styles.container} />;
}

interface SubscriptionAppBarProps {
  title?: string;
  backButtonExitsWebview?: boolean;
  forceBackButtonBehavior?: () => void;
  backButtonAnalyticsEvent: () => void;
  closeButtonAnalyticsEvent: () => void;
  noCloseButton?: boolean;
}

const CLEAR_STATE_FOR_PATHNAMES = ['/supplements'];

export default function SubscriptionAppBar({
  title,
  backButtonExitsWebview,
  backButtonAnalyticsEvent,
  forceBackButtonBehavior,
  closeButtonAnalyticsEvent,
  noCloseButton,
}: SubscriptionAppBarProps) {
  const history = useHistory();
  return (
    <div className={styles.container}>
      <div className={styles.subAppBarContent}>
        <div
          className={styles.button}
          onClick={() => {
            backButtonAnalyticsEvent();
            CLEAR_STATE_FOR_PATHNAMES.forEach((pathname) => history.replace(pathname, {}));
            // history.replace('/supplements', {});
            backButtonExitsWebview
              ? window.open('fi://closeappview')
              : forceBackButtonBehavior
              ? forceBackButtonBehavior()
              : history.goBack();
          }}
        >
          <BackIcon />
        </div>

        <div className={styles.title}>{title ?? ''}</div>

        <div
          className={styles.button}
          onClick={() => {
            closeButtonAnalyticsEvent();
            window.open('fi://closeappview');
          }}
        >
          {!noCloseButton && <CloseIcon />}
        </div>
      </div>
    </div>
  );
}
