import { createSlice, Dispatch, PayloadAction } from '@reduxjs/toolkit';
import { skusForCartItem } from '../lib/cart';
import { getProductsBySku } from '../lib/product';
import { cartActions as storeCartActions } from './storeCart';
import * as types from '../types';

const initialState: types.ECommerceConfig = {
  loaded: false,
  products: [],
  shippingOptions: [],
  siteConfig: {},
};

const name = 'config' as const;

const { actions, reducer } = createSlice({
  name,
  initialState,
  reducers: {
    set(_state: types.ECommerceConfig, { payload }: PayloadAction<types.ECommerceConfig>) {
      return {
        ...payload,
      };
    },
    clearConfig() {
      return initialState;
    },
  },
});

export const configActions = {
  ...actions,

  setConfig: (remoteConfig: types.RemoteConfig) => (dispatch: Dispatch, getState: () => types.AppState) => {
    dispatch(
      actions.set({
        loaded: true,
        products: remoteConfig.products,
        shippingOptions: remoteConfig.shippingOptions,
        siteConfig: remoteConfig.config,
      }),
    );

    // Remove any items from the cart that are no longer part of the product config
    // (I.e. if a chip reseller logs out, remove any Fi nano boxes.)
    // TODO: Do this for carts of all shops
    const cart = getState().storeShop.cart;
    const productsBySku = getProductsBySku(remoteConfig.products);

    const cartItemIdsToRemove = Object.values(cart.cartItems)
      .filter((cartItem) => skusForCartItem(cartItem).some((sku) => !productsBySku.get(sku)))
      .map((li) => li.cartItemId);

    cartItemIdsToRemove.forEach((cartItemId) => dispatch(storeCartActions.removeCartItem({ id: cartItemId })));
  },
};

export const configReducer = reducer;
export const configInitialState = { [name]: initialState };
