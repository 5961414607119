import classNames from 'classnames';
import React, { useContext } from 'react';
import { useDispatch } from 'react-redux';
import CheckoutContext from '../../lib/CheckoutContext';
import { centsToDollars } from '../../lib/util';
import styles from '../../styles/lineItem.module.scss';
import { ReactComponent as GiftBox } from '../../assets/images/icons/icon_gift_box.svg';
import RemoveLineItemButton from '../RemoveLineItemButton';

interface ReferralCodeLineItemProps {
  allowRemoveReferralCode?: boolean;
  appliedDiscountInCents: number;
  validationError?: string | null;
  referreeFreeMonth?: boolean;
  refereeCartDiscount?: boolean;
}

export default function ReferralCodeLineItem({
  allowRemoveReferralCode: allowRemoveCode,
  appliedDiscountInCents,
  validationError,
  referreeFreeMonth,
  refereeCartDiscount,
}: ReferralCodeLineItemProps) {
  const dispatch = useDispatch();
  const { checkoutActions } = useContext(CheckoutContext);
  // It's possible that using a referral code will only get the user a free month and no discount on the cart price
  // (e.g. activation fee is already waived)
  const willReceiveAnyBenefits = referreeFreeMonth || refereeCartDiscount;
  if (validationError || !willReceiveAnyBenefits) {
    return null;
  }

  return (
    <div className={classNames(styles.lineItem, styles.referralCodeLineItem)}>
      {allowRemoveCode && (
        <div className={styles.removeButtonRow}>
          <RemoveLineItemButton
            onClick={() => {
              dispatch(checkoutActions.removeReferral({}));
            }}
          />
        </div>
      )}
      <div className={styles.itemRow}>
        <div className={styles.lineItemImage}>
          <GiftBox />
        </div>
        <div className={styles.lineItemBody}>
          <div className={styles.lineItemPriceRows}>
            <div className={styles.lineItemPriceRow}>
              <div>
                {refereeCartDiscount && <div>${centsToDollars(appliedDiscountInCents)} Off</div>}
                <div>{referreeFreeMonth && '1 Month Free'}</div>
              </div>
              {!!appliedDiscountInCents && refereeCartDiscount && (
                <div className={styles.lineItemPrice}>-${centsToDollars(appliedDiscountInCents)}</div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
