import React, { useMemo } from 'react';
import { expectUnreachable } from '../../../../lib/util';
import { BandSizeDetails, f1BandSizeOptions, f3BandSizeOptions, sizeDetailsForSizeOption } from '../../../../lib/size';
import { BandSeries, SizeOption } from '../../../../types';
import SizingBlock from './SizingBlock';
import styles from './SizingGuide.module.scss';

interface SizingGuideProps {
  bandSeries: BandSeries;
  onSelect: (newValue: SizeOption) => void;
}

interface SizingBlockProps {
  sizeOption: SizeOption;
  bandSizeDetails: BandSizeDetails;
  onSelect: (newValue: SizeOption) => void;
}

function SizingBlockForSizeOption({ sizeOption, bandSizeDetails, onSelect }: SizingBlockProps) {
  return (
    <SizingBlock
      key={sizeOption}
      dogList={bandSizeDetails.dogList}
      sizeName={bandSizeDetails.sizeName}
      neckSize={bandSizeDetails.neckSize}
      bandWidth={bandSizeDetails.bandWidth}
      onClick={() => onSelect(sizeOption)}
    />
  );
}

export default function SizingGuide({ bandSeries, onSelect }: SizingGuideProps) {
  const allSizeOptions = useMemo(() => {
    if (bandSeries === BandSeries.F1) {
      return f1BandSizeOptions.map((sizeOption) => ({
        sizeOption,
        bandSizeDetails: sizeDetailsForSizeOption({ bandSeries, sizeOption }),
      }));
    } else if (bandSeries === BandSeries.F3) {
      return f3BandSizeOptions.map((sizeOption) => ({
        sizeOption,
        bandSizeDetails: sizeDetailsForSizeOption({ bandSeries, sizeOption }),
      }));
    } else {
      expectUnreachable(bandSeries);
      throw new Error(`Unsupported band series: ${bandSeries}`);
    }
  }, [bandSeries]);

  const sizeOptionList = useMemo(() => {
    return allSizeOptions.map(
      ({ bandSizeDetails, sizeOption }) =>
        bandSizeDetails && (
          <SizingBlockForSizeOption
            key={sizeOption}
            sizeOption={sizeOption}
            bandSizeDetails={bandSizeDetails}
            onSelect={onSelect}
          />
        ),
    );
  }, [allSizeOptions, onSelect]);

  return (
    <div className={styles.main}>
      <h2>Collar Sizing</h2>
      <div className={styles.sizeOptionList}>
        {sizeOptionList}
        <div className={styles.stillUnsure}>
          <h3>Still unsure?</h3>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://support.tryfi.com/hc/en-us/articles/360018860034-Sizing-and-Fit"
          >
            Check out this article
          </a>
          <p>
            *Recommendations based on average breed sizes. All dogs may vary. Measure your dog's existing collar length
            for the most accurate size estimate.
          </p>
        </div>
      </div>
    </div>
  );
}
