import React, { useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { useSelector } from 'react-redux';

import AppPaths from '../../../AppPaths';
import { getLoadingOrErrorElement, mkError } from '../../../lib/util';
import { supplementsManagement as events } from '../../../lib/analytics/events';
import { BillingSubscription, SubscriptionOption } from './SplashPage';
import Button from '../../../components/Button';
import PausePill from './PausePill/PausePill';
import {
  pauseSubscriptionMutation,
  resumeSubscriptionMutation,
  supplementsSubscriptionsQuery,
  updateSubscriptionMutation,
} from '../../../graphql-operations';
import { sizeSortOrder } from '../../Checkout/components/Supplements/ProductInfo/ProductInfo';
import styles from '../styles/SubscriptionContainer.module.scss';
import SubscriptionAppBar from '../components/SubscriptionAppBar';
import SupplementPill from './components/SupplementPill';
import * as types from '../../../types';

interface ManageSubscriptionState {
  subscription: BillingSubscription;
  pendingSubscription: SubscriptionOption;
}

export default function ManageSubscription() {
  const eventPageName = 'In-App Supplements > Manage';
  const pageName = 'Manage subscription';
  events.pageLoad({}, eventPageName);

  const location = useLocation<ManageSubscriptionState>();
  const { subscription, pendingSubscription } = location.state;
  const history = useHistory();
  const [selectedOption, setSelectedOption] = useState('');
  const updateButtonDisabled = selectedOption === '';

  // If there are pending changes (meaning the user has updated this subscription previously), we need to get more
  // information about the future plan so we can display it to the user. Behind the scenes, in Recurly, their plan will only be updated
  // on their next bill date (to avoid shipping a new bag of supplements prematurely), but we make an immediate change to the next bill
  // date, which appears in the UI, so it should appear as though the plan change has also happened immediately.
  const allProducts = useSelector((state: types.AppState) => state.config.products);
  // If there is a pending change on the subscription, filter supplement options based on the future plan.
  const otherSupplementProductOptions = useMemo((): types.ISupplementSubscriptionProduct[] => {
    const unsortedSupplements = allProducts.filter(
      (p) =>
        p.category === types.ProductCategory.SUPPLEMENT &&
        (pendingSubscription ? p.sku !== pendingSubscription.sku : p.sku !== subscription.subscriptionOption.sku),
    ) as types.ISupplementSubscriptionProduct[];
    // Sort items so they appear in product selector in a logical order.
    return unsortedSupplements.sort(
      (a, b) => sizeSortOrder(b.recommendedForDogSize) - sizeSortOrder(a.recommendedForDogSize),
    );
  }, [allProducts, subscription, pendingSubscription]);

  const [pauseMutation, pauseMutationState] = useMutation<
    types.gqlTypes.ECOMMERCE_pauseSupplementSubscription,
    types.gqlTypes.ECOMMERCE_pauseSupplementSubscriptionVariables
  >(pauseSubscriptionMutation, {
    refetchQueries: [{ query: supplementsSubscriptionsQuery }],
    onCompleted: (data) => {
      const success = !!data.skipSupplementShipment?.success;
      if (!success) {
        throw new Error('Failed to pause subscription: contact customer support.');
      }
      history.push({
        pathname: AppPaths.Supplements.Root,
        state: { toast: { success: true, change: 'updated' } },
        search: window.location.search,
      });
    },
  });

  const [resumeMutation, resumeMutationState] = useMutation<
    types.gqlTypes.ECOMMERCE_resumeSupplementSubscription,
    types.gqlTypes.ECOMMERCE_resumeSupplementSubscriptionVariables
  >(resumeSubscriptionMutation, {
    refetchQueries: [{ query: supplementsSubscriptionsQuery }],
    onCompleted: (data) => {
      const success = !!data.resumeSupplementSubscription?.success;
      if (!success) {
        throw new Error('Failed to resume subscription: contact customer support.');
      }
      history.push({
        pathname: AppPaths.Supplements.Root,
        state: { toast: { success: true, change: 'updated' } },
        search: window.location.search,
      });
    },
  });

  const [updateMutation, updateMutationState] = useMutation<
    types.gqlTypes.ECOMMERCE_updateSupplementSubscription,
    types.gqlTypes.ECOMMERCE_updateSupplementSubscriptionVariables
  >(updateSubscriptionMutation, {
    refetchQueries: [{ query: supplementsSubscriptionsQuery }],
    onCompleted: (data) => {
      const success = !!data.updateSupplementSubscription?.success;
      if (!success) {
        throw new Error('Failed to update subscription: contact customer support.');
      }
      history.push({
        pathname: AppPaths.Supplements.Root,
        state: { toast: { success: true, change: 'updated' } },
        search: window.location.search,
      });
    },
  });

  const pauseLoading = getLoadingOrErrorElement(pauseMutationState.loading, pauseMutationState.error);
  const resumeLoading = getLoadingOrErrorElement(resumeMutationState.loading, resumeMutationState.error);
  const updateLoading = getLoadingOrErrorElement(updateMutationState.loading, updateMutationState.error);
  if (pauseLoading) {
    return pauseLoading;
  }
  if (resumeLoading) {
    return resumeLoading;
  }
  if (updateLoading) {
    return updateLoading;
  }

  if (!subscription) {
    return mkError('Subscription not found');
  }

  const paused = !!subscription.isPaused;

  const handleUpdate = () => {
    if (selectedOption === 'pauseClicked') {
      handlePauseClick();
    } else {
      events.updateSubscription({ subscriptionId: subscription.id, newSku: selectedOption });
      updateMutation({
        variables: {
          input: {
            newSku: selectedOption,
            recurlySubscriptionId: subscription.id,
          },
        },
      });
    }
  };

  const handleCancel = () => {
    events.cancelSubscription({ subscriptionId: subscription.id });
    history.push({
      pathname: AppPaths.Supplements.Survey(subscription.id),
      state: { subscription },
      search: window.location.search,
    });
  };

  const handlePauseClick = () => {
    if (paused) {
      resumeMutation({
        variables: {
          input: {
            recurlySubscriptionId: subscription.id,
          },
        },
      });
      events.resumeSubscription({ subscriptionId: subscription.id });
      history.push({
        pathname: AppPaths.Supplements.Root,
        state: { toast: { success: true, change: 'updated' } },
        search: window.location.search,
      });
    } else {
      pauseMutation({
        variables: {
          input: {
            recurlySubscriptionId: subscription.id,
          },
        },
      });
      events.pauseSubscription({ subscriptionId: subscription.id });
      history.push({
        pathname: AppPaths.Supplements.Root,
        state: { toast: { success: true, change: 'updated' } },
        search: window.location.search,
      });
    }
  };

  return (
    <>
      <div className={styles.fullWidthWrapper}>
        <SubscriptionAppBar
          title={pageName}
          backButtonAnalyticsEvent={() => events.goBackFromPage({}, eventPageName)}
          closeButtonAnalyticsEvent={() => events.closePage({}, eventPageName)}
        />
        <div className={styles.subscriptionContainer}>
          <h1>Current subscription</h1>
          <SupplementPill
            showManageButton={false}
            subscription={subscription}
            subscriptionDetails={null}
            version={'informational'}
            pendingPlan={pendingSubscription ?? null}
            paused={paused}
          />

          <h1>Modify subscription</h1>
          <h3>Update shipping frequency</h3>

          {otherSupplementProductOptions &&
            otherSupplementProductOptions.map((subOption: any) => (
              <SupplementPill
                showManageButton={false}
                subscription={subscription}
                subscriptionDetails={subOption}
                version={'selectable'}
                handleClick={() => {
                  setSelectedOption(subOption.sku);
                }}
                selected={selectedOption === subOption.sku}
                paused={paused}
                key={subOption.sku}
              />
            ))}
          <h3>{paused ? 'Changed your mind?' : 'Need a pause?'}</h3>
          <PausePill
            subscription={subscription}
            paused={paused}
            handleClick={() => setSelectedOption('pauseClicked')}
            selected={selectedOption === 'pauseClicked'}
            key={subscription.id}
          />
        </div>
        <div className={styles.buttonContainer}>
          <Button className={styles.button} onClick={handleUpdate} disabled={updateButtonDisabled}>
            Update subscription
          </Button>
          <Button tertiary={true} className={styles.button} onClick={handleCancel}>
            Cancel subscription
          </Button>
        </div>
      </div>
    </>
  );
}
