import React, { useEffect, useState } from 'react';
import { useCartPricing } from '../../contexts/CartPricingContext';
import { activeBillingSubscriptionsQuery, userPetsQuery } from '../../graphql-operations';
import { CartPricing, CartItemCreditReason, gqlTypes } from '../../types';
import client from '../../lib/fi-api/client';
import { useError } from '../ErrorMessage';
import styles from './MultidogDiscountExistingCollars.module.scss';

const householdDiscountReason = (cartPricing: CartPricing): CartItemCreditReason | null => {
  if (!cartPricing.cartItemCredits) return null;

  return (
    Object.values(cartPricing.cartItemCredits)
      .flat()
      .find(
        (credit) =>
          credit.creditReason === 'household_discount_five_percent' ||
          credit.creditReason === 'household_discount_ten_percent',
      )?.creditReason || null
  );
};

export default function MultidogDiscountExistingCollars() {
  const { error, setError } = useError();
  const [activeBillingSubscriptions, setActiveBillingSubscriptions] = useState<
    gqlTypes.activeBillingSubscriptions | undefined
  >(undefined);
  const [userPets, setUserPets] = useState<gqlTypes.userPets | undefined>(undefined);

  useEffect(() => {
    if (activeBillingSubscriptions) {
      return;
    }
    client
      .query<gqlTypes.activeBillingSubscriptions>({
        query: activeBillingSubscriptionsQuery,
        fetchPolicy: 'network-only',
      })
      .then((result) => setActiveBillingSubscriptions(result.data))
      .catch((err) => setError(err.message));
  }, [activeBillingSubscriptions, setError]);

  useEffect(() => {
    if (userPets) {
      return;
    }
    client
      .query<gqlTypes.userPets>({
        query: userPetsQuery,
        fetchPolicy: 'network-only',
      })
      .then((result) => setUserPets(result.data))
      .catch((err) => setError(err.message));
  }, [userPets, setError]);

  const cartPricing = useCartPricing();
  const cartHouseholdDiscount = householdDiscountReason(cartPricing);
  if (error || !cartHouseholdDiscount || !activeBillingSubscriptions || !userPets) {
    return null;
  }

  const activeSubscriptionIds = new Set(
    activeBillingSubscriptions.currentUser.billingAccount?.subscriptions?.map((sub) => sub.id),
  );

  const savingsRate = cartHouseholdDiscount === 'household_discount_five_percent' ? 0.05 : 0.1;
  const subscribedPets = userPets.currentUser.userHouseholds.flatMap((household) =>
    household.household.pets
      .filter((pet) => pet.device?.subscription && activeSubscriptionIds.has(pet.device.subscription.id))
      .map((pet) => ({
        id: pet.id,
        name: pet.name,
        amountSaved: `$${((pet.device!.subscription!.unitAmountInCents * savingsRate) / 100).toFixed(2)}`,
        photo: pet.photos.first?.image.fullSize ?? '/dog_placeholder.png',
      })),
  );
  if (subscribedPets.length === 0) {
    return null;
  }

  return (
    <div className={styles.subscribedPetContainer}>
      {subscribedPets.map((subscribedPet) => (
        <div className={styles.subscribedPet}>
          <img className={styles.subscribedPetAvatar} src={subscribedPet.photo} alt="Pet avatar" />
          <div className={styles.subscribedPetDescription}>
            {subscribedPet.name}'s monthly Fi membership will save {savingsRate * 100}% (
            <span className={styles.subscribedPetSavings}>{subscribedPet.amountSaved}</span>) at renewal
          </div>
        </div>
      ))}
      <div className={styles.subscribedPetFooter}>
        Your existing {subscribedPets.length > 1 ? 'collars' : 'collar'} with the Multi-dog discount
      </div>
    </div>
  );
}
