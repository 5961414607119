import { useSelector } from 'react-redux';
import BandVariantOptions from '../components/BandVariantOptions';
import { CarouselController } from '../../../components/Carousel';
import CollarAddToBagButton from './components/CollarAddToBagButton';
import { DesktopImageGallery, MobileImageGallery, imageUrlForVariant } from './components/ImageGallery';
import * as events from '../../../lib/analytics/events';
import FAQ from './components/FAQ';
import { LowestSubscriptionBundlePrice } from './components/ProductTitle/ProductTitle';
import MembershipValueProps from './components/MembershipValueProps';
import { getPriceForRateUnit, minBy } from '../../../lib/util';
import NewFeatures from './components/NewFeatures';
import ProductTitle from './components/ProductTitle';
import React, { useCallback, useEffect, useRef, useMemo, useState, MutableRefObject } from 'react';
import { series3CollarId } from '../../../types';
import SoldOutBanner from '../components/SoldOutBanner';
import styles from '../ProductDetails.module.scss';
import SubscriptionOptions from '../../../components/SubscriptionOptions';
import * as types from '../../../types';
import useIsSkuBlocked from '../hooks/useIsSkuBlocked';
import useSubscriptionProducts from '../../../hooks/useSubscriptionProducts';
import useSubscriptionSelector from '../hooks/useSubscriptionSelector';
import useVariantSelector from '../hooks/useVariantSelector';
import SubscriptionUpsellModal from '../../../components/SubscriptionUpsellModal';
import { getFiGrowthBook } from '../../../lib/growthbook';
import gsap from 'gsap';
import ScrollToPlugin from 'gsap/ScrollToPlugin';
import CustomEase from 'gsap/CustomEase';
import { commonDetailsForSize } from '../../../lib/size';
import { commonDetailsForSubscription } from '../../../lib/subscription';
import classNames from 'classnames';
import HideChatWidget from '../../../components/ZendeskChat/HideChatWidget';
import NavBar from '../../../components/AppBar/NavBar';
import ProductSummary from './components/ProductSummary';

const PRODUCT_TITLE = 'SERIES 3';
const SUBSCRIPTION_TITLE = 'Select your membership';

gsap.registerPlugin(ScrollToPlugin);
gsap.registerPlugin(CustomEase);
CustomEase.create('scrollTo', '0.65, 0, 0.35, 1');

interface OnChangeHandlerProps {
  desktopCarouselControllerRef: React.MutableRefObject<CarouselController | undefined>;
  priceInCents: number;
  productName: string;
  subscriptionPriceInCents?: number;
  currentCarouselImage: number;
  newEcomFlow: boolean;
}

function useOnChangeHandlers({
  desktopCarouselControllerRef,
  priceInCents,
  productName,
  subscriptionPriceInCents,
  currentCarouselImage,
  newEcomFlow,
}: OnChangeHandlerProps) {
  // For analytics events, since we're viewing an S3 collar + subscription membership bundle, we should add the
  // subscription price to the collar price
  const totalPriceInCents = useMemo(
    () => priceInCents + (subscriptionPriceInCents ?? 0),
    [priceInCents, subscriptionPriceInCents],
  );

  const onColorChange = useCallback(
    (newVariant: types.IVariant) => {
      events.series3CollarColorSwitch({
        color: newVariant.options.color,
        priceInCents: totalPriceInCents,
        productName,
        size: newVariant.options.size,
        sku: newVariant.sku,
      });

      // Reset the desktop carousel to the first image when the color changes
      if (!newEcomFlow || currentCarouselImage !== 1) {
        desktopCarouselControllerRef.current?.select(0);
      }
    },
    [desktopCarouselControllerRef, productName, totalPriceInCents, currentCarouselImage, newEcomFlow],
  );

  const onSizeChange = useCallback(
    (newVariant: types.IVariant) => {
      events.series3CollarSizeSwitch({
        color: newVariant.options.color,
        priceInCents: totalPriceInCents,
        productName,
        size: newVariant.options.size,
        sku: newVariant.sku,
      });
    },
    [productName, totalPriceInCents],
  );

  const onSelectedVariantChange = useCallback(
    (newVariant: types.IVariant) => {
      events.series3CollarVariantSwitch({
        color: newVariant.options.color,
        priceInCents: totalPriceInCents,
        productName,
        size: newVariant.options.size,
        sku: newVariant.sku,
      });
    },
    [productName, totalPriceInCents],
  );

  return {
    onColorChange,
    onSizeChange,
    onSelectedVariantChange,
  };
}

const ctaButtonText = (hasUpdatedSize: boolean, hasUpdatedSubscriptionSku: boolean, newEcomFlow: boolean): string => {
  if (newEcomFlow && !hasUpdatedSize) {
    return 'Pick Your Size';
  }
  if (newEcomFlow && !hasUpdatedSubscriptionSku) {
    return 'Pick Your Membership';
  }
  return 'Add To Cart';
};

const ctaDescriptionText = (
  selectedVariant: types.IVariant,
  selectedSubscription: types.ISubscriptionProduct | undefined,
): string => {
  const toTitleCase = (str: string): string => {
    return str
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
  };

  let description =
    toTitleCase(selectedVariant.options.color) + ' · ' + commonDetailsForSize(selectedVariant.options.size).sizeName;
  if (selectedSubscription) {
    description += ' · ' + commonDetailsForSubscription(selectedSubscription).subscriptionName;
  }
  return description;
};

interface Series3CollarDetailsProps {
  addBundleCartItem: (variantSku: string, subscriptionSku: string) => void;
  availableSubscriptions?: types.ISubscriptionProduct[];
  hideSubscriptionOptions?: boolean;
  productName: string;
  preselectedSubscriptionSku?: string;
  priceInCents: number;
  variants: types.IVariant[];
  isUpgradePath?: boolean;
}

function lowestPriceForSubscriptionProducts(
  subscriptionProducts: types.ISubscriptionProduct[],
): LowestSubscriptionBundlePrice {
  const lowestMonthlyRate = minBy(
    // Exclude "buy it" plans from the lowest rate calculation
    subscriptionProducts.filter((p) => !p.buyItMembership),
    (p) => p.priceInCents / p.renewalMonths,
  )!;
  const { unit: cheapestSubscriptionRateUnit, displayRate: cheapestSubscriptionRate } = getPriceForRateUnit(
    lowestMonthlyRate,
    'week',
  );
  return {
    cheapestSubscriptionRate,
    cheapestSubscriptionRateUnit,
  };
}

export default function Series3CollarDetails({
  addBundleCartItem,
  availableSubscriptions,
  hideSubscriptionOptions,
  productName,
  preselectedSubscriptionSku,
  priceInCents,
  variants,
  isUpgradePath,
}: Series3CollarDetailsProps) {
  const desktopCarouselControllerRef = useRef<CarouselController>();
  const referralCode = useSelector((state: types.AppState) => state.storeShop.cart.referralCode);

  // Can be used to display a coupon-based pill above the prepaid plans
  const couponCodePillDisplay = useMemo((): { shouldShow: boolean; title?: string; subtitle?: string } => {
    return {
      shouldShow: false,
    };
  }, []);

  const allSeries3Subscriptions = useSubscriptionProducts(series3CollarId);
  const [showUpsellModal, setShowUpsellModal] = useState<boolean>(false);
  const [currentCarouselImage, setCurrentCarouselImage] = useState<number>(0);

  const subscriptionProducts: types.ISubscriptionProduct[] = useMemo(
    () => availableSubscriptions ?? allSeries3Subscriptions,
    [allSeries3Subscriptions, availableSubscriptions],
  );

  const { selectedSubscription, selectSubscriptionSku, hasUpdatedSubscriptionSku } = useSubscriptionSelector(
    subscriptionProducts,
    preselectedSubscriptionSku,
  );

  const shouldShowSubscriptionOptions = useMemo(() => {
    if (!selectedSubscription) {
      return true;
    }

    return !hideSubscriptionOptions;
  }, [selectedSubscription, hideSubscriptionOptions]);

  // A subscription product that we should try to upsell to the user
  const subscriptionToUpsell: types.ISubscriptionProduct | undefined = useMemo(() => {
    if (!selectedSubscription) {
      return undefined;
    }

    const planCodeToUpsell = selectedSubscription.attemptUpsellToPlanCode;
    if (!planCodeToUpsell) {
      return undefined;
    }

    return subscriptionProducts.find((p) => p.sku === planCodeToUpsell);
  }, [selectedSubscription, subscriptionProducts]);

  const newEcomFlow = getFiGrowthBook().getFeatureValue<boolean>('ecom-flow-revamp-cta', false) && !isUpgradePath;
  const { onColorChange, onSizeChange, onSelectedVariantChange } = useOnChangeHandlers({
    desktopCarouselControllerRef,
    priceInCents,
    productName,
    subscriptionPriceInCents: selectedSubscription?.priceInCents,
    currentCarouselImage,
    newEcomFlow,
  });

  const { changeVariantOptions, selectedVariant, hasUpdatedSize } = useVariantSelector({
    onColorChange,
    onSelectedVariantChange,
    onSizeChange,
    variants,
    newEcomFlow,
  });
  const isBlocked = useIsSkuBlocked(selectedVariant.sku);
  const sizePickerRef: MutableRefObject<HTMLDivElement | null> = useRef(null);
  const subscriptionPickerRef: MutableRefObject<HTMLDivElement | null> = useRef(null);

  // Analytics - S3 collar page viewed
  useEffect(() => {
    events.series3CollarViewed();
  }, []);

  // Add to bag logic
  const addToBag = useCallback(
    (variant: types.IVariant, subscriptionProduct: types.ISubscriptionProduct) => {
      events.series3CollarAddedToBag({
        color: variant.options.color,
        priceInCents,
        productName,
        size: variant.options.size,
        sku: variant.sku,
      });
      events.series3CollarActionButtonClick({
        color: variant.options.color,
        priceInCents,
        productName,
        size: variant.options.size,
        sku: variant.sku,
      });

      events.subscriptionAddedToBag({
        priceInCents: subscriptionProduct.priceInCents,
        productId: subscriptionProduct.id,
        productName: subscriptionProduct.name,
        sku: subscriptionProduct.sku,
      });

      addBundleCartItem(variant.sku, subscriptionProduct.sku);
    },
    [addBundleCartItem, priceInCents, productName],
  );

  const onAddToBagClicked = useCallback(() => {
    const offsetY = Array.from(document.querySelectorAll('.promotionBanner')).reduce(
      (total, el) => (total + (el as HTMLElement).style.zIndex === '-1' ? 0 : (el as HTMLElement).offsetHeight),
      0,
    );
    if (newEcomFlow && !hasUpdatedSize) {
      if (sizePickerRef.current) {
        gsap.to(window, {
          duration: 1.2,
          scrollTo: { y: sizePickerRef.current, offsetY: offsetY },
          ease: 'scrollTo',
        });
      }
      return false;
    }

    if (isBlocked || !selectedSubscription || (newEcomFlow && !hasUpdatedSubscriptionSku)) {
      if (subscriptionPickerRef.current) {
        gsap.to(window, {
          duration: 1.2,
          scrollTo: { y: subscriptionPickerRef.current, offsetY: offsetY },
          ease: 'scrollTo',
        });
      }
      return false;
    }

    if (subscriptionToUpsell) {
      setShowUpsellModal(true);
      return false;
    }

    addToBag(selectedVariant, selectedSubscription);
    return true;
  }, [
    isBlocked,
    selectedSubscription,
    subscriptionToUpsell,
    addToBag,
    selectedVariant,
    hasUpdatedSize,
    hasUpdatedSubscriptionSku,
    newEcomFlow,
  ]);

  return (
    <>
      {selectedSubscription && subscriptionToUpsell && (
        <SubscriptionUpsellModal
          onAccept={() => addToBag(selectedVariant, subscriptionToUpsell)}
          onClose={() => setShowUpsellModal(false)}
          onIgnore={() => addToBag(selectedVariant, selectedSubscription)}
          open={showUpsellModal}
          selectedSubscription={selectedSubscription}
          subscriptionToUpsell={subscriptionToUpsell}
        />
      )}
      <div
        className={classNames(styles.productContainer, {
          [styles.ecomFlowRevamp]: newEcomFlow,
          'ecom-flow-revamp': newEcomFlow,
          'ecom-flow-revamp-light': currentCarouselImage === 3 || currentCarouselImage === 5,
        })}
      >
        <div className={styles.galleryContainer}>
          <DesktopImageGallery
            carouselControllerRef={desktopCarouselControllerRef}
            selectedVariant={selectedVariant}
            pageDots={newEcomFlow}
            newEcomFlow={newEcomFlow}
            onChange={(idx) => setCurrentCarouselImage(idx)}
          >
            {newEcomFlow && <NavBar />}
          </DesktopImageGallery>
        </div>
        <div className={classNames(styles.productDetails, 'product-details')}>
          {!newEcomFlow && (
            <ProductTitle
              productName={PRODUCT_TITLE}
              price={lowestPriceForSubscriptionProducts(subscriptionProducts)}
            />
          )}
          {newEcomFlow && (
            <div className={styles.productTitleContainer}>
              <div className={styles.productTitle}>Fi Series 3</div>
              <div className={styles.productTitleDescription}>Pick your color to get started</div>
            </div>
          )}
          {!newEcomFlow && <MobileImageGallery />}

          {!newEcomFlow && <NewFeatures />}
          <BandVariantOptions
            bandSeries={types.BandSeries.F3}
            changeVariantOptions={changeVariantOptions}
            selectedVariant={selectedVariant}
            selectedVariantImageUrl={imageUrlForVariant(selectedVariant)}
            variants={variants}
            sizePickerRef={sizePickerRef}
            startSizeAsUnselected={newEcomFlow && !hasUpdatedSize}
            hideMakersLink={newEcomFlow}
            newEcomFlow={newEcomFlow}
          />

          {isBlocked && <SoldOutBanner />}

          {newEcomFlow && <HideChatWidget />}

          {shouldShowSubscriptionOptions && (
            <SubscriptionOptions
              allowNoSubscription={false}
              onSubscriptionChange={selectSubscriptionSku}
              selectedSubscriptionSku={selectedSubscription?.sku}
              subscriptionProducts={subscriptionProducts}
              title={newEcomFlow ? 'Pick your membership' : SUBSCRIPTION_TITLE}
              showReferralPill={!!referralCode}
              couponPillDisplay={couponCodePillDisplay}
              productName={PRODUCT_TITLE}
              subscriptionPickerRef={subscriptionPickerRef}
              startAsUnselected={newEcomFlow}
              disableSection={newEcomFlow && !hasUpdatedSize}
              newEcomFlow={newEcomFlow}
            />
          )}

          <MembershipValueProps selectedVariant={selectedVariant} newEcomFlow={newEcomFlow} />
          <CollarAddToBagButton
            disabled={isBlocked || !selectedSubscription}
            onAddToBag={onAddToBagClicked}
            ctaText={ctaButtonText(hasUpdatedSize, hasUpdatedSubscriptionSku, newEcomFlow)}
            stickyCTA={newEcomFlow}
            fadeInRef={newEcomFlow && !hasUpdatedSize ? sizePickerRef : undefined}
            isVisibleOverride={newEcomFlow && hasUpdatedSize}
            descriptionText={
              newEcomFlow && hasUpdatedSize && hasUpdatedSubscriptionSku
                ? ctaDescriptionText(selectedVariant, selectedSubscription)
                : null
            }
          />
          {!newEcomFlow && <div className={styles.footerValueProps}>Free shipping | Try risk free</div>}
        </div>
      </div>
      {newEcomFlow && <ProductSummary />}
      <FAQ newEcomFlow={newEcomFlow} />
    </>
  );
}
