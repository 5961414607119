import * as types from '../types';
import { getProductsBySku } from './product';

export function cartHasSomePhysicalProducts(cart: types.Cart, products: types.IProduct[]) {
  const productMap = getProductsBySku(products);
  return Object.values(cart.cartItems).some((cartItem) => {
    const product = productMap.get(cartItem.lineItem.sku);
    return product && product.physical;
  });
}

export function isBundleCartItem(cartItem: types.CartItem): cartItem is types.BundleCartItem {
  return 'subscriptionLineItem' in cartItem;
}

export function isGiftCardCartItem(cartItem: types.CartItem): cartItem is types.GiftCardCartItem {
  return 'giftCardDelivery' in cartItem;
}

export function isForModuleCartItem(
  cartItem: types.CartItem,
): cartItem is types.ForModuleBundleCartItem | types.ForModuleCartItem {
  return 'forModuleId' in cartItem;
}

export function skusForCartItem(cartItem: types.CartItem) {
  if (isBundleCartItem(cartItem)) {
    const addonSkus = cartItem.addonLineItems?.map((lineItem) => lineItem.sku) ?? [];
    return [cartItem.lineItem.sku, cartItem.subscriptionLineItem.sku, ...addonSkus];
  }

  return [cartItem.lineItem.sku];
}

export function giftCardCartItems(cart: types.Cart) {
  return Object.values(cart.cartItems).filter(isGiftCardCartItem);
}

export function cartHasItems(cart: types.Cart) {
  return Object.keys(cart.cartItems).length > 0;
}
