import { useCallback, useContext, useMemo, useState } from 'react';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useError } from '../../../../../components/ErrorMessage';
import useCheckoutPaths from '../../../../../hooks/useCheckoutPaths';
import useProductSkuMap, { ProductSkuMap } from '../../../../../hooks/useProductSkuMap';
import * as events from '../../../../../lib/analytics/events';
import CheckoutContext from '../../../../../lib/CheckoutContext';
import { getCustomerMessageFromApolloError, logInternalError } from '../../../../../lib/errors';
import { priceCartForCheckout } from '../../../../../lib/pricing';
import { expectUnreachable, generateID } from '../../../../../lib/util';
import SupplementsLocationState from '../../../../../models/SupplementsLocationState';
import { cartActions } from '../../../../../reducers/storeCart';
import { ISupplementSubscriptionProduct, SizeOption } from '../../../../../types';
import * as types from '../../../../../types';
import styles from './ProductInfo.module.scss';
import ImageGallery from '../../../../../components/ImageGallery/ImageGallery';
import ValuePropsBox from '../../../../ProductDetails/Series3CollarDetails/components/ValuePropsBox/ValuePropsBox';
import { BENEFITS_VALUE_PROPS } from './ValueProps';
import classNames from 'classnames';
import TermsAndConditions from './TermsAndConditions';
import FAQ from './FAQ';
import ProductSelector from '../ProductSelector/ProductSelector';
import SupplementPurchaseButtons from './SupplementPurchaseButtons';

function createSupplementCartItem(product: ISupplementSubscriptionProduct): types.StoreCartItem {
  return {
    cartItemId: generateID(),
    lineItem: {
      lineItemId: generateID(),
      sku: product.sku,
    },
    // Only ever purchase one supplement subscription
    quantity: 1,
  };
}

export function sizeSortOrder(size: SizeOption) {
  switch (size) {
    case SizeOption.XSmall:
      return 0;
    case SizeOption.Small:
      return 1;
    case SizeOption.Medium:
      return 3;
    case SizeOption.Large:
      return 4;
    case SizeOption.XLarge:
      return 5;
    default:
      expectUnreachable(size);
      throw new Error(`Unexpected product size: ${size}`);
  }
}

/**
 * Returns the supplement that matches the dog size, or undefined if none match.
 * @param supplements - list of supplements to search
 * @param dogSize - dog size to match
 */
function getMatchingSupplementForDogSize(
  supplements: ISupplementSubscriptionProduct[],
  dogSize: types.SizeOption,
): ISupplementSubscriptionProduct | undefined {
  let matchingSize = dogSize;
  // XSmall and XLarge supplements are not available, so use the next smallest/largest size
  if (matchingSize === types.SizeOption.XSmall) {
    matchingSize = types.SizeOption.Small;
  } else if (matchingSize === types.SizeOption.XLarge) {
    matchingSize = types.SizeOption.Large;
  }

  return supplements.find((s) => s.recommendedForDogSize === matchingSize);
}

/**
 * Returns the smallest dog collar size ordered, or undefined if none are ordered.
 */
function getSmallestCollarOrdered(cart: types.Cart, productSkuMap: ProductSkuMap): types.SizeOption | undefined {
  let smallestSize: types.SizeOption | undefined = undefined;

  for (const cartItem of Object.values(cart.cartItems)) {
    const { variant } = productSkuMap.get(cartItem.lineItem.sku) ?? {};

    if (variant && (!smallestSize || sizeSortOrder(variant.options.size) < sizeSortOrder(smallestSize))) {
      smallestSize = variant.options.size;
    }
  }

  return smallestSize;
}

export interface ProductInfoProps {
  originalCart: types.Cart;
  originalCartPricing: types.CartPricing;
  originalOrderId: string;
}

// Static component for supplements page/experiment
export default function ProductInfo({ originalCart, originalCartPricing, originalOrderId }: ProductInfoProps) {
  const history = useHistory<SupplementsLocationState>();
  const checkoutPaths = useCheckoutPaths();
  const { cart, executePurchaseMutation, purchaseMutationLoading } = useContext(CheckoutContext);
  const { checkoutState } = useContext(CheckoutContext);
  const session = useSelector((state: types.AppState) => state.session);
  const email = session?.email;
  const productSkuMap = useProductSkuMap();
  const allProducts = useSelector((state: types.AppState) => state.config.products);
  const supplementItems = useMemo((): ISupplementSubscriptionProduct[] => {
    const unsortedSupplements = allProducts.filter(
      (p) => p.category === types.ProductCategory.SUPPLEMENT,
    ) as ISupplementSubscriptionProduct[];
    // Sort items so they appear in product selector in a logical order.
    return unsortedSupplements.sort(
      (a, b) => sizeSortOrder(a.recommendedForDogSize) - sizeSortOrder(b.recommendedForDogSize),
    );
  }, [allProducts]);

  const smallestSizePurchased = getSmallestCollarOrdered(cart, productSkuMap);
  const matchingSupplement = smallestSizePurchased
    ? getMatchingSupplementForDogSize(supplementItems, smallestSizePurchased)
    : undefined;
  const defaultSelectedProduct = matchingSupplement ? matchingSupplement : supplementItems[0];
  const [selectedProduct, setSelectedProduct] = useState<ISupplementSubscriptionProduct>(defaultSelectedProduct);

  const { error, errorID, setError } = useError();

  const errorCallback = useCallback(
    (errorMessage: string) => {
      setError(errorMessage);
      events.confirmation.error(errorMessage);
    },
    [setError],
  );

  const goToThankYouPage = useCallback(
    (orderCart: types.Cart, invoiceNumber: string, cartPricing: types.CartPricing) => {
      history.replace(checkoutPaths.ThankYou, {
        orderId: invoiceNumber,
        orderedCart: orderCart,
        orderedCartPricing: cartPricing,
      });
    },
    [checkoutPaths.ThankYou, history],
  );

  // Complete purchase of the selected supplement subscription
  const purchaseSupplement = useCallback(
    async (emailAddress: string | undefined) => {
      cartActions.addSingleLineCartItem({ sku: selectedProduct.sku, quantity: 1 });
      const singleLineCartItem = createSupplementCartItem(selectedProduct);

      // Purchase the cart with only the supplement subscription line item
      try {
        const supplementPurchaseCart = {
          ...cart,
          couponCode: undefined, // Coupon codes do not apply to supplements, forces any applied via URL to be removed
          cartItems: { [singleLineCartItem.cartItemId]: singleLineCartItem },
        };
        const cartPricing = await priceCartForCheckout(supplementPurchaseCart, checkoutState);
        const result = await executePurchaseMutation(supplementPurchaseCart, checkoutState);

        const { chargeInvoice, giftCardInvoices } = result;
        const invoiceNumber = chargeInvoice?.number ?? giftCardInvoices?.[0]?.number;

        events.supplements.purchaseSucceeded('ecomm');
        goToThankYouPage(cart, invoiceNumber, cartPricing);
      } catch (err) {
        const customerMessage = getCustomerMessageFromApolloError(err);
        if (customerMessage) {
          errorCallback(customerMessage);
        } else {
          logInternalError(err);
          errorCallback(
            'Failed to process purchase. Please try again or contact support@tryfi.com if the problem persists.',
          );
        }
      }
    },
    [cart, checkoutState, errorCallback, selectedProduct, executePurchaseMutation, goToThankYouPage],
  );

  return (
    <>
      <div className={styles.productInfo}>
        <div className={styles.newLabel}>NEW</div>
        <h1 className={styles.supplementsText}>FI SUPPLEMENTS</h1>
        <p className={styles.pitch}>
          You can now easily add our new 8-in-1 supplements to your Fi Membership.
          <strong>
            <br />
            <br />
            SPECIAL OFFER - First Bag is FREE
          </strong>
        </p>
        <div className={classNames(styles.imageGalleryContainer)}>
          <ImageGallery
            images={[
              {
                url: `/product_images/supplements/Fi-Multivitamin-Pouch-front.png`,
                retinaWidth: 1200,
                retinaHeight: 1200,
              },
              {
                url: `/product_images/supplements/Fi-Multivitamin-Pouch-back.png`,
                retinaWidth: 1200,
                retinaHeight: 1200,
              },
              {
                url: `/product_images/supplements/Fi-Multivitamin-ingredients.png`,
                retinaWidth: 1200,
                retinaHeight: 1200,
              },
              {
                url: `/lifestyle_images/supplements/Fi-Multivitamin-lifestyle.png`,
                retinaWidth: 1200,
                retinaHeight: 1200,
              },
            ]}
            squareAspectRatio
            showMobileGallerySelector
            padGallerySelectorImages
          />
        </div>
        <div className={styles.valueProps}>
          <ValuePropsBox valueProps={BENEFITS_VALUE_PROPS} />
        </div>
        <ProductSelector
          onSelectionChange={setSelectedProduct}
          supplementProducts={supplementItems}
          selectedProduct={selectedProduct}
          handlePurchase={() => purchaseSupplement(email)}
          handlePurchaseLoading={purchaseMutationLoading}
          flow="ecomm"
        />
        <SupplementPurchaseButtons
          handlePurchase={async () => await purchaseSupplement(email)}
          handleNoThanks={() => goToThankYouPage(originalCart, originalOrderId, originalCartPricing)}
          purchaseMutationLoading={purchaseMutationLoading}
          selectedProduct={selectedProduct}
          error={error}
          errorId={errorID}
          flow="ecomm"
        />
        <TermsAndConditions />
        <FAQ />
      </div>
    </>
  );
}
