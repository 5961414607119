import React from 'react';
import { useLocation } from 'react-router-dom';
import styles from '../../styles/SubscriptionContainer.module.scss';
import Button from '../../../../components/Button';
import { usePurchaseSubscriptionContext } from '../../../PurchaseSubscription/context/PurchaseSubscriptionContext';
import { zendeskCancellationReason } from '../../../../types/gql-op-types';
import { inAppCancellation as events } from '../../../../lib/analytics/events';
import { SubscriptionAppBarSpacer } from '../../components/SubscriptionAppBar';

// Can't pass functions as state, so built a map that can be extended.
// Will need to revisit this if events need to be more custom.
const RequestReasonToEvent = {
  cancellation: events.cancellationTicketConfirmationDoneClicked,
  replacement: events.replacementTicketConfirmationDoneClicked,
};

export enum RequestReason {
  Cancellation = 'cancellation',
  Replacement = 'replacement',
}

interface RequestSentState {
  requestType: RequestReason;
  cancellationReason?: zendeskCancellationReason;
  replacementReason?: string;
}

export default function RequestSent() {
  const { device } = usePurchaseSubscriptionContext();
  const location = useLocation<RequestSentState>();
  const { requestType, cancellationReason, replacementReason } = location.state;
  events.pageLoad({ petId: device.pet!.id, cancellationReason, replacementReason }, 'Request Received');

  const petName = device.pet?.name ? device.pet.name : 'your dog';

  if (
    (requestType === RequestReason.Cancellation && !cancellationReason) ||
    (requestType === RequestReason.Replacement && !replacementReason)
  ) {
    throw new Error('Must provide reason for request');
  }

  return (
    <>
      <div className={styles.fullWidthWrapper}>
        <SubscriptionAppBarSpacer />
        <div className={styles.subscriptionContainer}>
          <h1>We’ll be in touch soon.</h1>

          {requestType === RequestReason.Cancellation ? (
            <>
              <p>
                Your request has been logged. We'll reach out via email within a few business hours to complete{' '}
                {petName}'s membership cancellation.{' '}
                <strong> You will need to confirm your cancellation with a Fi Customer Support representative. </strong>
              </p>

              <p>
                In the meantime, your membership will stay active, and you'll continue to have access to the Fi app.
              </p>
            </>
          ) : (
            <p>
              We have received your request for {requestType} and our support team will be reaching out with next steps.
            </p>
          )}
        </div>
        <div className={styles.buttonContainer}>
          <Button
            className={styles.button}
            onClick={() => {
              RequestReasonToEvent[requestType]({
                petId: device.pet?.id,
                replacementReason: replacementReason,
                cancellationReason: cancellationReason,
              });
              window.open('fi://closeappview');
            }}
          >
            Got It
          </Button>
          {/* Spacer ensures primary button is aligned with buttons on neighboring pages in in-app cancel flow */}
          <div className={styles.buttonSpacer}></div>
        </div>
      </div>
    </>
  );
}
