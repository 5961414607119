import { CarouselController } from '../../../../../components/Carousel';
import classNames from 'classnames';
import ImageGallery from '../../../../../components/ImageGallery/ImageGallery';
import React from 'react';
import styles from './ImageGallery.module.scss';
import * as types from '../../../../../types';

const DESKTOP_IMAGE_GALLERY_SIZE = 7;

const MOBILE_COLLAR_IMAGE: types.IImage = {
  url: '/lifestyle_images/smart-collar-v3/mobile-01.jpg',
  retinaWidth: 652,
  retinaHeight: 488,
};

const LIFESTYLE_IMAGES: types.IImage[] = [
  {
    url: '/lifestyle_images/smart-collar-v3/01.jpg',
    retinaWidth: 652,
    retinaHeight: 488,
  },
  {
    url: '/lifestyle_images/smart-collar-v3/02.jpg',
    retinaWidth: 652,
    retinaHeight: 488,
  },
  {
    url: '/lifestyle_images/smart-collar-v3/03.jpg',
    retinaWidth: 652,
    retinaHeight: 488,
  },
  {
    url: '/lifestyle_images/smart-collar-v3/04.jpg',
    retinaWidth: 652,
    retinaHeight: 488,
  },
  {
    url: '/lifestyle_images/smart-collar-v3/05.jpg',
    retinaWidth: 652,
    retinaHeight: 488,
  },
  {
    url: '/lifestyle_images/smart-collar-v3/06.jpg',
    retinaWidth: 652,
    retinaHeight: 488,
  },
];

const NEW_ECOM_LIFESTYLE_IMAGES: types.IImage[] = [
  {
    url: '/product_images/smart-collar-v3/yellow-lifestyle.jpg',
    descriptors: ['1500w', '3000w', '4000w'],
  },
  {
    url: '/product_images/smart-collar-v3/pink-lifestyle.jpg',
    descriptors: ['1500w', '3000w', '4000w'],
  },
  {
    url: '/product_images/smart-collar-v3/gray-lifestyle.jpg',
    descriptors: ['1500w', '3000w', '4000w'],
  },
  {
    url: '/product_images/smart-collar-v3/blue-lifestyle.jpg',
    descriptors: ['1500w', '3000w', '4000w'],
  },
];

interface DesktopImageGalleryProps {
  carouselControllerRef: React.MutableRefObject<CarouselController | undefined>;
  selectedVariant: types.IVariant;
  pageDots: boolean;
  newEcomFlow: boolean;
  onChange?: (index: number) => void;
  children?: React.ReactNode;
}

export function imageUrlForVariant(variant: types.IVariant) {
  return `/product_images/smart-collar-v3/${variant.options.color.replace(/[\s/]/g, '_')}.jpg`;
}

export function cirlceImageUrlForVariant(variant: types.IVariant) {
  return `/product_images/smart-collar-v3/${variant.options.color.replace(/[\s/]/g, '_')}-circle.jpg`;
}

export function lifestyleImageUrlForVariant(variant: types.IVariant) {
  return `/product_images/smart-collar-v3/${variant.options.color.replace(/[\s/]/g, '_')}-lifestyle.jpg`;
}

export function flatImageUrlForVariant(variant: types.IVariant) {
  return `/product_images/smart-collar-v3/${variant.options.color.replace(/[\s/]/g, '_')}-flat.jpg`;
}

export function secondaryImageUrlForVariant(variant: types.IVariant) {
  return `/product_images/smart-collar-v3/${variant.options.color.replace(/[\s/]/g, '_')}2.jpg`;
}

export function DesktopImageGallery({
  carouselControllerRef,
  selectedVariant,
  pageDots,
  newEcomFlow,
  onChange,
  children,
}: DesktopImageGalleryProps) {
  const productImages: types.IImage[] = newEcomFlow
    ? [
        {
          url: cirlceImageUrlForVariant(selectedVariant),
          descriptors: ['1500w', '3000w', '4000w'],
        },
        {
          url: flatImageUrlForVariant(selectedVariant),
          descriptors: ['1500w', '3000w', '4000w'],
        },
      ]
    : [
        {
          url: imageUrlForVariant(selectedVariant),
          retinaWidth: 1500,
          retinaHeight: 957,
        },
      ];
  if (selectedVariant.options.color === types.ColorOption.Strava) {
    productImages.push({
      url: secondaryImageUrlForVariant(selectedVariant),
      retinaWidth: 1200,
      retinaHeight: 1200,
    });
  }

  const lifestyleImages = newEcomFlow ? NEW_ECOM_LIFESTYLE_IMAGES : LIFESTYLE_IMAGES;
  // Maintain a maximum of 7 images in carousel
  const images = [...productImages, ...lifestyleImages].slice(0, DESKTOP_IMAGE_GALLERY_SIZE);

  return (
    <div className={classNames(styles.imageGalleryContainer, styles.desktopImageGallery)}>
      {children}
      <ImageGallery
        parentCarouselControllerRef={carouselControllerRef}
        images={images}
        pageDots={pageDots}
        disableAnimation={newEcomFlow}
        friction={newEcomFlow ? 0.4 : 0.28}
        onChange={onChange}
      />
    </div>
  );
}

export function MobileImageGallery() {
  return (
    <div className={classNames(styles.imageGalleryContainer, styles.mobileImageGallery)}>
      <ImageGallery images={[MOBILE_COLLAR_IMAGE, ...LIFESTYLE_IMAGES]} pageDots />
    </div>
  );
}
