import React from 'react';
import { Image } from '../../../../../components/Image';
import * as types from '../../../../../types';
import styles from './SubItems.module.scss';

interface SubItemDetails {
  name: string;
  imageUrl: string;
}

function subItemDetails(variant: types.IVariant): SubItemDetails[] {
  return [
    {
      name: 'Fi Series 2',
      imageUrl: '/series2_images/f2-m.jpg',
    },
    {
      name: 'Fi Series 2 Base',
      imageUrl: '/series2_images/f2-bua.jpg',
    },
    {
      name: 'Fi Band',
      imageUrl: `/product_images/additional-band/${variant.options.color}.jpg`,
    },
  ];
}

interface SubItemsProps {
  variant: types.IVariant;
}

export default function SubItems({ variant }: SubItemsProps) {
  return (
    <div className={styles.container}>
      <div className={styles.title}>What's included</div>
      <div className={styles.subItems}>
        {subItemDetails(variant).map((subItem) => (
          <div className={styles.subItem}>
            <div className={styles.subItemImageContainer}>
              <Image image={{ url: subItem.imageUrl }} alt={subItem.name} />
            </div>
            <div className={styles.subItemName}>{subItem.name}</div>
          </div>
        ))}
      </div>
    </div>
  );
}
