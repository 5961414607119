import classNames from 'classnames';
import { DateTime } from 'luxon';
import React from 'react';
import { useHistory } from 'react-router-dom';

import AppPaths from '../../../../AppPaths';
import { BillingSubscription, SubscriptionOption } from '../SplashPage';
import Button from '../../../../components/Button/Button';
import { centsToDollarsNumber, expectUnreachable } from '../../../../lib/util';
import pillStyles from '../styles/SupplementPill.module.scss';
import purchasablePillStyles from '../../../Checkout/components/Supplements/ProductSelector/ProductSelector.module.scss';
import { supplementsManagement as events } from '../../../../lib/analytics/events';
import { WeightRange } from '../../../../types/gql-op-types';
import { ReactComponent as DownArrow } from '../../../../assets/images/icons/arrow_down.svg';

export function getRecommendedText(recommendedForDogWeightRangePounds: WeightRange) {
  let text = 'Recommended for dogs ';
  const { min, max } = recommendedForDogWeightRangePounds ?? { min: null, max: null };
  if (min && max) {
    text += `${min}-${max}lbs`;
  } else if (!min) {
    text += `up to ${max}lbs`;
  } else if (!max) {
    text += `over ${min}lbs`;
  }
  return text;
}

interface InformationSupplementPillProps {
  paused: boolean;
  showManageButton: boolean;
  subscription: BillingSubscription;
  pendingPlan?: SubscriptionOption;
}

function InformationSupplementPill({
  paused,
  showManageButton,
  subscription,
  pendingPlan,
}: InformationSupplementPillProps) {
  const history = useHistory();
  const { supplementShipmentDates } = subscription;
  if (!supplementShipmentDates) {
    throw new Error('Missing shipment dates. Please contact support@tryfi.com.');
  }
  const { recommendedForDogWeightRangePounds, weeksBetweenShipments, inAppManagementName, priceInCents } =
    subscription.subscriptionOption;

  const handleManageClick = () => {
    events.manageSubscription({ subscriptionId: subscription.id });
    history.push({
      pathname: AppPaths.Supplements.Manage(subscription.id),
      state: { subscription, pendingSubscription: pendingPlan },
      search: window.location.search,
    });
  };

  const shippingCadenceText = () => {
    // If there are pending changes (meaning the user has updated this subscription previously), we need to get more
    // information about the future plan so we can display it to the user. Behind the scenes, in Recurly, their plan will only be updated
    // on their next bill date (to avoid shipping a new bag of supplements prematurely), but we make an immediate change to the next bill
    // date, which appears in the UI, so it should appear as though the plan change has also happened immediately. To do this,
    // we'll get the plan information of the new plan and display it here.
    let shippingCadence = weeksBetweenShipments;
    if (pendingPlan) {
      shippingCadence = pendingPlan.weeksBetweenShipments;
    }

    return `Ships every ${shippingCadence} weeks`;
  };

  const nextShipmentText = () => {
    return `Next shipment on ${DateTime.fromISO(supplementShipmentDates.nextShipmentDate).toFormat('LL/dd/yy')}.`;
  };

  const shipmentInfoText = () => {
    return paused ? 'Upcoming shipment paused.' : `Billed as $${centsToDollarsNumber(priceInCents)}/shipment.`;
  };

  return (
    <>
      <div
        className={classNames(pillStyles.supplementPillContainer, pillStyles.informational, {
          [pillStyles.paused]: paused,
        })}
      >
        <div className={pillStyles.row}>
          <h2>{inAppManagementName}</h2>
          {showManageButton && (
            <Button secondary onClick={handleManageClick}>
              Manage
            </Button>
          )}
        </div>

        <div className={pillStyles.shipping}>{shippingCadenceText()}</div>
        {recommendedForDogWeightRangePounds && (
          <div className={pillStyles.recommended}>{getRecommendedText(recommendedForDogWeightRangePounds)}</div>
        )}

        <div className={pillStyles.divider}></div>

        <div className={classNames(pillStyles.billedAs, { [pillStyles.paused]: paused })}>
          {shipmentInfoText()} <span className={pillStyles.nextShipment}>{nextShipmentText()}</span>
        </div>
      </div>
    </>
  );
}

interface SupplementPillUpdateOptionProps {
  subscription: BillingSubscription;
  subscriptionOption: SubscriptionOption;
  handleClick: () => void;
  selected: boolean;
}
export function SupplementPillUpdateOption({
  subscription,
  subscriptionOption,
  handleClick,
  selected,
}: SupplementPillUpdateOptionProps) {
  const { sku, recommendedForDogWeightRangePounds, weeksBetweenShipments } = subscriptionOption;
  if (!sku) {
    throw new Error('No sku found');
  }

  const { supplementShipmentDates } = subscription;
  if (!supplementShipmentDates) {
    throw new Error('Missing shipment dates. Please contact support@tryfi.com.');
  }
  const nextShipDate = supplementShipmentDates.previewUpdate.find((d) => d.sku === sku)?.nextShipDate;
  let nextShipDateText = '';
  if (nextShipDate) {
    nextShipDateText = `Your next shipment will be on ${DateTime.fromISO(nextShipDate).toFormat('LL/dd/yy')}`;
  }

  return (
    <>
      <div
        className={classNames(pillStyles.supplementPillContainer, pillStyles.selectable, {
          [pillStyles.selected]: selected,
        })}
        onClick={handleClick}
      >
        <div className={pillStyles.shipping}>Ships every {weeksBetweenShipments} weeks</div>
        {nextShipDateText && <div className={pillStyles.nextShipDate}>{nextShipDateText}</div>}
        {recommendedForDogWeightRangePounds && (
          <div className={pillStyles.recommended}>{getRecommendedText(recommendedForDogWeightRangePounds)}</div>
        )}
      </div>
    </>
  );
}

interface SupplementPillPurchaseOptionProps {
  subscriptionOption: SubscriptionOption;
  handleClick?: () => void;
  selected: boolean;
  showDownArrow?: boolean;
}
export function SupplementPillPurchaseOption({
  subscriptionOption,
  handleClick,
  selected,
  showDownArrow = false,
}: SupplementPillPurchaseOptionProps) {
  return (
    <div
      className={classNames(pillStyles.purchasableOption, purchasablePillStyles.supplementProductSelector, {
        [pillStyles.selected]: selected,
      })}
      onClick={handleClick}
    >
      <div className={purchasablePillStyles.top}>
        <div className={purchasablePillStyles.left}>
          <div className={purchasablePillStyles.size}>{subscriptionOption.name}</div>
          <div className={purchasablePillStyles.shippingCadence}>
            Ships every {subscriptionOption.weeksBetweenShipments} {subscriptionOption.displayedRateUnit}s
          </div>
          {subscriptionOption.recommendedForDogWeightRangePounds && (
            <div className={purchasablePillStyles.recommended}>
              {' '}
              {getRecommendedText(subscriptionOption.recommendedForDogWeightRangePounds)}
            </div>
          )}
        </div>
        <div className={purchasablePillStyles.right}>
          <div className={purchasablePillStyles.price}>
            <strong>
              {subscriptionOption.displayedRate}/{subscriptionOption.displayedRateUnit}
            </strong>
          </div>
          <DownArrow className={classNames({ [pillStyles.hideDown]: !showDownArrow })} />
        </div>
      </div>
      <div className={purchasablePillStyles.bottom}>
        <div className={purchasablePillStyles.divider} />
        <span className={purchasablePillStyles.billingCadence}>
          Billed as ${centsToDollarsNumber(subscriptionOption.priceInCents)}/shipment.
        </span>
      </div>
    </div>
  );
}

type SupplementPillVersion =
  | 'informational' // Display information about a user's existing plan.
  | 'selectable' // Display a selectable subscription pill for user to select when changing plan.
  | 'purchasable'; // Display a selectable subscription pill for user to purchase. Displays more information than `selectable` type.

interface CommonSupplementPillProps {
  showManageButton: boolean;
  version: SupplementPillVersion;
  paused?: boolean;
  handleClick?: () => void;
  selected?: boolean;
  pendingPlan?: SubscriptionOption;
}

interface InfoPillProps {
  subscription: BillingSubscription;
  subscriptionDetails: null;
}
interface PurchasePillProps {
  subscription: null;
  subscriptionDetails: SubscriptionOption;
}
interface UpdatePillProps {
  subscription: BillingSubscription;
  subscriptionDetails: SubscriptionOption;
}

type ConditionalSupplementPillProps = InfoPillProps | PurchasePillProps | UpdatePillProps;

export default function SupplementPill({
  showManageButton,
  subscription,
  subscriptionDetails,
  version,
  handleClick,
  selected = false,
  paused = false,
  pendingPlan,
}: CommonSupplementPillProps & ConditionalSupplementPillProps) {
  const subscriptionOption = subscriptionDetails ? subscriptionDetails : subscription.subscriptionOption;
  switch (version) {
    case 'informational':
      return (
        subscription && (
          <InformationSupplementPill
            showManageButton={showManageButton}
            paused={paused}
            subscription={subscription}
            pendingPlan={pendingPlan}
          />
        )
      );
    case 'selectable':
      return (
        subscription && (
          <SupplementPillUpdateOption
            subscription={subscription}
            subscriptionOption={subscriptionOption}
            selected={selected}
            handleClick={() => handleClick && handleClick()}
          />
        )
      );
    case 'purchasable':
      return (
        <SupplementPillPurchaseOption
          subscriptionOption={subscriptionOption}
          selected={selected}
          handleClick={() => handleClick && handleClick()}
        />
      );

    default:
      expectUnreachable(version);
      throw new Error(`Unknown supplement pill version: ${version}`);
  }
}
