import { gqlTypes } from '../types';
import * as types from '../types';
import { expectUnreachable } from './util';

export function gqlBillingCadenceToBillingCadence(gqlBillingCadence: gqlTypes.BillingCadence): types.BillingCadence {
  switch (gqlBillingCadence) {
    case gqlTypes.BillingCadence.MONTH:
      return types.BillingCadence.Month;
    case gqlTypes.BillingCadence.YEAR:
      return types.BillingCadence.Year;
    case gqlTypes.BillingCadence.WEEK:
      return types.BillingCadence.Week;
    default:
      expectUnreachable(gqlBillingCadence);
      throw new Error(`Unknown billing cadence: ${gqlBillingCadence}`);
  }
}

export function commonDetailsForSubscription(subscription: types.ISubscriptionProduct): { subscriptionName: string } {
  if (subscription.sku === 'sub-monthly-1m-001') {
    return {
      subscriptionName: 'Monthly',
    };
  } else if (subscription.sku === 'sub-monthly-6m-001') {
    return {
      subscriptionName: '6 Months',
    };
  } else if (subscription.sku === 'sub-monthly-12m-001') {
    return {
      subscriptionName: '1 Year',
    };
  } else if (subscription.sku === 'sub-monthly-24m-001') {
    return {
      subscriptionName: '2 Year',
    };
  }
  return {
    subscriptionName: subscription.name,
  };
}
