import { DateTime } from 'luxon';
import React from 'react';
import { SubscriptionOption } from '../SubscriptionOptionDetails';
import SubscriptionOptionButton, {
  SubscriptionOverrideContent,
} from '../SubscriptionOptionDetails/SubscriptionOptionDetails';
import styles from './SubscriptionPill.module.scss';
import classNames from 'classnames';

export enum SubscriptionPillStyle {
  Default = 'default',
  Gray = 'gray',
  Downgrade = 'downgrade',
}

interface SubscriptionPillProps {
  subscriptionProduct: SubscriptionOption;
  renewalDate?: DateTime;
  expirationDate?: DateTime;
  disableSavingsDisplay?: boolean;
  style?: SubscriptionPillStyle;
  overrideTitle?: string;
  renewScreens?: boolean; // If these pills appear on renewal screens, styles will be slightly different.
}
export default function SubscriptionPill({
  subscriptionProduct,
  renewalDate,
  expirationDate,
  disableSavingsDisplay,
  style,
  overrideTitle,
  renewScreens,
}: SubscriptionPillProps) {
  let pillStyle = styles.pillDefault;
  if (style === SubscriptionPillStyle.Gray) {
    pillStyle = styles.pillGray;
  }
  if (style === SubscriptionPillStyle.Downgrade) {
    pillStyle = styles.pillDowngrade;
  }
  return (
    <div className={classNames(styles.pill, pillStyle)}>
      <SubscriptionOptionButton
        disableSavingsDisplay={disableSavingsDisplay}
        renewalDate={renewalDate}
        expirationDate={expirationDate}
        overrideTitle={overrideTitle}
        subscriptionProduct={subscriptionProduct}
        renewScreens={renewScreens}
      />
    </div>
  );
}

interface SubscriptionOverridePillProps {
  style?: SubscriptionPillStyle;
}
export function SubscriptionOverridePill({ style }: SubscriptionOverridePillProps) {
  let pillStyle = styles.pillDefault;
  if (style === SubscriptionPillStyle.Gray) {
    pillStyle = styles.pillGray;
  }
  if (style === SubscriptionPillStyle.Downgrade) {
    pillStyle = styles.pillDowngrade;
  }
  return (
    <div className={classNames(styles.pill, pillStyle)}>
      <SubscriptionOverrideContent />
    </div>
  );
}
