import React from 'react';
import * as types from '../../types';
import PromoCode from '../PromoCode';
import CartActions from './CartActions';
import styles from './CartSummary.module.scss';
import CartValueProps from './CartValueProps';

interface CartSummaryProps {
  allowPromoCodes?: boolean;
  onCheckOut: (checkoutCart: types.Cart, cartPricing: types.CartPricing) => void;
}

export default function CartSummary({ allowPromoCodes = true, onCheckOut }: CartSummaryProps) {
  return (
    <>
      <div className={styles.cartSummary}>
        {allowPromoCodes && (
          <div className={styles.cartPromoCode}>
            <PromoCode />
          </div>
        )}
        <CartValueProps />
        <CartActions onCheckOut={onCheckOut} />
      </div>
    </>
  );
}
