import Button from '../../../../components/Button';
import Chooser from '../../../../components/Chooser';
import { gqlBillingCadenceToBillingCadence } from '../../../../lib/subscription';
import React, { useMemo } from 'react';
import styles from '../../styles/SubscriptionContainer.module.scss';
import { SubscriptionOption } from '../../../../components/SubscriptionOptionDetails';
import { usePurchaseSubscriptionContext } from '../../../PurchaseSubscription/context/PurchaseSubscriptionContext';
import { useSubscriptionChooserGroups } from '../../../../components/SubscriptionOptions/SubscriptionOptions';
import AppPaths from '../../../../AppPaths';
import { useHistory } from 'react-router-dom';
import CurrentSubscription from '../components/CurrentSubscription';
import SubscriptionAppBar from '../../components/SubscriptionAppBar';
import { PurchaseSubscriptionCheckoutState } from '../../../PurchaseSubscription/PurchaseSubscriptionCheckout/PurchaseSubscriptionCheckout';
import { inAppCancellation as events } from '../../../../lib/analytics/events';
import { cartActions } from '../../../../reducers/subscriptionCart';
import { useDispatch, useSelector } from 'react-redux';
import { useSubscriptionCartMode } from '../../../../lib/cartModes';

function CancelButton({ moduleId, petId }: { moduleId: string; petId?: string }) {
  const history = useHistory();
  return (
    <Button
      className={styles.button}
      tertiary={true}
      onClick={() => {
        events.requestToCancelMembershipClicked({ petId });
        history.push({ pathname: AppPaths.Subscription.Cancel(moduleId), search: window.location.search });
      }}
    >
      Request to Cancel Membership
    </Button>
  );
}

export default function ChangeSubscription() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { device } = usePurchaseSubscriptionContext();
  const { cartSelector } = useSubscriptionCartMode();
  const cart = useSelector(cartSelector);
  events.pageLoad({ petId: device.pet!.id }, 'Manage Membership');

  const applicableSubscriptionProducts: SubscriptionOption[] = useMemo(() => {
    if (device.hasSubscriptionOverride) {
      return []; // No upgrade options for an override subscription.
    }

    const result: SubscriptionOption[] = [];
    const currentRenewalMonths = device.subscription?.subscriptionOption.renewalMonths ?? 0;
    for (const option of device.purchasableSubscriptionOptions) {
      // Don't show options to "upgrade" to a lower tier subscription option.
      if (option.renewalMonths <= currentRenewalMonths) {
        continue;
      }

      result.push({
        ...option,
        billingCadence: gqlBillingCadenceToBillingCadence(option.billingCadence),
      });
    }
    return result;
  }, [device]);

  const selectedSubscriptionSku = useMemo(() => {
    const cartItem = Object.values(cart.cartItems)[0];
    if (!cartItem) {
      return undefined;
    }

    return cartItem.lineItem.sku;
  }, [cart.cartItems]);

  const selectedSubscriptionOption = useMemo(() => {
    return applicableSubscriptionProducts.find((option) => option.sku === selectedSubscriptionSku);
  }, [applicableSubscriptionProducts, selectedSubscriptionSku]);

  const chooserGroups = useSubscriptionChooserGroups({
    disablePrioritizedShippingUpsell: true,
    subscriptionProducts: applicableSubscriptionProducts,
  });

  const checkoutDisabled = selectedSubscriptionOption && selectedSubscriptionSku ? false : true;

  const showCancelButton = !device.hasSubscriptionOverride;
  const showProducts = applicableSubscriptionProducts.length > 0;

  const currentSubscriptionSku = device.subscription?.subscriptionOption.sku;

  return (
    <>
      <div className={styles.fullWidthWrapper}>
        <SubscriptionAppBar
          title={'Manage Membership'}
          backButtonAnalyticsEvent={() => events.changeMembershipBack({ petId: device.pet?.id })}
          closeButtonAnalyticsEvent={() => events.changeMembershipClose({ petId: device.pet?.id })}
        />
        <div className={styles.subscriptionContainer}>
          <h1>Your current plan</h1>
          {/* This page is not accessible from the renew flow  */}
          <CurrentSubscription canRenew={false} />

          <h1>Prepay and save</h1>
          {showProducts ? (
            <Chooser
              onSelect={(selectedSku) => {
                if (!selectedSku) {
                  return;
                }

                dispatch(
                  cartActions.setCartItem({
                    moduleId: device.moduleId,
                    sku: selectedSku,
                  }),
                );
              }}
              selectedOption={selectedSubscriptionSku}
              groups={chooserGroups}
            />
          ) : (
            <div className={styles.content}>
              <p>You currently have the best value plan for your {device.currentCollarModel} collar.</p>
            </div>
          )}
        </div>
        <div className={styles.buttonContainer}>
          {showProducts && (
            <Button
              className={styles.button}
              disabled={checkoutDisabled}
              onClick={() => {
                events.changeMembershipSaveClicked({
                  petId: device.pet?.id,
                  oldSku: currentSubscriptionSku,
                  newSku: selectedSubscriptionSku,
                });
                history.push({
                  pathname: AppPaths.Subscription.Checkout(device.moduleId),
                  state: {
                    petId: device.pet?.id,
                    oldSku: currentSubscriptionSku,
                    subscriptionOption: selectedSubscriptionOption,
                  } as PurchaseSubscriptionCheckoutState,
                  search: window.location.search,
                });
              }}
            >
              Continue
            </Button>
          )}
          {showCancelButton && <CancelButton moduleId={device.moduleId} petId={device.pet?.id} />}
        </div>
      </div>
    </>
  );
}
