import React, { useState } from 'react';
import styles from '../../styles/SubscriptionContainer.module.scss';
import { usePurchaseSubscriptionContext } from '../../../PurchaseSubscription/context/PurchaseSubscriptionContext';
import SubscriptionAppBar from '../../components/SubscriptionAppBar';
import Button from '../../../../components/Button';
import { TextArea } from '../../../../components/TextArea/TextArea';
import { mkError } from '../../../../lib/util';
import { inAppCancellation as events } from '../../../../lib/analytics/events';
import { useZendeskCancellationTicket, useZendeskReplacementTicket } from '../hooks/useZendesk';
import CancelConfirmationModal from '../../components/CancelConfirmationModal/CancelConfirmationModal';
import { useLocation } from 'react-router-dom';
import { gqlTypes } from '../../../../types';

interface ReplaceCollarState {
  selectedCancelReason: gqlTypes.zendeskCancellationReason;
}

export default function ReplaceCollar() {
  const { device } = usePurchaseSubscriptionContext();
  const location = useLocation<ReplaceCollarState>();
  const { selectedCancelReason } = location.state;
  const [replaceReasonText, setReplaceReasonText] = useState('');

  const { mutation: replaceMutation, loading: replaceLoading } = useZendeskReplacementTicket({
    analyticsEvent: events.replacementTicketSubmitted,
    replacementReason: replaceReasonText,
    device,
  });

  const { mutation: cancelMutation, loading: cancelLoading } = useZendeskCancellationTicket({
    analyticsEvent: events.cancelFromFreeMonthPage,
    selectedCancelReason,
    device,
  });

  if (replaceLoading) {
    return replaceLoading;
  }
  if (cancelLoading) {
    return cancelLoading;
  }

  if (!device.subscription?.id) {
    return mkError('Subscription not found');
  }

  const handleReplacementRequest = () => {
    if (!replaceReasonText || !device.subscription?.id) {
      throw new Error('Something went wrong.');
    }
    replaceMutation({
      variables: {
        input: {
          recurlySubscriptionId: device.subscription.id,
          replacementReason: replaceReasonText,
        },
      },
    });
  };

  const handleCancel = () => {
    cancelMutation({
      variables: {
        input: {
          recurlySubscriptionId: device.subscription!.id,
          cancellationReason: selectedCancelReason,
          additionalText: undefined,
        },
      },
    });
  };

  return (
    <>
      <div className={styles.fullWidthWrapper}>
        <SubscriptionAppBar
          backButtonAnalyticsEvent={() => events.replaceCollarBack({ petId: device.pet?.id })}
          closeButtonAnalyticsEvent={() => events.replaceCollarClose({ petId: device.pet?.id })}
        />
        <div className={styles.subscriptionContainer}>
          <div className={styles.sectionHeaderContainer}>
            <h2 className={styles.sectionSubheader}>Replacement for Your Collar</h2>
          </div>
          <div className={styles.content}>
            <p>
              Exciting news! You have the opportunity to get a brand new S3 collar replacement! Tell us what happened to
              your current collar.
            </p>
            <div className={styles.textArea}>
              <TextArea
                onChange={(value) => {
                  value && setReplaceReasonText(value);
                }}
                rows={12}
                placeholderText="Type your response here..."
              />
            </div>
          </div>
        </div>
        <div className={styles.buttonContainer}>
          <Button
            onClick={() => handleReplacementRequest()}
            className={styles.button}
            disabled={replaceReasonText.length === 0}
          >
            Submit
          </Button>
          <CancelConfirmationModal
            onContinue={() => handleCancel()}
            pageName={'Replace Collar'}
            petId={device.pet!.id}
          />
        </div>
      </div>
    </>
  );
}
