import React from 'react';
import styles from './SizingGuide.module.scss';

interface ISizingBlockProps {
  dogList: string;
  sizeName: string;
  neckSize: string;
  bandWidth: string;
  onClick?: VoidFunction;
}

export default function SizingBlock({ dogList, sizeName, neckSize, bandWidth, onClick }: ISizingBlockProps) {
  return (
    <div className={styles.sizingBlock} onClick={onClick}>
      <h3>{sizeName}</h3>
      <div className={styles.sizeDescription}>
        {neckSize} &bull; {bandWidth} wide collar
      </div>
      <div className={styles.dogList}>{dogList}</div>
    </div>
  );
}
