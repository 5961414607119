import React from 'react';
import styles from './ProductTitle.module.scss';

export interface LowestSubscriptionBundlePrice {
  cheapestSubscriptionRate: string;
  cheapestSubscriptionRateUnit: string;
}

interface ProductTitleProps {
  productName: string;
  price: LowestSubscriptionBundlePrice;
}

export default function ProductTitle({ productName, price }: ProductTitleProps) {
  return (
    <div className={styles.container}>
      <h2 className={styles.title}>{productName}</h2>
      <div className={styles.price}>
        <div className={styles.pricePrimary}>Join Fi</div>
        <div className={styles.priceSecondary}>
          Starting at {price.cheapestSubscriptionRate}/{price.cheapestSubscriptionRateUnit}
        </div>
      </div>
    </div>
  );
}
