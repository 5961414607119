import React from 'react';

import Question from '../../../../ProductDetails/Series3CollarDetails/components/FAQ/Question';
import styles from '../../../../ProductDetails/Series3CollarDetails/components/FAQ/FAQ.module.scss';
import productInfoStyles from '../ProductInfo/ProductInfo.module.scss';
import classNames from 'classnames';

export default function FAQ() {
  return (
    <div className={classNames(styles.container, productInfoStyles.faqContainer)}>
      <h2 className={styles.heading}>FAQ</h2>
      <div>
        <div id="ingredients">
          <Question title="What are the ingredients? How much of each?">
            <strong>See the full list below,</strong> for a one-scoop (2g) serving:
            <br />
            <br />
            <table>
              <thead>
                <tr>
                  <th>Active Ingredient</th>
                  <th>Amount</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Glucosamine HCI (Shellfish)</td>
                  <td>300mg</td>
                </tr>
                <tr>
                  <td>Methylsulfonylmethane (MSM)</td>
                  <td>250mg</td>
                </tr>
                <tr>
                  <td>Vitamin C (Ascorbic Acid)</td>
                  <td>90mg</td>
                </tr>
                <tr>
                  <td>Hydrolyzed Bovine Collagen</td>
                  <td>75mg</td>
                </tr>
                <tr>
                  <td>Chondroitin Sulfate Sodium (Porcine)</td>
                  <td>50mg</td>
                </tr>
                <tr>
                  <td>Turmeric Root Powder</td>
                  <td>50mg</td>
                </tr>
                <tr>
                  <td>Niacin</td>
                  <td>16mg NE</td>
                </tr>
                <tr>
                  <td>Vitamin E (as D-Alpha Tocopheryl Acetate)</td>
                  <td>15mg</td>
                </tr>
                <tr>
                  <td>Pantothenic Acid (as Calcium-D-Pantothenate)</td>
                  <td>5mg</td>
                </tr>
                <tr>
                  <td>Manganese (as Manganese Sulfate)</td>
                  <td>2.3mg</td>
                </tr>
                <tr>
                  <td>Vitamin B6 (as Pyridoxine HCI)</td>
                  <td>1.7mg</td>
                </tr>
                <tr>
                  <td>Riboflavin</td>
                  <td>1.3mg</td>
                </tr>
                <tr>
                  <td>Thiamin (as Thiamin HCI)</td>
                  <td>1.2mg</td>
                </tr>
                <tr>
                  <td>Zinc (as Zinc Amino Acid Chelate)</td>
                  <td>1mg</td>
                </tr>
                <tr>
                  <td>Vitamin A (as Beta-Carotene)</td>
                  <td>900mcg RAE</td>
                </tr>
                <tr>
                  <td>Folic Acid</td>
                  <td>400mcg</td>
                </tr>
                <tr>
                  <td>Biotin</td>
                  <td>30mcg</td>
                </tr>
                <tr>
                  <td>Vitamin D (as Cholecalciferol)</td>
                  <td>20mcg</td>
                </tr>
                <tr>
                  <td>Vitamin B12 (as Cyanocobalamin)</td>
                  <td>2mcg</td>
                </tr>
              </tbody>
            </table>
            <br />
            <table>
              <thead>
                <tr>
                  <th>Inactive Ingredients</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Brewer's Yeast Powder</td>
                </tr>
                <tr>
                  <td>Beef Liver Powder</td>
                </tr>
                <tr>
                  <td>Silicon Dioxide</td>
                </tr>
              </tbody>
            </table>
          </Question>
        </div>
        <Question title="Is this included in my collar subscription? Or is it a separate bill?">
          <strong>Your Fi Supplement Subscription exists independent of your monthly Fi Collar membership.</strong>
          <br />
          <br />
          You'll get a separate bill and you can make changes to this subscription outside of your ongoing commitment to
          your dog's Fi collar, the app, and everything included.
        </Question>
        <Question title="Does this ship with my collar?">
          <strong>Your Fi Supplement Subscription ships separately.</strong>
          <br />
          <br />
          You can anticipate receiving a second, different communication from us with your tracking for the supplement.
        </Question>
        <Question title="What is the delivery schedule?">
          You'll receive your introductory supplement package soon. After this, you will receive your next bag every 4,
          6, or 12 weeks, depending on the your dog's size (and therefore your consumption).
        </Question>
        <Question title="What is my level of commitment?">
          <strong>This is an ongoing subscription, and you can cancel any time.</strong>
          <br />
          <br />
          Reach out to customer service to modify your subscription.
        </Question>
        <Question title="Can I give my dog this alongside other supplements and vitamins?">
          <strong>We recommend against this.</strong>
          <br />
          <br />
          Fi Multivitamin 8-in-1 is intended to be an all-in-one daily option, and so we do not recommend combining it
          with other supplements. Of course, if your vet recommends a different course of action, please listen to them.
          You can share the ingredient list above with your vet.
        </Question>
        <Question title="My dog has specific allergies or food sensitivities. Can they take this supplement?">
          <strong>
            It depends on what they're allergic or sensitive to. We recommend speaking with your vet before adding any
            supplement to your dog's diet.
          </strong>
          <br />
          <br />
          The most common food allergens for dogs tend to be with protein and complex carbs. Our Multivitamin powder
          contains two animal-derived ingredients:
          <ul>
            <li>Hydrolyzed Bovine Collagen (from beef)</li>
            <li>Chondroitin Sulfate Sodium (from pork)</li>
          </ul>
          This product also contains Turmeric Root Powder, which is typically well-tolerated, though some dogs have
          sensitivity. See the full ingredient list above.
        </Question>
      </div>
    </div>
  );
}
