import { CouponDiscount } from './index';

/**
 * -- Checkout types --
 * store: main e-commerce store
 * upgrade: upgrade to the latest collar model (in-app webview)
 * subscription: purchase a subscription for a collar that currently does not have a subscription (in-app webview)
 * membership_upgrade: change the plan on an existing subscription already attached to a collar (in-app webview)
 */
export enum CheckoutType {
  Store = 'store',
  Upgrade = 'upgrade',
  Subscription = 'subscription',
  MembershipUpgrade = 'membership_upgrade',
}

// Cart pricing
export interface SerializedCartValidationError {
  code: string;
  message: string;
}

export interface CartPricingValidationErrors {
  couponCode?: SerializedCartValidationError | null;
  referralCode?: SerializedCartValidationError | null;
  redeemedGiftCardCode?: SerializedCartValidationError | null;
}

export type CartItemCreditReason =
  | 'upgrade'
  | 'promo'
  | 'household_discount_five_percent'
  | 'household_discount_ten_percent';

export interface CartItemCredit {
  creditReason: CartItemCreditReason;
  creditAmountInCents: number;
}

export interface CartPricing {
  couponDiscount?: CouponDiscount | null;
  validationErrors?: CartPricingValidationErrors;
  activationFeesInCents?: number;
  activationFeeInCents?: number;
  subtotalInCents: number;
  appliedDiscountInCents: number;
  upgradeCreditAmountInCents?: number;
  cartItemCredits?: {
    [cartItemId: string]: CartItemCredit[];
  };
  totalInCents: number;
  totalValueOfRedeemedGiftCardInCents?: number;
  appliedGiftCardAmountInCents?: number;
  appliedAccountBalanceInCents?: number;
  shippingInCents?: number;
  taxInCents?: number;
  referrerName?: string;
  referreeEligibleForFreeMonth?: boolean;
  referreeEligibleForCartDiscount?: boolean;
}

// Cart items
export interface GiftCardDelivery {
  deliverAt?: DateTime | null;
  emailAddress: string;
  firstName?: string | null;
  lastName?: string | null;
  gifterName?: string | null;
  personalMessage?: string | null;
}

export interface LineItem {
  lineItemId: string;
  sku: string;
}

export interface SingleLineCartItem {
  cartItemId: string;
  lineItem: LineItem;
  quantity: number;
}

/**
 * This cart item is associated with a specific module id. Used in a couple cases:
 *   1) to specify the module that's being upgraded
 *   2) to specify the module that should be assigned the newly purchased subscription
 */
export interface ForModuleCartItem extends SingleLineCartItem {
  forModuleId: string;
}

export interface BundleCartItem extends SingleLineCartItem {
  subscriptionLineItem: LineItem;
  addonLineItems?: LineItem[];
}

export interface ForModuleBundleCartItem extends BundleCartItem {
  forModuleId: string;
}

export interface GiftCardCartItem extends SingleLineCartItem {
  giftCardDelivery: GiftCardDelivery;
}

export type StoreCartItem = SingleLineCartItem | BundleCartItem | GiftCardCartItem;
export type Series3UpgradeCartItem = ForModuleBundleCartItem;
export type SubscriptionCartItem = ForModuleCartItem;

export type CartItem = StoreCartItem | Series3UpgradeCartItem | SubscriptionCartItem;

/**
 * Defines a cart for the main e-commerce store. Other carts include: upgrade, subscription
 */
export interface StoreCart {
  cartItems: { [key: string]: StoreCartItem };
  redeemedGiftCardCode?: string;
  couponCode?: string;
  referralCode?: string;
  referrerName?: string;
}

export interface Series3UpgradeCart {
  cartItems: { [key: string]: Series3UpgradeCartItem };
  // Need to add these properties to the upgrade cart to simplify some reuseable code that operates on a Cart type
  // which is a union of cart types. But for the S3 upgrade cart we do not allow for adding any codes to the cart so
  // they will always be undefined.
  redeemedGiftCardCode?: undefined;
  couponCode?: undefined;
  referralCode?: undefined;
  referrerName?: string;
}

/**
 * A cart where the cart items will be for purchasing subscriptions for specific modules. These carts do not allow
 * promo codes.
 */
export interface SubscriptionCart {
  cartItems: { [key: string]: SubscriptionCartItem };
  redeemedGiftCardCode?: string;
  couponCode?: string;
  referralCode?: undefined;
  referrerName?: string;
}

export type Cart = StoreCart | Series3UpgradeCart | SubscriptionCart;
