import React, { useMemo } from 'react';
import { Image } from '../../../../components/Image';
import { ProductLink, ProductLinkLoc } from '../../../../components/ProductLink';
import { ColorOption, IImage, ProductCategory } from '../../../../types';
import { expectUnreachable, centsToDollars } from '../../../../lib/util';
import { ModuleItem } from '../../AccessoryTypes';
import styles from './ItemTile.module.scss';
import { colorVariantName, bandSeriesToCollarSeries } from '../../../../lib/product';
import classNames from 'classnames';

const IMAGE_WIDTH = 612;
const IMAGE_HEIGHT = 440;

const ItemTile = ({ item }: { item: ModuleItem }) => {
  const image: IImage | undefined = useMemo((): IImage | undefined => {
    if (item.category === ProductCategory.CHARGING_BASE) {
      return {
        url: `/product_images/${item.productId}.jpg`,
        retinaWidth: IMAGE_WIDTH,
        retinaHeight: IMAGE_HEIGHT,
      };
    } else if (item.category === ProductCategory.BAND) {
      return {
        url: `/product_images/${item.productId}/${item.variantOptions.color.replace(/\s/, '_')}.jpg`,
        retinaWidth: IMAGE_WIDTH,
        retinaHeight: IMAGE_HEIGHT,
      };
    } else {
      expectUnreachable(item);
      return undefined;
    }
  }, [item]);

  const productName = useMemo(() => {
    if (item.nameOverride) {
      return item.nameOverride;
    }

    if (item.category === ProductCategory.BAND) {
      // Bands get a custom "Color Band" name. But we have to capitalize the color.
      const colorName = colorVariantName(item.variantOptions.color, item.bandSeries);
      return `${bandSeriesToCollarSeries(item.bandSeries)} ${colorName} Band`;
    }

    return item.productName;
  }, [item]);

  if (!image) {
    return null;
  }

  return (
    <div
      className={classNames(styles.accessoriesModuleItemContainer, {
        [styles.showItemAsFirstInGroup]:
          item.category === ProductCategory.BAND ? item.variantOptions.color === ColorOption.Strava : false,
      })}
    >
      <div className={styles.accessoriesModuleItem}>
        <ProductLink
          productId={item.productId}
          from={ProductLinkLoc.Accessories}
          variantOptions={item.category === ProductCategory.BAND ? item.variantOptions : undefined}
        >
          <div className={styles.accessoriesModuleItemImage}>
            <Image image={image} desiredWidth={500} />
          </div>
          <div className={styles.accessoriesModuleItemDescription}>
            <span>
              {productName}
              <br />
              <span className={styles.accessoriesModuleItemDescriptionPrice}>${centsToDollars(item.priceInCents)}</span>
            </span>
          </div>
        </ProductLink>
      </div>
    </div>
  );
};

export default ItemTile;
