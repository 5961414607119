import { IImage } from '../../types';

export const GIFT_CARD_IMAGES: IImage[] = [
  {
    url: '/gift_card/yellow_card.jpg',
    retinaWidth: 1500,
    retinaHeight: 1125,
  },
  {
    url: '/gift_card/box.jpg',
    retinaWidth: 1500,
    retinaHeight: 844,
  },
  {
    url: '/gift_card/dog_with_collar_zoomed.jpg',
    retinaWidth: 1200,
    retinaHeight: 1200,
  },
  {
    url: '/gift_card/dog_with_collar_black_background.jpg',
    retinaWidth: 1500,
    retinaHeight: 1000,
  },
  {
    url: '/gift_card/dog_with_collar.jpg',
    retinaWidth: 1500,
    retinaHeight: 1000,
  },
];
