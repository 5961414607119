import { useLocation } from 'react-router-dom';
import AppPaths from '../AppPaths';
import { useSelector } from 'react-redux';
import { AppState } from '../types';

interface PathBasedAppCustomization {
  hideNav: boolean;
  inCheckoutFlow: boolean;
  showPromotionBanner: boolean;
  inBag: boolean;
}

export function usePathBasedAppCustomization(): PathBasedAppCustomization {
  const { pathname } = useLocation();
  const showPromoBannerImprovements = useSelector(
    (state: AppState) => !!state.config.siteConfig.enableEcommPromoBannerImprovements,
  );

  const hideNav = pathname.startsWith('/series2upgrade') || pathname.startsWith('/purchasesubscription');
  const inCheckoutFlow = pathname.startsWith('/checkout') && !pathname.endsWith('/thank-you');
  const inBag = pathname === AppPaths.Bag;

  // Show the promotion banner on either S2 or S3 page, on the cart page, and, if promo banner flag is on, through the checkout flow
  const showPromotionBanner =
    pathname.startsWith('/products/smart-collar-v') ||
    pathname.startsWith('/bag') ||
    (showPromoBannerImprovements && inCheckoutFlow && pathname !== AppPaths.CheckoutSupplements);

  return {
    hideNav,
    inCheckoutFlow,
    showPromotionBanner,
    inBag,
  };
}
