import { DateTime } from 'luxon';
import ordinal from 'ordinal';
import React, { useEffect, useMemo } from 'react';
import Chooser from '../../../../components/Chooser';
import useShippingOptions from '../../../../hooks/useShippingOptions';
import * as types from '../../../../types';
import ShippingOptionsItem from './ShippingOptionsItem';
import styles from './ShippingOptionsList.module.scss';

interface ShippingOptionsListProps {
  anyPreorder: boolean;
  canExpedite: boolean;
  currentlySelected: types.ShippingCode | undefined;
  onSelect: (shippingCode: types.ShippingCode) => void;
}

export default function ShippingOptionsList({
  anyPreorder,
  canExpedite,
  currentlySelected,
  onSelect,
}: ShippingOptionsListProps) {
  const shippingOptions = useShippingOptions();
  const availableShippingCodesSet = useMemo(
    () => new Set(shippingOptions.filter(({ isExpedited }) => !isExpedited || canExpedite).map((so) => so.code)),
    [shippingOptions, canExpedite],
  );

  useEffect(() => {
    // If only 1 shipping option is available, select it.
    if (availableShippingCodesSet.size === 1 && !currentlySelected) {
      onSelect(Array.from(availableShippingCodesSet)[0]);
    }
  }, [availableShippingCodesSet, currentlySelected, onSelect, shippingOptions]);

  const disclaimer: string | undefined = useMemo(() => {
    const shippingOption = currentlySelected
      ? shippingOptions.find((so) => so.code === currentlySelected)
      : shippingOptions[0];

    if (!canExpedite) {
      return '* Expedited shipping not available for P.O. Box addresses or US territories';
    } else if (shippingOption?.arrivesOn && !anyPreorder) {
      const arrivesOnDate = DateTime.fromISO(shippingOption.arrivesOn).toLocal();
      const ordinalDay = ordinal(arrivesOnDate.day);
      const formattedDate = arrivesOnDate.toFormat(`cccc, LLLL '${ordinalDay}'`); // Friday, August 6th

      return `* This order will arrive on ${formattedDate}`;
    }
  }, [currentlySelected, anyPreorder, shippingOptions, canExpedite]);

  return (
    <>
      <div className={styles.main}>
        <h4>Shipping method</h4>
        <Chooser
          onSelect={(code) => {
            if (availableShippingCodesSet.has(code)) {
              onSelect(code);
            }
          }}
          selectedOption={currentlySelected}
          groups={[
            {
              footer: <div className={styles.shippingDisclaimer}>{disclaimer}</div>,
              options: shippingOptions.map(({ code, name, displayedCarrier, priceInCents: price_in_cents }) => {
                const disabled = !availableShippingCodesSet.has(code);

                return {
                  value: code,
                  disabled,
                  content: (
                    <ShippingOptionsItem
                      key={code}
                      disabled={disabled}
                      name={name}
                      carrier={displayedCarrier}
                      priceInCents={price_in_cents}
                    />
                  ),
                };
              }),
            },
          ]}
          showRadio
        />
      </div>
    </>
  );
}
