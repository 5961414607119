import classNames from 'classnames';
import React from 'react';
import { ReactComponent as IconLte } from '../../../../../assets/images/icons/icon_lte.svg';
import { ReactComponent as IconMessage } from '../../../../../assets/images/icons/icon_message.svg';
import { ReactComponent as IconUpdate } from '../../../../../assets/images/icons/icon_update.svg';
import { ReactComponent as IconWarranty } from '../../../../../assets/images/icons/icon_warranty.svg';
// import { ReactComponent as IconPhone } from '../../../../../assets/images/icons/icon_phone.svg';
import styles from '../../../ProductDetails.module.scss';
import { ValueProp } from './ValuePropsBox';

export const WHATS_INCLUDED_VALUE_PROPS: ValueProp[] = [
  {
    icon: <IconLte />,
    text: (
      <div className={styles.valueProp}>
        <span className={styles.valuePropHeader}>Unlimited AT&T LTE-M connectivity.</span>
      </div>
    ),
  },
  {
    icon: <IconWarranty />,
    text: (
      <div className={styles.valueProp}>
        <div className={styles.valuePropHeader}>Fi Series 3 Warranty</div>
        <div className={classNames(styles.valuePropBody, styles.valuePropCheat)}>
          Unlimited warranty replacements on active memberships.
        </div>
      </div>
    ),
  },
  {
    icon: <IconUpdate />,
    text: (
      <div className={styles.valueProp}>
        <span className={styles.valuePropHeader}>Unlimited software updates. Series 3 keeps getting better.</span>
      </div>
    ),
  },
  {
    icon: <IconMessage />,
    text: (
      <div className={styles.valueProp}>
        <span className={styles.valuePropHeader}>Live chat - customer service available 7 days a week.</span>
      </div>
    ),
  },
  // Hide Support+ for now
  // {
  //   icon: <IconPhone />,
  //   text: (
  //     <div className={styles.valueProp}>
  //       <span className={styles.valuePropBadge}>NEW</span> <span className={styles.valuePropHeader}>Support+ </span>
  //       <span className={styles.valuePropBody}>Phone support & prioritized support for 1 year members and up. </span>
  //     </div>
  //   ),
  // },
];
