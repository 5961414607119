import classNames from 'classnames';
import React, { MutableRefObject, useCallback, useMemo, useState } from 'react';
import Chooser, { ChooserGroup } from '../Chooser';
import * as events from '../../lib/analytics/events';
import * as types from '../../types';
import SubscriptionOptionButton, { SubscriptionOption } from '../SubscriptionOptionDetails';
import styles from './SubscriptionOptions.module.scss';

export interface CouponPillDisplay {
  shouldShow: boolean;
  title?: string;
  subtitle?: string;
}

interface ChooserGroupsProps {
  allowNoSubscription?: boolean;
  disablePrioritizedShippingUpsell?: boolean;
  subscriptionProducts: SubscriptionOption[];
  showReferralPill?: boolean;
  couponPillDisplay?: CouponPillDisplay;
  showUpsellsInChooser?: boolean;
  newEcomFlow?: boolean;
}

export function useSubscriptionChooserGroups({
  allowNoSubscription = false,
  disablePrioritizedShippingUpsell = false,
  subscriptionProducts,
  showReferralPill = false,
  couponPillDisplay,
  showUpsellsInChooser = false,
  newEcomFlow = false,
}: ChooserGroupsProps) {
  // For the subscription chooser, we usually don't want to show any plans that are meant to be only for upsells which will
  // be made available via an interstitial modal, but the in-app cancel flow doesn't employ upselling in the same way,
  // so we set the showUpsellsInChooser and surface all purchasable plans.
  const filteredSubscriptionProducts = useMemo(
    () =>
      showUpsellsInChooser
        ? subscriptionProducts
        : subscriptionProducts.filter((subscriptionProduct) => !subscriptionProduct.upsellOnly),
    [subscriptionProducts, showUpsellsInChooser],
  );

  const chooserGroup = useCallback(
    (
      subscriptionProductGroup: SubscriptionOption[],
      groupLabel?: string,
      groupDescription?: string,
      shouldShowReferralPill = false,
      couponPill?: CouponPillDisplay,
    ): ChooserGroup<string | undefined> => ({
      label: groupLabel,
      options: subscriptionProductGroup.map((subscriptionProduct) => ({
        content: <SubscriptionOptionButton subscriptionProduct={subscriptionProduct} newEcomFlow={newEcomFlow} />,
        value: subscriptionProduct.sku,
      })),
      description: groupDescription,
      showReferralPill: shouldShowReferralPill,
      couponPill,
    }),
    [newEcomFlow],
  );

  const yearlyChooserGroup = useMemo(
    () =>
      chooserGroup(
        filteredSubscriptionProducts.filter(
          (subscriptionProduct) =>
            !subscriptionProduct.buyItMembership && subscriptionProduct.billingCadence === types.BillingCadence.Year,
        ),
      ),
    [chooserGroup, filteredSubscriptionProducts],
  );

  const monthToMonthChooserGroup = useMemo(
    () =>
      chooserGroup(
        filteredSubscriptionProducts.filter(
          (subscriptionProduct) =>
            !subscriptionProduct.buyItMembership &&
            subscriptionProduct.billingCadence === types.BillingCadence.Month &&
            subscriptionProduct.renewalMonths === 1,
        ),
      ),
    [chooserGroup, filteredSubscriptionProducts],
  );

  const prepaidChooserGroup = useMemo(
    () =>
      chooserGroup(
        filteredSubscriptionProducts.filter(
          (subscriptionProduct) =>
            !subscriptionProduct.buyItMembership &&
            subscriptionProduct.billingCadence === types.BillingCadence.Month &&
            subscriptionProduct.renewalMonths > 1,
        ),
        // Only show a header for the prepay section if they have any month-to-month options available to them
        monthToMonthChooserGroup.options.length > 0 ? 'Prepay and save' : undefined,
        disablePrioritizedShippingUpsell ? undefined : 'Prioritized shipping',
        showReferralPill,
        couponPillDisplay,
      ),
    [
      chooserGroup,
      monthToMonthChooserGroup.options.length,
      filteredSubscriptionProducts,
      disablePrioritizedShippingUpsell,
      showReferralPill,
      couponPillDisplay,
    ],
  );

  const buyItChooserGroup = useMemo(
    () =>
      chooserGroup(
        filteredSubscriptionProducts.filter((subscriptionProduct) => subscriptionProduct.buyItMembership),
        'Or pay one time', // Group header
      ),
    [chooserGroup, filteredSubscriptionProducts],
  );

  const chooserGroups = useMemo(() => {
    const groups = [yearlyChooserGroup, monthToMonthChooserGroup, prepaidChooserGroup, buyItChooserGroup].filter(
      (group) => group.options.length > 0,
    );
    if (allowNoSubscription) {
      groups.push({
        options: [
          {
            content: <div className={styles.noSubscriptionOption}>I don't need a plan</div>,
            value: undefined,
          },
        ],
      });
    }
    return groups;
  }, [allowNoSubscription, buyItChooserGroup, monthToMonthChooserGroup, prepaidChooserGroup, yearlyChooserGroup]);

  return chooserGroups;
}

interface SubscriptionOptionsProps {
  allowNoSubscription?: boolean;
  onSubscriptionChange: (sku: string | undefined) => void;
  selectedSubscriptionSku?: string;
  subscriptionProducts: types.ISubscriptionProduct[];
  title?: string;
  showReferralPill?: boolean;
  couponPillDisplay?: CouponPillDisplay;
  productName: string;
  subscriptionPickerRef?: MutableRefObject<HTMLDivElement | null>;
  startAsUnselected: boolean;
  disableSection: boolean;
  newEcomFlow: boolean;
}

export default function SubscriptionOptions({
  allowNoSubscription = false,
  onSubscriptionChange,
  selectedSubscriptionSku,
  subscriptionProducts,
  title = 'Choose your plan',
  showReferralPill = false,
  couponPillDisplay,
  productName,
  subscriptionPickerRef,
  startAsUnselected,
  disableSection,
  newEcomFlow,
}: SubscriptionOptionsProps) {
  const chooserGroups = useSubscriptionChooserGroups({
    allowNoSubscription,
    subscriptionProducts,
    showReferralPill,
    couponPillDisplay,
    newEcomFlow,
    disablePrioritizedShippingUpsell: newEcomFlow,
  });

  const [showSelected, setShowSelected] = useState<boolean>(!startAsUnselected);

  const needsSelection = false;
  const SERIES_3_NAME = 'SERIES 3';
  return (
    <div
      className={classNames(styles.variant, { [styles.disabledSection]: disableSection })}
      ref={subscriptionPickerRef}
    >
      <div className={styles.variantTitleGroup}>
        <div className={classNames(styles.variantTitle, { [styles.variantTitleError]: needsSelection })}>{title}</div>
        {!newEcomFlow && productName === SERIES_3_NAME && (
          <div className={styles.variantInfo}>All memberships include a Fi Series 3 collar</div>
        )}
      </div>
      <Chooser
        onSelect={(subscriptionSku: string | undefined) => {
          setShowSelected(true);
          onSubscriptionChange(subscriptionSku);

          if (!subscriptionSku) {
            return;
          }

          const subscription = subscriptionProducts.find((product) => product.sku === subscriptionSku);
          if (subscription) {
            events.subscriptionSelected(subscription);
          }
        }}
        selectedOption={showSelected ? selectedSubscriptionSku : undefined}
        groups={chooserGroups}
      />
    </div>
  );
}
