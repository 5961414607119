import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import AppPaths from '../../../AppPaths';
import ImageGallery from '../../../components/ImageGallery/ImageGallery';
import * as events from '../../../lib/analytics/events';
import { bandSeriesToCollarSeries } from '../../../lib/product';
import { cartActions as storeCartActions } from '../../../reducers/storeCart';
import * as types from '../../../types';
import { Series } from '../../../types/Series';
import AddToBagButton from '../components/AddToBagButton';
import BandSeriesToggle from '../components/BandSeriesToggle';
import BandVariantOptions from '../components/BandVariantOptions';
import ProductTitle from '../components/ProductTitle';
import SoldOutBanner from '../components/SoldOutBanner';
import useIsSkuBlocked from '../hooks/useIsSkuBlocked';
import useVariantSelector from '../hooks/useVariantSelector';
import styles from '../ProductDetails.module.scss';

function useBandSeriesToProductIdMap() {
  return useSelector((state: types.AppState) => {
    const products = state.config.products;

    const map = new Map<Series, string>();
    const series2BandInProductConfig = !!products.find((product) => product.id === types.f1BandId);
    if (series2BandInProductConfig) {
      map.set(Series.Series2, types.f1BandId);
    }

    const series3BandInProductConfig = !!products.find((product) => product.id === types.f3BandId);
    if (series3BandInProductConfig) {
      map.set(Series.Series3, types.f3BandId);
    }

    return map;
  });
}

interface OnChangeHandlerProps {
  priceInCents: number;
  productName: string;
}

function useOnChangeHandlers({ priceInCents, productName }: OnChangeHandlerProps) {
  const onColorChange = useCallback(
    (newVariant: types.IVariant) => {
      events.bandColorSwitch({
        color: newVariant.options.color,
        priceInCents,
        productName,
        size: newVariant.options.size,
        sku: newVariant.sku,
      });
    },
    [priceInCents, productName],
  );

  const onSizeChange = useCallback(
    (newVariant: types.IVariant) => {
      events.bandSizeSwitch({
        color: newVariant.options.color,
        priceInCents,
        productName,
        size: newVariant.options.size,
        sku: newVariant.sku,
      });
    },
    [priceInCents, productName],
  );

  const onSelectedVariantChange = useCallback(
    (newVariant: types.IVariant) => {
      events.bandVariantSwitch({
        color: newVariant.options.color,
        priceInCents,
        productName,
        size: newVariant.options.size,
        sku: newVariant.sku,
      });
    },
    [priceInCents, productName],
  );

  return {
    onColorChange,
    onSizeChange,
    onSelectedVariantChange,
  };
}

function selectedVariantImageUrl(productId: string, variant: types.IVariant) {
  return `/product_images/${productId}/${variant.options.color.replace(/[\s/]/g, '_')}.jpg`;
}

interface BandDetailsProps {
  bandSeries: types.BandSeries;
  priceInCents: number;
  productId: string;
  productName: string;
  variants: types.IVariant[];
}

export default function BandDetails({ bandSeries, priceInCents, productId, productName, variants }: BandDetailsProps) {
  const dispatch = useDispatch();
  const history = useHistory();

  const { onColorChange, onSizeChange, onSelectedVariantChange } = useOnChangeHandlers({
    priceInCents,
    productName,
  });

  const { changeVariantOptions, selectedVariant } = useVariantSelector({
    onColorChange,
    onSelectedVariantChange,
    onSizeChange,
    variants,
    newEcomFlow: false,
  });
  const isBlocked = useIsSkuBlocked(selectedVariant?.sku);

  const seriesProductIdMap = useBandSeriesToProductIdMap();

  useEffect(() => {
    events.bandViewed();
  }, []);

  return (
    <>
      <div className={styles.productContainer}>
        <div className={styles.imageGalleryContainer}>
          <ImageGallery
            images={[
              {
                url: selectedVariantImageUrl(productId, selectedVariant),
                retinaWidth: 612,
                retinaHeight: 440,
              },
            ]}
          />
        </div>
        <div className={styles.productDetails}>
          <div className={styles.productDetailsHeader}>
            <div className={styles.productDetailsTitle}>
              <ProductTitle productName={productName} price={{ priceInCents }} />
            </div>
            <p className={styles.description}>
              This is an additional collar without a tracker. Why? Well, you wouldn't wear the same shirt everyday so
              why should your dog have to wear the same collar all the time. Spice up your life.
            </p>
          </div>

          <BandSeriesToggle
            seriesProductIdMap={seriesProductIdMap}
            selectedSeries={bandSeriesToCollarSeries(bandSeries)}
            variantOptions={selectedVariant.options}
          />

          <BandVariantOptions
            bandSeries={bandSeries}
            changeVariantOptions={changeVariantOptions}
            selectedVariant={selectedVariant}
            variants={variants}
            startSizeAsUnselected={false}
            newEcomFlow={false}
          />

          {isBlocked && <SoldOutBanner />}

          <div className={styles.productAction}>
            <AddToBagButton
              disabled={isBlocked}
              onAddToBag={() => {
                if (isBlocked) {
                  return false;
                }

                events.bandAddedToBag({
                  color: selectedVariant.options.color,
                  priceInCents,
                  productName,
                  size: selectedVariant.options.size,
                  sku: selectedVariant.sku,
                });

                dispatch(
                  storeCartActions.addSingleLineCartItem({
                    sku: selectedVariant.sku,
                  }),
                );

                history.push(AppPaths.Bag);

                return true;
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
}
