import * as types from '../../types';

export default class ApplePayPaymentState {
  selectedShippingCode: types.ShippingCode | null;
  country: string | null;
  postalCode: string | null;

  constructor() {
    this.selectedShippingCode = null;
    this.country = null;
    this.postalCode = null;
  }

  clear() {
    this.selectedShippingCode = null;
    this.country = null;
    this.postalCode = null;
  }

  // NOTE: ApplePayJS.ApplePayShippingContactSelectedEvent contains redacted shipping info, not the full information.
  // From the docs: "The redacted information includes only the necessary data for completing
  // transaction tasks, such as calculating taxes or shipping costs."
  updateFromShippingContactSelected(evt: ApplePayJS.ApplePayShippingContactSelectedEvent) {
    this.country = evt.shippingContact.countryCode || null;
    this.postalCode = evt.shippingContact.postalCode || null;

    // Changing the shipping contact re-sets the shipping method (but doesn't call that callback).
    this.selectedShippingCode = null;
  }

  updateFromShippingMethodSelected(evt: ApplePayJS.ApplePayShippingMethodSelectedEvent) {
    this.selectedShippingCode = (evt.shippingMethod.identifier as types.ShippingCode) || null;
  }

  toAddress(): { country: string; postalCode: string } | null {
    return this.country && this.postalCode
      ? {
          country: this.country,
          postalCode: this.postalCode,
        }
      : null;
  }
}
