import React from 'react';

import Question from './Question';
import styles from './FAQ.module.scss';

export default function FAQ({ newEcomFlow }: { newEcomFlow?: boolean }) {
  return (
    <div className={styles.container}>
      <h2 className={styles.heading}>FAQ</h2>
      <div>
        {!newEcomFlow && (
          <Question title="What happens when a new generation of hardware is released?">
            When you have an active Fi membership, you don't have to worry about having outdated hardware; you are
            eligible to upgrade to the latest generation of hardware.
          </Question>
        )}
        <Question title="When does my Fi membership start?">
          Your Fi membership starts when you activate your Fi, or 7 days after the Series 3 is shipped.
        </Question>
        <Question title="Why is Fi a membership?">
          The Fi membership provides a lower-cost way for customers to try the most advanced technology for their dogs
          without a high upfront cost. Fi also believes in continuing to deliver new features, analytics, and benefits,
          and a membership model allows us to continue investing in the best possible customer experience.
        </Question>
        <Question title="Can I lower my monthly payment cost?">
          The longer you commit to Fi, the lower your monthly cost. Once you are a member, you can always switch to an
          annual membership to lock in savings. Additionally, our referral program gives you a free month for each
          person you refer.
        </Question>
        <Question title="What is the commitment period on the month to month and 3 month memberships?">
          When you sign up for Fi, you can try it for 30 days. If for any reason you decide that Fi is not for you, you
          can return your Fi within the return window. Otherwise, the month to month and 3 month memberships have a
          minimum commitment of 6 months.
        </Question>
        {newEcomFlow && (
          <Question title="What happens when a new generation of hardware is released?">
            When you have an active Fi membership, you don't have to worry about having outdated hardware; you are
            eligible to upgrade to the latest generation of hardware.
          </Question>
        )}
      </div>
    </div>
  );
}
