import { useSelector } from 'react-redux';
import useIsShoppingInPerson from './useIsShoppingInPerson';
import { logInternalError } from '../lib/errors';
import { getOperatingSystem } from '../lib/util';
import * as types from '../types';

let memoSupportsApplePay: boolean | null = null;

export function osSupportsApplePay() {
  const os = getOperatingSystem(window);

  // If there is no OS, let's err on the side of caution and
  // show Apple Pay rather than hide it
  if (!os.name) {
    return true;
  }

  return /(Mac|iOS)/i.test(os.name);
}

export function browserSupportsApplePay() {
  if (memoSupportsApplePay !== null) {
    return memoSupportsApplePay;
  }

  // https://developer.apple.com/documentation/apple_pay_on_the_web/apple_pay_js_api/checking_for_apple_pay_availability
  // Memoizing this since it apparently takes up to ~500ms to compute
  // as of 6/18/16 (perf regression on Apple's end?)
  try {
    const applePaySession = (window as any).ApplePaySession;
    memoSupportsApplePay = !!(applePaySession && applePaySession.canMakePayments());
  } catch (exception) {
    // If we run into an error while trying to show apple pay, log it and abort.
    logInternalError(exception);
    memoSupportsApplePay = false;
  }

  return memoSupportsApplePay;
}

export default function useShouldShowApplePay(): boolean {
  const isShoppingInPerson = useIsShoppingInPerson();
  return useSelector(
    (state: types.AppState) => !!(!isShoppingInPerson && state.config.siteConfig.showApplePay && osSupportsApplePay()),
  );
}
