import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useQuery } from '@apollo/client';

import { gqlTypes } from '../../../types';
import Button from '../../../components/Button/Button';
import { getLoadingOrErrorElement } from '../../../lib/util/getLoadingOrErrorElement';
import { ReactComponent as Checkmark } from '../../../assets/images/checkmark_white.svg';
import styles from '../styles/SubscriptionContainer.module.scss';
import SubscriptionAppBar from '../components/SubscriptionAppBar';
import { supplementsManagement as events } from '../../../lib/analytics/events';
import SupplementPill from './components/SupplementPill';
import { supplementsSubscriptionsQuery } from '../../../graphql-operations';
import { BillingSubscriptionState, WeightRange } from '../../../types/gql-op-types';
import Upsell from './components/Upsell/Upsell';

export interface SubscriptionOption {
  sku: string;
  name?: string;
  description?: string;
  priceInCents: number;
  displayedRate?: string;
  displayedRateUnit: string;
  billingPeriodsPerTerm: number;
  weeksBetweenShipments?: number | null;
  inAppManagementName: string;
  freeTrialDays?: number | null;
  recommendedForDogWeightRangePounds?: WeightRange | null;
}

interface ShipmentDates {
  nextShipmentDate: string;
  previewResumingShipmentDate: string;
  previewSkippingShipmentDate: string;
  previewUpdate: { sku: string; nextShipDate: string }[];
}
export interface BillingSubscription {
  id: string;
  state: BillingSubscriptionState;
  subscriptionOption: SubscriptionOption;
  currentTermEndsAt: string;
  isPaused?: boolean | null;
  supplementShipmentDates?: ShipmentDates | null;
}

type ChangeType = 'canceled' | 'updated' | 'added';

interface ToastProps {
  change: ChangeType;
}

function Toast({ change }: ToastProps) {
  const [shown, setShown] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setShown(false);
    }, 4000);
  }, []);
  return (
    <>
      {shown && (
        <div className={styles.toast}>
          <Checkmark />
          <div className={styles.toastContent}>Subscription successfully {change}</div>
        </div>
      )}
    </>
  );
}

interface SplashPageState {
  toast?: { success: boolean; change: ChangeType };
}

export default function SplashPage() {
  const pageName = 'In-App Supplements Main Page';
  const displayPageName = 'Supplements';
  events.pageLoad({}, pageName);

  const [addClicked, setAddClicked] = useState(false);
  const location = useLocation<SplashPageState>();
  const [showToast, _setShowToast] = useState(location.state?.toast ? true : false);
  const toastChange = location.state?.toast?.change ?? null;

  const { data, loading, error } = useQuery<gqlTypes.ECOMMERCE_getSupplementSubscriptionsQuery>(
    supplementsSubscriptionsQuery,
    { fetchPolicy: 'cache-and-network' },
  );
  const loadingOrErrorElement = getLoadingOrErrorElement(loading, error);
  if (loadingOrErrorElement) {
    return loadingOrErrorElement;
  }

  const canManage = data?.currentUser.canManageSupplementSubscriptions;
  const addButtonDisabled = !canManage;

  const supplementSubscriptions: BillingSubscription[] = data?.currentUser.billingAccount?.subscriptions ?? [];
  const activeAndPausedSupplementSubscriptions = supplementSubscriptions?.filter(
    (sub) => sub.state === BillingSubscriptionState.ACTIVE || sub.state === BillingSubscriptionState.PAUSED,
  );
  // Sort by nextShipmentDate ascending
  const sortedSubscriptions = activeAndPausedSupplementSubscriptions.sort((a: any, b: any) =>
    a.supplementShipmentDates.nextShipmentDate.localeCompare(b.supplementShipmentDates.nextShipmentDate),
  );

  const componentToReturn = () => {
    function CantManage() {
      return (
        <>
          <div className={styles.fullWidthWrapper}>
            <SubscriptionAppBar
              title={displayPageName}
              backButtonExitsWebview={true}
              backButtonAnalyticsEvent={() => events.goBackFromPage({}, pageName)}
              closeButtonAnalyticsEvent={() => events.closePage({}, pageName)}
            />
            <p className={styles.unauthorized}>
              Unable to manage supplement subscriptions. Please contact support@tryfi.com if you think this is an error.
            </p>
          </div>
        </>
      );
    }

    function MainPage() {
      return (
        <>
          <div className={styles.fullWidthWrapper}>
            <SubscriptionAppBar
              title={displayPageName}
              backButtonExitsWebview={true}
              backButtonAnalyticsEvent={() => events.goBackFromPage({}, pageName)}
              closeButtonAnalyticsEvent={() => events.closePage({}, pageName)}
            />
            <div className={styles.subscriptionContainer}>
              <h1>Your supplements</h1>
              {sortedSubscriptions.map((subscription: any) => (
                <SupplementPill
                  showManageButton={true}
                  subscription={subscription}
                  subscriptionDetails={subscription}
                  version={'informational'}
                  paused={subscription.isPaused ? true : false}
                  pendingPlan={subscription.pendingChanges ? subscription.pendingChanges.subscriptionOption : null}
                  key={subscription.id}
                />
              ))}
            </div>

            {showToast && toastChange && <Toast change={toastChange} />}
            <div className={styles.buttonContainer}>
              <Button
                className={styles.button}
                disabled={addButtonDisabled}
                onClick={() => {
                  events.addSupplementsClicked({});
                  setAddClicked(true);
                }}
              >
                Add Supplements
              </Button>
              {/* Spacer ensures primary button is aligned with buttons on neighboring pages in in-app cancel flow */}
              <div className={styles.buttonSpacer}></div>
            </div>
          </div>
        </>
      );
    }
    if (!canManage) {
      return <CantManage />;
    }

    if (!sortedSubscriptions.length) {
      return <Upsell referrer="upsell" closeUpsell={() => setAddClicked(false)} />;
    }

    if (addClicked) {
      return <Upsell referrer="add" closeUpsell={() => setAddClicked(false)} />;
    }
    return <MainPage />;
  };

  return <> {componentToReturn()}</>;
}
