import React from 'react';
import styles from '../../styles/SubscriptionContainer.module.scss';
import SubscriptionAppBar from '../../components/SubscriptionAppBar';
import Button from '../../../../components/Button';
import CancelConfirmationModal from '../../components/CancelConfirmationModal';
import { useHistory, useLocation } from 'react-router-dom';
import { usePurchaseSubscriptionContext } from '../../../PurchaseSubscription/context/PurchaseSubscriptionContext';
import * as gqlTypes from '../../../../types/gql-op-types';
import { useZendeskCancellationTicket } from '../hooks/useZendesk';
import { inAppCancellation as events } from '../../../../lib/analytics/events';
import AppPaths from '../../../../AppPaths';
import SubscriptionPill, { SubscriptionPillStyle } from '../../../../components/SubscriptionPill/SubscriptionPill';
import { BillingCadence } from '../../../../types';
import { useMutation } from '@apollo/client';
import { downgradeS2Mutation } from '../../../../graphql-operations';
import { getLoadingOrErrorElement } from '../../../../lib/util';

export interface DowngradeS2State {
  selectedCancelReason: gqlTypes.zendeskCancellationReason;
}

export default function DowngradeS2() {
  const { device } = usePurchaseSubscriptionContext();
  const history = useHistory();
  const location = useLocation<DowngradeS2State>();
  const { selectedCancelReason } = location.state;

  const [downgradeS2, downgradeS2State] = useMutation<
    gqlTypes.ECOMMERCE_downgradeS2,
    gqlTypes.ECOMMERCE_downgradeS2Variables
  >(downgradeS2Mutation, {
    onCompleted: (data) => {
      const success = !!data.downgradeSeries2?.success;
      if (!success) {
        throw new Error('Failed to downgrade S2 membership: contact customer support.');
      }
      history.push({
        pathname: AppPaths.Subscription.DowngradeS2Confirmed(device.moduleId),
        search: window.location.search,
      });
    },
  });

  if (!device.pet) {
    throw new Error('No pet found');
  }
  const petName = device.pet.name;

  events.pageLoad({ petId: device.pet.id }, 'Downgrade S2');

  const { mutation: zendeskMutation, loading: zendeskMutationLoading } = useZendeskCancellationTicket({
    analyticsEvent: events.downgradeS2Cancel,
    selectedCancelReason,
    device,
  });
  const loadingOrErrorElement =
    zendeskMutationLoading || getLoadingOrErrorElement(downgradeS2State.loading, downgradeS2State.error);
  if (loadingOrErrorElement) {
    return loadingOrErrorElement;
  }

  const handleSwitchToMonthlyPlan = () => {
    downgradeS2({
      variables: {
        input: {
          moduleId: device.moduleId,
        },
      },
    });
  };

  const handleCancel = () => {
    zendeskMutation({
      variables: {
        input: {
          recurlySubscriptionId: device.subscription!.id,
          cancellationReason: selectedCancelReason,
          additionalText: undefined,
        },
      },
    });
  };

  // Only non-monthly S2 devices get directed to this page:
  // other than monthly there are only 12, 24, or 36 month renewal terms for S2s.
  const renewalTermText = (() => {
    switch (device.subscription!.subscriptionOption.renewalMonths) {
      case 12:
        return 'a full year';
      case 24:
        return 'two full years';
      case 36:
        return 'three full years';
      default:
        // Should never happen, but just in case.
        return `the full ${device.subscription!.subscriptionOption.renewalMonths} months`;
    }
  })();

  return (
    <>
      <div className={styles.fullWidthWrapper}>
        <SubscriptionAppBar
          backButtonAnalyticsEvent={() => events.downgradeS2Back({ petId: device.pet?.id })}
          closeButtonAnalyticsEvent={() => events.downgradeS2Close({ petId: device.pet?.id })}
        />
        <div className={styles.subscriptionContainer}>
          <div className={styles.content}>
            <div className={styles.largeHeader}>
              <h1>How about more flexibility?</h1>
            </div>

            <p>
              We want to keep you and {petName} in the pack. If you don't want to pay for {renewalTermText}, we're happy
              to switch you to a more workable monthly membership.
            </p>

            <SubscriptionPill
              style={SubscriptionPillStyle.Downgrade}
              renewalDate={undefined}
              subscriptionProduct={{
                billingPeriodsPerTerm: 1, // Not used for display purposes
                displayedRate: '', // Not used for display purposes
                displayedRateUnit: '', // Not used for display purposes
                sku: '', // Not used for display purposes
                name: '', // Not used for display purposes
                valueLabel: 'EXCLUSIVE OFFER',
                priceInCents: 900,
                renewalMonths: 1,
                billingCadence: BillingCadence.Month,
              }}
              overrideTitle={'1 MONTH'}
            />

            <p>This exclusive offer is only available right now — if you skip it you'll miss it.</p>
          </div>
        </div>
        <div className={styles.buttonContainer}>
          <Button className={styles.button} onClick={() => handleSwitchToMonthlyPlan()}>
            Switch to Monthly Plan
          </Button>

          <CancelConfirmationModal onContinue={() => handleCancel()} pageName={'Downgrade S2'} petId={device.pet.id} />
        </div>
      </div>
    </>
  );
}
