import * as React from 'react';
import styles from './OrderConfirmedBanner.module.scss';
import { ReactComponent as Checkmark } from '../../../../../assets/images/check-yellow-bg.svg';

export interface OrderConfirmedBannerProps {
  email?: string;
}

// Static component for supplements page/experiment
export default function OrderConfirmedBanner({ email }: OrderConfirmedBannerProps) {
  return (
    <>
      <div className={styles.orderConfirmedBanner}>
        <Checkmark />
        <div className={styles.text}>
          <h2 className={styles.confirmedText}>Your order is confirmed!</h2>
          <p className={styles.emailText}>Be on the lookout for an email {email && `at ${email}`} for tracking.</p>
        </div>
      </div>
    </>
  );
}
