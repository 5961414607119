import Cookies from 'js-cookie';

import { IWindow } from '../Window';
import store from '../reduxStore';

// Copied from https://segment.com/docs/connections/spec/identify/#traits
interface ReservedIdentifyTraits {
  address?: {
    city?: string;
    country?: string;
    postalCode?: string;
    state?: string;
    street?: string;
  };
  age?: number;
  avatar?: string;
  birthday?: Date;
  company?: {
    name?: string;
    id?: string | number;
    industry?: string;
    employee_count?: number;
    plan?: string;
  };
  createdAt?: Date;
  description?: string;
  email?: string;
  firstName?: string;
  gender?: string;
  id?: string; // Unique ID in your database for a user
  lastName?: string;
  name?: string;
  phone?: string;
  title?: string;
  username?: string;
  website?: string;
}

// Any custom identify traits we want to send
/* eslint-disable-next-line @typescript-eslint/no-empty-interface */
interface FiIdentifyTraits {}

// The union of reserved traits & Fi traits
export type IdentifyTraits = ReservedIdentifyTraits & FiIdentifyTraits;

// For reference: https://segment.com/docs/connections/spec/common/#context
interface SegmentContext extends Record<string, any> {
  traits?: IdentifyTraits;
}

export interface IAnalyticsBackend {
  identify(userId: string): void;
  identify(userId: string, traits?: IdentifyTraits): void;
  identify(userId: string, traits?: IdentifyTraits, options?: SegmentAnalytics.SegmentOpts): void;
  page(): void;
  track(name: string, properties: any): void;
  track(name: string, properties: any, options?: SegmentAnalytics.SegmentOpts): void;
  ready(callback: () => void): void;
  trackLink(element: HTMLElement, name: string, properties?: any): void;
  /** Reset anonymous ID */
  reset(): void;

  pause(): void;
  unpause(): void;
}

interface ISegmentAnalyticsSource {
  analytics: SegmentAnalytics.AnalyticsJS;
}

function facebookPixelPropertiesFromCookies(): { fbc: string | undefined; fbp: string | undefined } {
  // These are cookies set by Facebook for their pixel & ad clicks
  // https://developers.facebook.com/docs/marketing-api/conversions-api/parameters/fbp-and-fbc
  // We send these along so our analytics-backend can in turn send them to the Facebook Conversion API
  const fbc = Cookies.get('_fbc');
  const fbp = Cookies.get('_fbp');

  return {
    fbc,
    fbp,
  };
}

function tikTokPixelPropertiesFromCookies(): { ttclid: string | undefined; ttp: string | undefined } {
  // These cookies are set by TikTok for their pixel & ad clicks
  // https://ads.tiktok.com/marketing_api/docs?id=1701890980108353
  // We send these along so our analytics-backend can in turn send them to the TikTok Events API
  const ttclid = Cookies.get('ttclid');
  const ttp = Cookies.get('_ttp');

  return {
    ttclid,
    ttp,
  };
}

function augmentProperties(properties: any) {
  let timezone: string | undefined;
  try {
    timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  } catch (e) {
    // Noop
  }

  return {
    ...properties,
    ...facebookPixelPropertiesFromCookies(),
    ...tikTokPixelPropertiesFromCookies(),
    timezone,
  };
}

abstract class AnalyticsWrapper {
  constructor(protected readonly backend: IAnalyticsBackend) {}
}

export class SuppressImpersonationAnalytics extends AnalyticsWrapper implements IAnalyticsBackend {
  identify(userId: string, traits?: IdentifyTraits, options?: SegmentAnalytics.SegmentOpts) {
    this.maybeExecute('identify', userId, traits, options);
  }

  page() {
    this.maybeExecute('page');
  }

  track(name: string, properties: any, options?: SegmentAnalytics.SegmentOpts) {
    this.maybeExecute('track', name, properties, options);
  }

  ready(callback: () => void) {
    this.backend.ready(callback);
  }

  trackLink(element: HTMLElement, name: string, properties?: any) {
    this.maybeExecute('trackLink', element, name, properties);
  }

  pause() {
    this.backend.pause();
  }

  unpause() {
    this.backend.unpause();
  }

  reset() {
    this.maybeExecute('reset');
  }

  private get shouldExecute() {
    return !store.getState().session?.impersonating;
  }

  private maybeExecute<K extends keyof IAnalyticsBackend>(name: K, ...args: Parameters<IAnalyticsBackend[K]>) {
    if (this.shouldExecute) {
      (this.backend[name] as (...fnArgs: any) => void).apply(this.backend, args);
    }
  }
}

export class QueuedAnalytics extends AnalyticsWrapper implements IAnalyticsBackend {
  private readonly queue: Array<{ name: keyof IAnalyticsBackend; args: any[] }>;
  private pauseCount: number;

  constructor(backend: IAnalyticsBackend) {
    super(backend);
    this.queue = [];
    this.pauseCount = 0;
  }

  public identify(userId: string, traits?: IdentifyTraits, options?: SegmentAnalytics.SegmentOpts) {
    this.queueOrExecute('identify', userId, traits, options);
  }

  public page(): void {
    this.queueOrExecute('page');
  }

  public track(name: string, properties: any, options?: SegmentAnalytics.SegmentOpts): void {
    this.queueOrExecute('track', name, properties, options);
  }

  public ready(callback: () => void) {
    this.backend.ready(callback);
  }

  public reset() {
    this.backend.reset();
  }

  public trackLink(element: HTMLElement, name: string, properties?: any) {
    // No queueing necessary
    this.backend.trackLink(element, name, properties);
  }

  public pause() {
    this.pauseCount++;
  }

  public unpause() {
    this.pauseCount--;
    if (this.pauseCount === 0) {
      this.flushQueue();
    }
  }

  private executeCall({ name, args }: { name: keyof IAnalyticsBackend; args: any[] }) {
    (this.backend[name] as (...fnArgs: any) => void).apply(this.backend, args);
  }

  private queueOrExecute<K extends keyof IAnalyticsBackend>(name: K, ...args: Parameters<IAnalyticsBackend[K]>) {
    const obj = { name, args };
    if (this.pauseCount === 0) {
      this.executeCall(obj);
    } else {
      this.queue.push(obj);
    }
  }

  private flushQueue() {
    while (this.queue.length > 0) {
      this.executeCall(this.queue.shift()!);
    }
  }
}

class SegmentAnalytics implements IAnalyticsBackend {
  private source: ISegmentAnalyticsSource;

  constructor(source: ISegmentAnalyticsSource) {
    this.source = source;
  }

  public identify(userId: string, traits?: IdentifyTraits, options?: SegmentAnalytics.SegmentOpts) {
    this.source.analytics.identify(userId, traits, options);
  }

  public page(): void {
    this.source.analytics.page(augmentProperties({}), this.augmentSegmentOptions());
  }

  public track(name: string, properties: any, options?: SegmentAnalytics.SegmentOpts): void {
    this.source.analytics.track(name, augmentProperties(properties), this.augmentSegmentOptions(options));
  }

  public trackLink(element: HTMLElement, name: string, properties: any = {}) {
    this.source.analytics.trackLink(element, name, properties);
  }

  public ready(callback: () => void) {
    this.source.analytics.ready(callback);
  }

  public reset() {
    this.source.analytics.reset();
  }

  public pause() {
    // Noop
  }

  public unpause() {
    // Noop
  }

  private augmentSegmentOptions(options?: SegmentAnalytics.SegmentOpts) {
    // This is not always defined
    if (typeof this.source.analytics.user !== 'function') {
      return options;
    }

    // The segment types are incorrect on this, you can access traits in this fashion, just
    // run `analytics.user().traits()` in the ecommerce site's browser console to verify
    // Also in code: https://github.com/segmentio/analytics.js/blob/7dda4259d40375666ea6fd166d51b208d79c38ec/analytics.js#L5040
    const userTraits = this.source.analytics.user().traits() as unknown as IdentifyTraits;

    // Short circuit if we have no user traits to merge in
    if (!userTraits || Object.keys(userTraits).length === 0) {
      return options;
    }

    // Short circuit if we have user traits, but no base options were given
    if (!options) {
      return { context: { traits: userTraits } };
    }

    // Merge traits into options giving preference to the options.context.traits over user.traits
    const { traits: contextTraits, ...originalContext } = (options?.context ?? {}) as SegmentContext;
    const combinedTraits = contextTraits ? { ...userTraits, ...contextTraits } : userTraits;
    return {
      ...options,
      context: { ...originalContext, traits: combinedTraits },
    };
  }
}

// tslint:disable:no-console
class LogAnalytics implements IAnalyticsBackend {
  public identify(userId: string, traits?: IdentifyTraits, options?: SegmentAnalytics.SegmentOpts) {
    console.log('Identifying user as: ', userId, traits, options);
  }

  public page(): void {
    console.log('Analytics: page');
  }

  public reset(): void {
    console.log('Analytics: reset');
  }

  public track(name: string, properties: any, options?: SegmentAnalytics.SegmentOpts): void {
    console.log(
      'Analytics: track - name: ',
      name,
      ', properties: ',
      augmentProperties(properties),
      ', options: ',
      options,
    );
  }

  public ready(callback: () => void) {
    setTimeout(() => {
      callback();
    }, 0);
  }

  public trackLink(element: HTMLElement, name: string, properties?: any) {
    // Noop
  }

  public pause() {
    // Noop
  }

  public unpause() {
    // Noop
  }
}

// tslint:enable:no-console

const extendedWindow: IWindow = window;
const analytics = extendedWindow.analytics
  ? new SuppressImpersonationAnalytics(
      new QueuedAnalytics(new SegmentAnalytics(extendedWindow as ISegmentAnalyticsSource)),
    )
  : new LogAnalytics();

export default analytics;
