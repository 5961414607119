import { ApplePayConfig, ApplePayInstance } from '@recurly/recurly-js';
import { IWindow } from './Window';

const PUBLIC_KEY = process.env.REACT_APP_RECURLY_PUBLIC_KEY || '';

export interface IError {
  name: string;
  code: string;
  message: string;
  fields: string[];
}

export interface IToken {
  id: string;
}

export interface IConfiguration {
  publicKey?: string;
  required?: string[];
}

// https://developer.apple.com/documentation/apple_pay_on_the_web/applepaypaymentrequest/2216121-requiredshippingcontactfields
export type ShippingContactField = 'email' | 'name' | 'postalAddress' | 'phone';

export interface RecurlyApplePayPayment extends ApplePayJS.ApplePayPayment {
  recurlyToken: {
    type: string;
    id: string;
  };
}

export interface RecurlyApplePayPaymentAuthorizedEvent extends ApplePayJS.ApplePayPaymentAuthorizedEvent {
  payment: RecurlyApplePayPayment;
}

export interface FiApplePayError {
  code: ApplePayJS.ApplePayErrorCode | 'accountExists' | 'fiApiError';
  contactField?: ApplePayJS.ApplePayErrorContactField;
  message: string;
}

export interface RecurlyApplePayCallbackResult {
  errors?: FiApplePayError[];
}

export interface RecurlyApplePayUpdateResult extends RecurlyApplePayCallbackResult {
  newLineItems?: ApplePayJS.ApplePayLineItem[];
  newShippingMethods?: ApplePayJS.ApplePayShippingMethod[];
  newTotal?: ApplePayJS.ApplePayLineItem;
}

interface RecurlyApplePayCallbacks {
  onPaymentAuthorized?: (
    event: RecurlyApplePayPaymentAuthorizedEvent,
  ) => Promise<RecurlyApplePayCallbackResult | undefined>;
  onPaymentMethodSelected?: (
    event: ApplePayJS.ApplePayPaymentMethodSelectedEvent,
  ) => Promise<RecurlyApplePayUpdateResult>;

  onShippingContactSelected?: (
    event: ApplePayJS.ApplePayShippingContactSelectedEvent,
  ) => Promise<RecurlyApplePayUpdateResult>;

  onShippingMethodSelected?: (
    event: ApplePayJS.ApplePayShippingMethodSelectedEvent,
  ) => Promise<RecurlyApplePayUpdateResult>;
}

interface IRecurlyApplePayOptions extends ApplePayConfig {
  country: 'US';
  currency: 'USD';
  pricing?: any;
  /**
   * Undocumented as of yet, but looks stable.
   * https://github.com/recurly/recurly-js/pull/474
   */
  requiredShippingContactFields?: ShippingContactField[];
  callbacks?: RecurlyApplePayCallbacks;
}

export interface IRecurlyApplePayInstance extends ApplePayInstance {
  // Private undocumented Recurly methods that we need to override.
  onPaymentAuthorized: (event: RecurlyApplePayPaymentAuthorizedEvent, result?: RecurlyApplePayCallbackResult) => void;
  onPaymentMethodSelected: (
    event: ApplePayJS.ApplePayPaymentMethodSelectedEvent,
    result?: RecurlyApplePayUpdateResult,
  ) => void;
  onShippingContactSelected: (
    event: ApplePayJS.ApplePayShippingContactSelectedEvent,
    result?: RecurlyApplePayUpdateResult,
  ) => void;
  onShippingMethodSelected: (
    event: ApplePayJS.ApplePayShippingMethodSelectedEvent,
    result?: RecurlyApplePayUpdateResult,
  ) => void;

  session: ApplePaySession;
  finalTotalLineItem: any;
  lineItems: any;
}

interface IRecurlyPayPalOptions {
  display: {
    displayName: string;
  };
}

export interface IRecurlyPayPal {
  start(): void;
  on(event: string, listener: Function): Function;
  once(event: string, listener: Function): Function;
  off(): Function;
}

export type IRecurlyApplePay = (options: IRecurlyApplePayOptions) => IRecurlyApplePayInstance;

export interface IRecurly {
  Pricing: {
    Checkout: () => Recurly.CheckoutPricing;
    Subscription: () => Recurly.SubscriptionPricing;
  };
  ApplePay: IRecurlyApplePay;
  PayPal: (options: IRecurlyPayPalOptions) => IRecurlyPayPal;
  configure(configuration: IConfiguration): void;
  token(form: any, callback: (err?: IError, token?: IToken) => void): void;
}

export class Provider {
  private static instance: Provider;

  public value: IRecurly;

  constructor(source: IWindow = window) {
    if (Provider.instance) {
      this.value = Provider.instance.value;
      return Provider.instance;
    }

    if (!source.recurly) {
      throw new Error('Please load Recurly.js (https://js.recurly.com/v4/recurly.js) on this page');
    } else {
      this.value = source.recurly;
      this.value.configure({ publicKey: PUBLIC_KEY, required: ['cvv'] });
    }
  }
}

export default Provider;
