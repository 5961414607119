import classNames from 'classnames';
import React from 'react';
import { centsToDollars } from '../../lib/util';
import styles from '../../styles/lineItem.module.scss';
import * as types from '../../types';
import { Image } from '../Image';
import RemoveLineItemButton from '../RemoveLineItemButton';

/**
 * Defines an individual pricing row to display for a cart item. In the case of bundles, we want to list out
 * all of the products in the bundle with a rolled up price. A cart item could have multiple pricing rows
 * e.g. we want to split out subscription activation fee price to display separately.
 */
export interface ItemPriceRowProps {
  discountedFromOriginalPriceInCents?: number;
  freeTrialLengthInDays?: number;
  productName: string;
  secondary?: boolean;
  totalPriceInCents?: number;
  variantDescription?: string;
}

interface ProductCartItemProps {
  id: types.CartItemID;
  priceRows: ItemPriceRowProps[];
  productImageUrl?: string;
  removeCartItem?: () => void;
  quantity?: number;
}

function numberInCentsToMoney(numberInCents: number) {
  const isNegative = numberInCents < 0 ? '-' : '';
  return isNegative + '$' + centsToDollars(Math.abs(numberInCents));
}

function ItemPriceRow({
  discountedFromOriginalPriceInCents,
  freeTrialLengthInDays,
  productName,
  secondary,
  totalPriceInCents,
  variantDescription,
}: ItemPriceRowProps) {
  const discounted = !!discountedFromOriginalPriceInCents;
  const waived = totalPriceInCents === 0 && discounted;

  return (
    <div
      className={classNames(styles.lineItemPriceRow, {
        [styles.secondaryPriceRow]: !!secondary,
        [styles.waived]: waived,
      })}
    >
      <div className={styles.lineItemDetails}>
        <div className={styles.lineItemDetailsRow}>
          <div className={styles.productName}>
            {productName}
            {variantDescription && <span className={styles.variantDescription}> ({variantDescription})</span>}
          </div>
        </div>
      </div>

      <div
        className={classNames(styles.lineItemPrices, {
          [styles.freeTrial]: !!freeTrialLengthInDays,
        })}
      >
        {!!discountedFromOriginalPriceInCents && (
          <div className={styles.lineItemOriginalPrice}>{numberInCentsToMoney(discountedFromOriginalPriceInCents)}</div>
        )}
        {!!totalPriceInCents && (
          <div
            className={classNames(styles.lineItemPrice, {
              [styles.credit]: totalPriceInCents < 0,
            })}
          >
            {numberInCentsToMoney(totalPriceInCents)}
          </div>
        )}
        {freeTrialLengthInDays && <div className={styles.freeTrialLength}>Free for {freeTrialLengthInDays} days</div>}
      </div>
    </div>
  );
}

export default function ProductCartItem({
  priceRows,
  productImageUrl,
  removeCartItem,
  quantity,
}: ProductCartItemProps) {
  return (
    <div className={styles.lineItem}>
      {removeCartItem && (
        <div className={styles.removeButtonRow}>
          <RemoveLineItemButton onClick={removeCartItem} />
        </div>
      )}
      <div className={styles.itemRow}>
        <div
          className={classNames(styles.lineItemImage, {
            [styles.empty]: !productImageUrl,
          })}
        >
          {productImageUrl && <Image image={{ url: productImageUrl }} alt={priceRows[0].productName} />}
        </div>
        <div className={styles.lineItemBody}>
          <div className={styles.lineItemPriceRows}>
            {priceRows.map((priceRow, i) => (
              <ItemPriceRow
                discountedFromOriginalPriceInCents={priceRow.discountedFromOriginalPriceInCents}
                freeTrialLengthInDays={priceRow.freeTrialLengthInDays}
                key={i}
                productName={priceRow.productName}
                secondary={priceRow.secondary}
                totalPriceInCents={priceRow.totalPriceInCents}
                variantDescription={priceRow.variantDescription}
              />
            ))}
          </div>

          {quantity && quantity > 1 ? <div className={styles.lineItemQuantity}>x {quantity}</div> : null}
        </div>
      </div>
    </div>
  );
}
