import Cookies from 'js-cookie';
import React, { useCallback, useContext, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import setCouponCookie from '../../lib/setCouponCookie';
import { COUPON_COOKIE_NAME, expectUnreachable } from '../../lib/util';
import { useCartPricing } from '../../contexts/CartPricingContext';
import usePromoCodeEligibility from '../../hooks/usePromoCodeEligibility';
import * as events from '../../lib/analytics/events';
import CheckoutContext from '../../lib/CheckoutContext';
import { CouponError, GiftCardError, ReferralCodeError, logInternalError, PromoCodeError } from '../../lib/errors';
import { validatePromoCode, PromoCodeValidationErrorResponse } from '../../lib/promoCode';
import * as types from '../../types';
import ErrorMessage from '../ErrorMessage';
import styles from './PromoCode.module.scss';
import PromoCodeForm from './PromoCodeForm';
import { isAxiosError } from '../../lib/fi-api/apiUtils';

export default function PromoCode() {
  const dispatch = useDispatch();
  const cartPricing = useCartPricing();
  const { cart, checkoutActions } = useContext(CheckoutContext);
  const [error, setError] = useState<string | null>(null);
  const showCoupons = useSelector((state: types.AppState) => !!state.config.siteConfig.showCoupons);
  const [pendingCodeValue, setPendingCodeValue] = useState('');
  const [submitting, setSubmitting] = useState(false);

  const onResolved = useCallback(() => {
    setSubmitting(false);
    setPendingCodeValue('');
  }, []);

  const { couponCodeEligibility, giftCardEligibility, referralCodeEligibility } = usePromoCodeEligibility({
    cart,
    showCoupons,
  });

  // When promo codes are applied from url redirects, they can get attached to carts even if they're not valid for the
  // current cart. This is because we don't want to lose the promo code if the user adds an item to the cart
  // that makes it valid. When applying a new code, we want to ignore any invalid codes that are attached just in case
  // the backend would reject the new code due to stacking restrictions.
  const cartExcludingInvalidCodes: types.Cart = useMemo(
    () => ({
      ...cart,
      couponCode: cartPricing.validationErrors?.couponCode ? undefined : cart.couponCode,
      referralCode: cartPricing.validationErrors?.referralCode ? undefined : cart.referralCode,
      redeemedGiftCardCode: cartPricing.validationErrors?.redeemedGiftCardCode ? undefined : cart.redeemedGiftCardCode,
    }),
    [
      cart,
      cartPricing.validationErrors?.couponCode,
      cartPricing.validationErrors?.redeemedGiftCardCode,
      cartPricing.validationErrors?.referralCode,
    ],
  );

  /**
   * Even though we may have already determined eligibility for our code types via usePromoCodeEligibility, we still do
   * the checks for each when submitting the form because we use an onmibox and we want to differentiate error messages
   * between a code that is not found and a code that is found but cannot be applied due to eligibility reasons.
   */
  const onSubmit = useCallback(async () => {
    setSubmitting(true);

    try {
      const result = await validatePromoCode(cartExcludingInvalidCodes, pendingCodeValue);
      if (result.promoCodeType === 'coupon') {
        if (!couponCodeEligibility.canApplyCode) {
          throw CouponError.ineligible(couponCodeEligibility.ineligibilityReason);
        }

        dispatch(checkoutActions.addCoupon(pendingCodeValue));
        events.cartPage.couponApplied(pendingCodeValue);
        setCouponCookie(pendingCodeValue);
      } else if (result.promoCodeType === 'referral') {
        if (!referralCodeEligibility.canApplyCode) {
          throw ReferralCodeError.ineligible(referralCodeEligibility.ineligibilityReason);
        }

        dispatch(checkoutActions.addReferral({ code: pendingCodeValue, referrerName: result.referrerName }));
        events.cartPage.referralCodeApplied(pendingCodeValue);
      } else if (result.promoCodeType === 'giftCard') {
        if (!giftCardEligibility.canApplyCode) {
          throw GiftCardError.ineligible(giftCardEligibility.ineligibilityReason);
        }

        dispatch(checkoutActions.addGiftCard(pendingCodeValue));
        events.cartPage.giftCardApplied(pendingCodeValue);
      } else if (result.promoCodeType === 'internalCoupon') {
        if (!couponCodeEligibility.canApplyCode) {
          throw CouponError.ineligible(couponCodeEligibility.ineligibilityReason);
        }

        dispatch(checkoutActions.addCoupon(pendingCodeValue));
        events.cartPage.couponApplied(pendingCodeValue);
        setCouponCookie(pendingCodeValue);
      } else {
        expectUnreachable(result.promoCodeType);
        throw new Error('Unexpected promo code type');
      }
    } catch (err) {
      if (isAxiosError(err) && err.response?.data.error) {
        const errorResponseData: PromoCodeValidationErrorResponse = err.response?.data.error;
        setError(errorResponseData.message);
      } else if (err instanceof PromoCodeError) {
        setError(err.message);
      } else {
        setError('An unknown error occurred. Please try again.');
        logInternalError(err);
      }

      Cookies.remove(COUPON_COOKIE_NAME);
    } finally {
      onResolved();
    }
  }, [
    cartExcludingInvalidCodes,
    checkoutActions,
    couponCodeEligibility,
    dispatch,
    giftCardEligibility,
    onResolved,
    pendingCodeValue,
    referralCodeEligibility,
  ]);

  // If they cannot apply any codes, don't show the form
  if (
    !couponCodeEligibility.canApplyCode &&
    !giftCardEligibility.canApplyCode &&
    !referralCodeEligibility.canApplyCode
  ) {
    return null;
  }

  const allowedCodeTypes = [];
  if (couponCodeEligibility.canApplyCode) {
    allowedCodeTypes.push('Coupon');
  }

  if (referralCodeEligibility.canApplyCode) {
    allowedCodeTypes.push('Referral');
  }

  if (giftCardEligibility.canApplyCode) {
    allowedCodeTypes.push('Gift card');
  }

  const placeholder = `${allowedCodeTypes.join(' / ')} code`;

  const disabled = submitting || pendingCodeValue === '';

  return (
    <div className={styles.main}>
      <PromoCodeForm
        actionLabel="Apply"
        disabled={disabled}
        onChange={(code: string) => {
          setPendingCodeValue(code);
          setError(null);
        }}
        onSubmit={onSubmit}
        pendingCodeValue={pendingCodeValue}
        placeholder={placeholder}
      />
      {error && <ErrorMessage errors={[error]} />}
    </div>
  );
}
