import { FeaturesReady, useGrowthBook } from '@growthbook/growthbook-react';
import classNames from 'classnames';
import { DateTime } from 'luxon';
import React from 'react';
import { centsToDollarsNumber, expectUnreachable, getPriceForRateUnit, RateUnit } from '../../lib/util';
import * as types from '../../types';
import styles from './SubscriptionOptionDetails.module.scss';
import { getFiGrowthBook } from '../../lib/growthbook';

export interface SubscriptionOption {
  billingCadence: types.BillingCadence;
  billingPeriodsPerTerm: number;
  buyItMembership?: boolean;
  displayedRate: string;
  displayedRateUnit: string;
  name: string;
  priceInCents: number;
  renewalMonths: number;
  renewalYears?: number | null;
  savings?: string | null;
  sku: string;
  upsellOnly?: boolean;
  valueLabel?: string | null;
  supportPlus?: boolean;
  couponExtensionMonths?: number;
}

interface SubscriptionOptionButtonProps {
  subscriptionProduct: SubscriptionOption;
  renewalDate?: DateTime;
  expirationDate?: DateTime;
  disableSavingsDisplay?: boolean;
  overrideTitle?: string;
  pricingRateUnit?: RateUnit;
  renewScreens?: boolean;
  newEcomFlow?: boolean;
}

function renewalTitle(renewalMonths: number, newEcomFlow: boolean) {
  if (renewalMonths % 12 === 0) {
    const years = Math.round(renewalMonths / 12);
    return newEcomFlow ? `${years} Year` : `${years} YEAR`;
  }

  if (renewalMonths === 1) {
    return newEcomFlow ? 'Monthly' : 'MONTHLY';
  }

  return newEcomFlow ? `${renewalMonths} Months` : `${renewalMonths} MONTHS`;
}

function billingCadenceDescription(subscription: SubscriptionOption, newEcomFlow: boolean) {
  if (newEcomFlow) {
    return `Equal to ${getPriceForRateUnit(subscription, 'week').displayRate} per week`;
  }

  let pricePeriod = '';
  const price = `$${centsToDollarsNumber(subscription.priceInCents)}`;
  if (subscription.renewalMonths === 1) {
    pricePeriod = `${price}/month`;
  } else if (subscription.renewalMonths === 6) {
    pricePeriod = `${price} semi-annually`;
  } else if (subscription.renewalMonths % 12 === 0) {
    const years = Math.round(subscription.renewalMonths / 12);
    if (years === 1) {
      pricePeriod = `${price} annually`;
    } else if (years === 2) {
      pricePeriod = `${price} biennially`;
    } else {
      pricePeriod = `${price} per ${years} years`;
    }
  } else {
    pricePeriod = `${price} per ${subscription.renewalMonths} months`;
  }

  return `Billed as ${pricePeriod}`;
}

export function MonthlySubscriptionContent({
  subscriptionProduct,
  renewalDate,
  expirationDate,
  disableSavingsDisplay,
  overrideTitle,
  pricingRateUnit = 'week',
  renewScreens = false,
  newEcomFlow = false,
}: SubscriptionOptionButtonProps) {
  const DATE_FORMAT_TOKEN = 'LL/dd/yy';
  // useGrowthBook returns undefined if we're not wrapped in a GrowthBookProvider. We only use the GrowthBookProvider in
  // the main store flow so in-app webviews won't have this.
  const growthBookEnabled = !!useGrowthBook();
  const { unit: displayRateUnit, displayRate } = getPriceForRateUnit(subscriptionProduct, pricingRateUnit);

  const prepaidPeriodTitle = overrideTitle ?? renewalTitle(subscriptionProduct.renewalMonths, newEcomFlow);
  const subscriptionExtensionPromoBannerEnabled =
    (growthBookEnabled
      ? getFiGrowthBook().getFeatureValue<number>('subscription-extension-promo-banner-variant-index', 0)
      : 0) === 1;

  return (
    <div className={styles.container}>
      <div className={styles.column}>
        <div
          className={classNames(styles.row, styles.title, {
            [styles.subscriptionExtensionPromo]: subscriptionExtensionPromoBannerEnabled,
          })}
        >
          {prepaidPeriodTitle && <div className={styles.billingCadence}>{prepaidPeriodTitle}</div>}
          {subscriptionProduct.savings && !disableSavingsDisplay && !newEcomFlow && (
            <div className={styles.savingsPill}>{subscriptionProduct.savings}</div>
          )}
        </div>
        <div className={styles.price}>
          {newEcomFlow && `$${centsToDollarsNumber(subscriptionProduct.priceInCents)}`}
          {!newEcomFlow && (
            <>
              <span className={styles.priceBold}>{displayRate}</span>/{displayRateUnit}
            </>
          )}
        </div>
        <div
          className={classNames(styles.description, {
            [styles.accommodateSupportPlus]: subscriptionProduct.supportPlus,
          })}
        >
          {billingCadenceDescription(subscriptionProduct, newEcomFlow)}
        </div>
        {/* renewScreens is true only if expirationDate exists, but we need this redundant check to appease Typescript. */}
        {renewScreens && expirationDate ? (
          <div className={styles.description}>Plan expires on {expirationDate.toFormat(DATE_FORMAT_TOKEN)}</div>
        ) : (
          renewalDate && <div className={styles.description}>Renews {renewalDate.toFormat(DATE_FORMAT_TOKEN)}</div>
        )}
      </div>
      <div className={classNames(styles.column, styles.right)}>
        {subscriptionProduct.savings && !disableSavingsDisplay && newEcomFlow && (
          <div className={styles.savingsPill}>{subscriptionProduct.savings}</div>
        )}
        {!newEcomFlow && subscriptionExtensionPromoBannerEnabled && subscriptionProduct.couponExtensionMonths && (
          <div className={classNames(styles.savingsPill, styles.couponExtension)}>
            <span className={styles.title}>EXCLUSIVE OFFER</span>
            {!newEcomFlow && <br />}
            <span className={styles.description}>
              +{subscriptionProduct.couponExtensionMonths} MONTH
              {subscriptionProduct.couponExtensionMonths > 1 ? 'S' : ''} FREE
            </span>
          </div>
        )}
        {newEcomFlow && subscriptionProduct.couponExtensionMonths && (
          <div className={classNames(styles.savingsPill, styles.couponExtension)}>
            <span className={styles.title}>EXCLUSIVE OFFER</span>
            {!newEcomFlow && <br />}
            <span className={styles.description}>
              +{subscriptionProduct.couponExtensionMonths} month
              {subscriptionProduct.couponExtensionMonths > 1 ? 's' : ''} free
            </span>
          </div>
        )}
      </div>
      {/** We will be hiding these labels but keeping the code here in case we turn em back on */}
      {/* <div className={classNames(styles.column, styles.right)}>
        {subscriptionProduct.valueLabel && (
          <span
          className={classNames(styles.valueLabel, {
            [styles.mostPopular]: labelClass === 'mostpopular',
            [styles.bestValue]: labelClass === 'bestvalue',
            [styles.exclusiveOffer]: labelClass === 'exclusiveoffer',
          })}
          >
          {subscriptionProduct.valueLabel}
          </span>
          )}
          <div className={styles.supportPlusWrapper}>
          {subscriptionProduct.supportPlus && (
            <>
            <div className={classNames(styles.supportLabel)}>Support+ included</div>
            <div className={classNames(styles.supportDescription)}>Prioritized Phone Support</div>
            </>
            )}
            </div>
          </div> */}

      {renewScreens && (
        <div className={classNames(styles.column, styles.right)}>
          <span className={classNames(styles.valueLabel, styles.canceled)}>CANCELED</span>
        </div>
      )}
    </div>
  );
}

export function YearlySubscriptionContent({
  subscriptionProduct,
  renewalDate,
  expirationDate,
  disableSavingsDisplay,
  overrideTitle,
  renewScreens = false,
  newEcomFlow = false,
}: SubscriptionOptionButtonProps) {
  const DATE_FORMAT_TOKEN = 'LL/dd/yy';
  return (
    <div className={styles.container}>
      <div className={styles.column}>
        <div className={classNames(styles.row, styles.title)}>
          <div className={styles.billingCadence}>
            {overrideTitle ?? renewalTitle(subscriptionProduct.renewalMonths, newEcomFlow)}
          </div>
          {subscriptionProduct.savings && !disableSavingsDisplay && (
            <div className={styles.savingsPill}>{subscriptionProduct.savings}</div>
          )}
        </div>
        <div className={styles.price}>
          <span className={styles.priceBold}>{subscriptionProduct.displayedRate}</span>/
          {subscriptionProduct.displayedRateUnit}
        </div>
        <div className={styles.description}>{billingCadenceDescription(subscriptionProduct, newEcomFlow)}</div>
        {/* renewScreens is true only if expirationDate exists, but we need this redundant check to appease Typescript. */}
        {renewScreens && expirationDate ? (
          <div className={styles.description}>Plan expires on {expirationDate.toFormat(DATE_FORMAT_TOKEN)}</div>
        ) : (
          renewalDate && <div className={styles.description}>Renews {renewalDate.toFormat(DATE_FORMAT_TOKEN)}</div>
        )}
      </div>
      {renewScreens ? (
        <div className={classNames(styles.column, styles.right)}>
          <span className={classNames(styles.valueLabel, styles.canceled)}>CANCELED</span>
        </div>
      ) : (
        <div className={styles.column}></div>
      )}
    </div>
  );
}

export function BuyItSubscriptionContent({ subscriptionProduct }: SubscriptionOptionButtonProps) {
  return (
    <div className={styles.container}>
      <div className={styles.column}>
        <div className={classNames(styles.row, styles.title)}>
          <div className={styles.billingCadence}>BUY IT</div>
        </div>
        <div className={styles.price}>{subscriptionProduct.displayedRate}</div>
        <div className={styles.description}>One time payment</div>
      </div>
      <div className={classNames(styles.column, styles.right)}>
        <div className={styles.supportPlusWrapper}>
          {subscriptionProduct.supportPlus && (
            <>
              <div className={classNames(styles.supportLabel)}>Support+ included</div>
              <div className={classNames(styles.supportDescription)}>Prioritized Phone Support</div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export function SubscriptionOverrideContent() {
  return (
    <div className={styles.container}>
      <div className={styles.column}>
        <div className={classNames(styles.row, styles.title)}>
          <div className={styles.billingCadence}>SUBSCRIPTION OVERRIDE</div>
        </div>
        <div className={styles.price}>$0</div>
        <div className={styles.description}>Renews indefinitely</div>
      </div>
      <div className={styles.column}></div>
    </div>
  );
}

export default function SubscriptionOptionButton({
  disableSavingsDisplay,
  renewalDate,
  expirationDate,
  subscriptionProduct,
  overrideTitle,
  renewScreens,
  newEcomFlow = false,
}: SubscriptionOptionButtonProps) {
  // This can be undefined if we're not wrapped in a GrowthBookProvider. We only use the GrowthBookProvider in
  // the main store flow so in-app webviews won't have this.
  const growthBookEnabled = !!useGrowthBook();

  if (subscriptionProduct.buyItMembership) {
    return <BuyItSubscriptionContent subscriptionProduct={subscriptionProduct} />;
  } else if (subscriptionProduct.billingCadence === types.BillingCadence.Month) {
    const monthlySubscriptionContent = (
      <MonthlySubscriptionContent
        disableSavingsDisplay={disableSavingsDisplay}
        renewalDate={renewalDate}
        expirationDate={expirationDate}
        overrideTitle={overrideTitle}
        subscriptionProduct={subscriptionProduct}
        pricingRateUnit={'week'}
        renewScreens={renewScreens}
        newEcomFlow={newEcomFlow}
      />
    );

    return growthBookEnabled ? <FeaturesReady>{monthlySubscriptionContent}</FeaturesReady> : monthlySubscriptionContent;
  } else if (subscriptionProduct.billingCadence === types.BillingCadence.Year) {
    return (
      <YearlySubscriptionContent
        disableSavingsDisplay={disableSavingsDisplay}
        renewalDate={renewalDate}
        expirationDate={expirationDate}
        overrideTitle={overrideTitle}
        subscriptionProduct={subscriptionProduct}
        renewScreens={renewScreens}
        newEcomFlow={newEcomFlow}
      />
    );
  } else if (subscriptionProduct.billingCadence === types.BillingCadence.Week) {
    // We don't support weekly subscriptions yet as they are currently only used by suplement subscriptions
    return null;
  } else {
    expectUnreachable(subscriptionProduct.billingCadence);
    return null;
  }
}
