import { ActionCreatorWithPayload } from '@reduxjs/toolkit';
import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import useRequiresShippingDetails from '../hooks/useRequiresShippingDetails';
import { series3UpgradeShopActions } from '../reducers/series3UpgradeShop';
import { storeShopActions } from '../reducers/storeShop';
import { subscriptionShopActions } from '../reducers/subscriptionShop';
import * as types from '../types';
import { useCartMode } from './cartModes';
import {
  executeMembershipUpgradeMutation,
  executeStoreShopMutation,
  executeSubscriptionShopMutation,
  executeUpgradeShopMutation,
} from './purchase';
import { expectUnreachable } from './util';

type CheckoutActions = { [key: string]: ActionCreatorWithPayload<any> };

const CheckoutContext = React.createContext<{
  requiresShippingDetails: boolean;
  session: types.Session | null | undefined;
  cart: types.Cart;
  checkoutState: types.CheckoutState;
  checkoutActions: CheckoutActions;
  purchaseMutationLoading: boolean;
  executePurchaseMutation: (
    cart: types.Cart,
    checkoutState: types.CheckoutState,
  ) => Promise<types.gqlTypes.CartPurchaseResult>;
}>(undefined as any);

function useShopStateForCheckoutType(checkoutType: types.CheckoutType): types.BaseShopState {
  const storeShop = useSelector((state: types.AppState) => state.storeShop);
  const series3UpgradeShop = useSelector((state: types.AppState) => state.series3UpgradeShop);
  const subscriptionShop = useSelector((state: types.AppState) => state.subscriptionShop);

  switch (checkoutType) {
    case types.CheckoutType.Store:
      return storeShop;
    case types.CheckoutType.Upgrade:
      return series3UpgradeShop;
    case types.CheckoutType.Subscription:
    case types.CheckoutType.MembershipUpgrade:
      // subscription and membership_upgrade are similar in that we're purchasing a subscription for a specific collar
      return subscriptionShop;
    default:
      expectUnreachable(checkoutType);
      throw new Error(`Unknown checkout type: ${checkoutType}`);
  }
}

function useReduxActionsForCheckoutType(checkoutType: types.CheckoutType): CheckoutActions {
  switch (checkoutType) {
    case types.CheckoutType.Store:
      return storeShopActions;
    case types.CheckoutType.Upgrade:
      return series3UpgradeShopActions;
    case types.CheckoutType.Subscription:
    case types.CheckoutType.MembershipUpgrade:
      // subscription and membership_upgrade are similar in that we're purchasing a subscription for a specific collar
      return subscriptionShopActions;
    default:
      expectUnreachable(checkoutType);
      throw new Error(`Unknown checkout type: ${checkoutType}`);
  }
}

async function purchaseMutation(
  checkoutType: types.CheckoutType,
  cart: types.Cart,
  checkoutState: types.CheckoutState,
) {
  switch (checkoutType) {
    case types.CheckoutType.Store:
      return await executeStoreShopMutation(cart, checkoutState);
    case types.CheckoutType.Upgrade:
      return await executeUpgradeShopMutation(cart, checkoutState);
    case types.CheckoutType.Subscription:
      return await executeSubscriptionShopMutation(cart);
    case types.CheckoutType.MembershipUpgrade:
      return await executeMembershipUpgradeMutation(cart);
    default:
      expectUnreachable(checkoutType);
      throw new Error(`Unknown checkout type: ${checkoutType}`);
  }
}

function usePurchaseMutationForCheckoutType(checkoutType: types.CheckoutType) {
  const [loading, setLoading] = useState(false);
  const mutation = useCallback(
    async (cart: types.Cart, checkoutState: types.CheckoutState): Promise<types.gqlTypes.CartPurchaseResult> => {
      setLoading(true);

      try {
        return await purchaseMutation(checkoutType, cart, checkoutState);
      } finally {
        setLoading(false);
      }
    },
    [checkoutType],
  );

  return {
    executePurchaseMutation: mutation,
    purchaseMutationLoading: loading,
  };
}

interface CheckoutContextProviderProps {
  children: React.ReactNode;
}

export function CheckoutContextProvider({ children }: CheckoutContextProviderProps) {
  const { checkoutType } = useCartMode();

  const { cart, checkout: checkoutState } = useShopStateForCheckoutType(checkoutType);
  const { executePurchaseMutation, purchaseMutationLoading } = usePurchaseMutationForCheckoutType(checkoutType);

  const checkoutActions = useReduxActionsForCheckoutType(checkoutType);
  const requiresShippingDetails = useRequiresShippingDetails();
  const session = useSelector((state: types.AppState) => state.session);

  const checkoutContext = {
    cart,
    checkoutActions,
    checkoutState,
    executePurchaseMutation,
    purchaseMutationLoading,
    requiresShippingDetails,
    session,
  };

  return <CheckoutContext.Provider value={checkoutContext}>{children}</CheckoutContext.Provider>;
}

export default CheckoutContext;
