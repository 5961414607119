import classNames from 'classnames';
import React from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as MapMarker } from '../../../../assets/images/icons/icon_location.svg';
import { useCartPricing } from '../../../../contexts/CartPricingContext';
import useCheckoutPaths from '../../../../hooks/useCheckoutPaths';
import { centsToDollars } from '../../../../lib/util';
import styles from '../../../../styles/lineItem.module.scss';
import * as types from '../../../../types';

interface ShippingLineItemProps {
  customerName?: types.CustomerFullName;
  shippingAddress: types.Address;
  shippingOption: types.IShippingOption;
}

export default function ShippingLineItem({ customerName, shippingAddress, shippingOption }: ShippingLineItemProps) {
  const { shippingInCents } = useCartPricing();
  const checkoutPaths = useCheckoutPaths();

  return (
    <div className={styles.lineItem}>
      <div className={styles.itemRow}>
        <div className={styles.lineItemImage}>
          <MapMarker />
        </div>
        <div className={styles.lineItemBody}>
          <div className={styles.lineItemPriceRows}>
            <div className={styles.lineItemPriceRow}>
              {/* sentry-mask class is used to mask text in Sentry replays */}
              <div className={classNames(styles.lineItemDetails, 'sentry-mask')}>
                {customerName && (
                  <div>
                    {customerName.firstName} {customerName.lastName}
                  </div>
                )}
                <div>{shippingAddress.line1}</div>
                {shippingAddress.line2 && <div>{shippingAddress.line2}</div>}
                <div>
                  {shippingAddress.city}, {shippingAddress.state} {shippingAddress.zip}
                </div>
              </div>
              {!!shippingInCents && <div className={styles.lineItemPrice}>${centsToDollars(shippingInCents)}</div>}
            </div>
            <div>
              {shippingOption.name} {shippingOption.displayedCarrier}
            </div>
          </div>
          <div className={styles.removeButtonRow}>
            <Link to={checkoutPaths.Shipping}>Edit</Link>
          </div>
        </div>
      </div>
    </div>
  );
}
