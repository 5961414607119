import React from 'react';
import styles from '../../styles/SubscriptionContainer.module.scss';
import SubscriptionAppBar from '../../components/SubscriptionAppBar';
import CancelConfirmationModal from '../../components/CancelConfirmationModal';
import Button from '../../../../components/Button';
import { useHistory, useLocation } from 'react-router-dom';
import { usePurchaseSubscriptionContext } from '../../../PurchaseSubscription/context/PurchaseSubscriptionContext';
import { useZendeskCancellationTicket } from '../hooks/useZendesk';
import { inAppCancellation as events } from '../../../../lib/analytics/events';
import pauseImage from '../../../../assets/images/pause.png';
import { gqlTypes } from '../../../../types';
import AppPaths from '../../../../AppPaths';
import retentionOfferDogImg from '../../../../assets/images/in_app_cancellation/retention_offer_dog.jpeg';
import { claimPauseMonthMutation } from '../../../../graphql-operations';
import { useMutation } from '@apollo/client';
import { getLoadingOrErrorElement } from '../../../../lib/util';

export interface PauseMonthState {
  selectedCancelReason: gqlTypes.zendeskCancellationReason;
}

export default function PauseMonthOffer() {
  const { device } = usePurchaseSubscriptionContext();
  const history = useHistory();
  const location = useLocation<PauseMonthState>();
  const { selectedCancelReason } = location.state;
  if (!device.pet) {
    throw new Error('No pet found');
  }

  events.pageLoad({ petId: device.pet.id }, 'Pause Month');

  const petName = device.pet.name;
  const petAvatarPath = device.pet.photos.first?.image?.fullSize ?? retentionOfferDogImg;

  const { mutation: zendeskMutation, loading: zendeskMutationLoading } = useZendeskCancellationTicket({
    analyticsEvent: events.cancelFromPauseMonthPage,
    selectedCancelReason,
    device,
  });

  const [pauseMonthMutation, pauseMonthMutationState] = useMutation<
    gqlTypes.ECOMMERCE_claimPauseMonth,
    gqlTypes.ECOMMERCE_claimPauseMonthVariables
  >(claimPauseMonthMutation, {
    onCompleted: (data) => {
      const success = !!data.claimPauseMonth?.success;
      if (!success) {
        throw new Error('Failed to claim pause for a month offer: contact customer support.');
      }
      history.push({
        pathname: AppPaths.Subscription.PauseMonthConfirmed(device.moduleId),
        search: window.location.search,
      });
    },
  });

  const loadingOrErrorElement =
    zendeskMutationLoading || getLoadingOrErrorElement(pauseMonthMutationState.loading, pauseMonthMutationState.error);
  if (loadingOrErrorElement) {
    return loadingOrErrorElement;
  }

  const handleCancel = () => {
    zendeskMutation({
      variables: {
        input: {
          recurlySubscriptionId: device.subscription!.id,
          cancellationReason: selectedCancelReason,
          additionalText: undefined,
        },
      },
    });
  };

  const handleClaimPauseMonth = () => {
    events.claimPauseMonth({ petId: device.pet!.id });
    pauseMonthMutation({
      variables: {
        input: {
          moduleId: device.moduleId,
        },
      },
    });
  };

  return (
    <>
      <div className={styles.fullWidthWrapper}>
        <SubscriptionAppBar
          backButtonAnalyticsEvent={() => events.claimPauseMonthBack({ petId: device.pet?.id })}
          closeButtonAnalyticsEvent={() => events.claimPauseMonthClose({ petId: device.pet?.id })}
        />
        <div className={styles.subscriptionContainer}>
          <div className={styles.content}>
            <div className={styles.largeHeader}>
              <h1>Want to pause for a month?</h1>
            </div>

            <p>
              If you need to pause {petName}'s membership, we're happy to help! We'll pause your membership for 1 month,
              with continued access to the Fi App.
            </p>

            <div className={styles.pauseMonthOffer}>
              <div className={styles.pauseMonthOfferImages}>
                <div className={styles.pauseMonthOfferAvatar}>
                  <img src={petAvatarPath} alt={petName} />
                </div>
                <div className={styles.pauseMonthOfferPauseIcon}>
                  <img src={pauseImage} alt="Pause icon" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.buttonContainer}>
          <Button className={styles.button} onClick={() => handleClaimPauseMonth()}>
            Pause Membership
          </Button>

          <CancelConfirmationModal onContinue={() => handleCancel()} pageName={'Pause Month'} petId={device.pet.id} />
        </div>
      </div>
    </>
  );
}
