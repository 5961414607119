import classNames from 'classnames';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';

import {
  addSubscriptionMutation,
  billingAccountQuery,
  supplementsSubscriptionsQuery,
  userPetsQuery,
} from '../../../../../graphql-operations';
import AppPaths from '../../../../../AppPaths';
import { BENEFITS_VALUE_PROPS } from '../../../../Checkout/components/Supplements/ProductInfo/ValueProps';

import { getLoadingOrErrorElement } from '../../../../../lib/util';
import { sizeSortOrder } from '../../../../Checkout/components/Supplements/ProductInfo/ProductInfo';
import ImageGallery from '../../../../../components/ImageGallery/ImageGallery';
import ProductSelector from '../../../../Checkout/components/Supplements/ProductSelector';
import styles from '../../../../Checkout/components/Supplements/ProductInfo/ProductInfo.module.scss';
import upsellStyles from './Upsell.module.scss';
import * as events from '../../../../../lib/analytics/events';
import TermsAndConditions from '../../../../Checkout/components/Supplements/ProductInfo/TermsAndConditions';
import * as types from '../../../../../types';
import ValuePropsBox from '../../../../ProductDetails/Series3CollarDetails/components/ValuePropsBox';
import SubscriptionAppBar from '../../../components/SubscriptionAppBar';
import SupplementPurchaseButtons from '../../../../Checkout/components/Supplements/ProductInfo/SupplementPurchaseButtons';
import FAQ from '../../../../Checkout/components/Supplements/ProductInfo/FAQ';
import { Pet } from '../../../../../types/gql-op-types';
import { logInternalError } from '../../../../../lib/errors';
import AddPayment from '../../PurchaseSelection/AddPayment';

interface UpsellProps {
  referrer: 'add' | 'upsell';
  closeUpsell: () => void;
}

function getBiggestPet(pets: any): Pet | null {
  if (!pets || pets.length === 0) {
    return null;
  }
  if (pets.length === 1) {
    return pets[0];
  }

  let biggestPet = pets[0];
  pets.forEach((pet: Pet) => {
    if (pet.weight > biggestPet.weight) {
      biggestPet = pet;
    }
  });

  return biggestPet;
}

function getDefaultSupplementForPet(
  supplements: types.ISupplementSubscriptionProduct[],
  pet: Pet | null,
): types.ISupplementSubscriptionProduct {
  let mostFrequentSupplementSubscription = supplements[0];
  supplements.forEach((supplementSub) => {
    if (supplementSub.weeksBetweenShipments < mostFrequentSupplementSubscription.weeksBetweenShipments) {
      mostFrequentSupplementSubscription = supplementSub;
    }
  });

  if (!pet) {
    // If no pet determined to be biggest for some reason, default to most frequent supplement subscription.
    return mostFrequentSupplementSubscription;
  }

  const weight = pet.weight;
  // Default to most frequent subscription if no match is found.
  let recommendedSupplementSubscription = mostFrequentSupplementSubscription;
  for (let i = 0; i < supplements.length; i++) {
    let { min, max } = supplements[i].recommendedForDogWeightRangePounds;

    // Highest and lowest subscription cadences don't have min and max, respectively.
    if (!min) {
      min = 0;
    }
    if (!max) {
      max = 10000; // Arbitrarily large number
    }

    if (weight >= min && weight <= max) {
      recommendedSupplementSubscription = supplements[i];
      break;
    }
  }
  return recommendedSupplementSubscription;
}

export default function Upsell({ referrer, closeUpsell }: UpsellProps) {
  const pageName = 'Supplements In-App Upsell';
  events.supplementsManagement.pageLoad({}, pageName);

  const history = useHistory();
  const [error, setError] = useState('');

  const allProducts = useSelector((state: types.AppState) => state.config.products);
  const supplementItems = useMemo((): types.ISupplementSubscriptionProduct[] => {
    const unsortedSupplements = allProducts.filter(
      (p) => p.category === types.ProductCategory.SUPPLEMENT,
    ) as types.ISupplementSubscriptionProduct[];
    // Sort items so they appear in product selector in a logical order.
    return unsortedSupplements.sort(
      (a, b) => sizeSortOrder(a.recommendedForDogSize) - sizeSortOrder(b.recommendedForDogSize),
    );
  }, [allProducts]);

  const { data: petsData, loading: petsLoading, error: petsError } = useQuery<types.gqlTypes.userPets>(userPetsQuery);
  const loadingOrErrorElement = getLoadingOrErrorElement(petsLoading, petsError);
  const pets = petsData?.currentUser.userHouseholds.flatMap((household) => household.household.pets);
  const biggestPet = getBiggestPet(pets);

  const defaultSelectedProduct = getDefaultSupplementForPet(supplementItems, biggestPet);
  const [selectedProduct, setSelectedProduct] = useState<types.ISupplementSubscriptionProduct>(defaultSelectedProduct);
  const [showBillingForm, setShowBillingForm] = useState(false);

  const { data: accountData } = useQuery<types.gqlTypes.billingAccount>(billingAccountQuery, {
    onError(err) {
      logInternalError(err);
    },
  });
  const billingInfo = accountData?.currentUser.billingAccount?.billingInfo ?? null;

  useEffect(() => {
    setSelectedProduct(defaultSelectedProduct);
  }, [defaultSelectedProduct, setSelectedProduct]);

  const [purchaseMutation, purchaseMutationState] = useMutation<
    types.gqlTypes.ECOMMERCE_addSupplementSubscription,
    types.gqlTypes.ECOMMERCE_addSupplementSubscriptionVariables
  >(addSubscriptionMutation, {
    refetchQueries: [{ query: supplementsSubscriptionsQuery }],
    onCompleted: (data) => {
      const success = !!data.addSupplementSubscription?.success;
      if (!success) {
        setError(
          data.addSupplementSubscription?.reasonForFailure ??
            'Unable to purchase subscription. Please contact customer support',
        );
      } else {
        events.supplements.purchaseSucceeded('in-app');
        closeUpsell();
        history.push({
          pathname: AppPaths.Supplements.Root,
          search: window.location.search,
          state: { toast: { success: true, change: 'added' } },
        });
      }
    },
  });

  if (loadingOrErrorElement) {
    return loadingOrErrorElement;
  }

  const purchaseLoading = getLoadingOrErrorElement(purchaseMutationState.loading, purchaseMutationState.error);
  if (purchaseLoading) {
    return purchaseLoading;
  }

  const handlePurchase = async () => {
    await purchaseMutation({
      variables: {
        input: {
          skuToAdd: selectedProduct.sku,
        },
      },
    });
  };

  return (
    <>
      {showBillingForm ? (
        <AddPayment
          onSubmit={() => {
            handlePurchase();
            setShowBillingForm(false);
          }}
        />
      ) : (
        <>
          <SubscriptionAppBar
            backButtonExitsWebview={referrer === 'upsell' ? true : false}
            backButtonAnalyticsEvent={() => events.supplementsManagement.purchaseConfirmationModalGoBack({}, pageName)}
            closeButtonAnalyticsEvent={() =>
              events.supplementsManagement.purchaseConfirmationModalContinue({}, pageName)
            }
            forceBackButtonBehavior={referrer === 'add' ? closeUpsell : undefined}
            noCloseButton={referrer === 'upsell' ? true : false}
          />
          <div className={upsellStyles.productInfo}>
            <div className={styles.newLabel}>NEW</div>
            <h1 className={styles.supplementsText}>FI SUPPLEMENTS</h1>
            <p className={styles.pitch}>
              You can now easily add our new 8-in-1 supplements to your Fi Membership.
              {referrer === 'upsell' && (
                <strong>
                  <br />
                  <br />
                  SPECIAL OFFER - First Bag is FREE
                </strong>
              )}
            </p>
            <div className={classNames(styles.imageGalleryContainer)}>
              <ImageGallery
                images={[
                  {
                    url: `/product_images/supplements/Fi-Multivitamin-Pouch-front.png`,
                    retinaWidth: 1200,
                    retinaHeight: 1200,
                  },
                  {
                    url: `/product_images/supplements/Fi-Multivitamin-Pouch-back.png`,
                    retinaWidth: 1200,
                    retinaHeight: 1200,
                  },
                  {
                    url: `/product_images/supplements/Fi-Multivitamin-ingredients.png`,
                    retinaWidth: 1200,
                    retinaHeight: 1200,
                  },
                  {
                    url: `/lifestyle_images/supplements/Fi-Multivitamin-lifestyle.png`,
                    retinaWidth: 1200,
                    retinaHeight: 1200,
                  },
                ]}
                squareAspectRatio
                showMobileGallerySelector
                padGallerySelectorImages
              />
            </div>
            <div className={styles.valueProps}>
              <ValuePropsBox valueProps={BENEFITS_VALUE_PROPS} />
            </div>
            <ProductSelector
              onSelectionChange={setSelectedProduct}
              supplementProducts={supplementItems}
              selectedProduct={selectedProduct}
              handlePurchase={handlePurchase}
              handlePurchaseLoading={!!purchaseLoading}
              flow="in-app"
            />

            <SupplementPurchaseButtons
              handlePurchase={
                billingInfo
                  ? handlePurchase
                  : () => {
                      setShowBillingForm(true);
                    }
              }
              showNoThanks={false}
              purchaseMutationLoading={!!purchaseLoading}
              selectedProduct={selectedProduct}
              error={error}
              flow="in-app"
            />
            <TermsAndConditions />
            <FAQ />
          </div>
        </>
      )}
    </>
  );
}
