import { useCallback, useContext, useMemo } from 'react';
import { useSelector } from 'react-redux';
import useShippingOptions from '../../hooks/useShippingOptions';
import CheckoutContext from '../../lib/CheckoutContext';
import { RecurlyApplePayUpdateResult } from '../../lib/RecurlyProvider';
import { useCartMode } from '../../lib/cartModes';
import { APPLE_PAY_ERROR_CODE_CUSTOM_API, APPLE_PAY_ERROR_CODE_SHIPPING, logInternalError } from '../../lib/errors';
import { priceCartForCheckout } from '../../lib/pricing';
import { centsToDollars } from '../../lib/util';
import * as types from '../../types';
import ApplePayPaymentState from './ApplePayPaymentState';
import { IApplePayEvents } from './Events';

interface UpdatePricingHookProps {
  events: IApplePayEvents;
  paymentState: ApplePayPaymentState;
  setCartPricing: (cartPricing: types.CartPricing) => void;
}

interface PaymentSheetLineItems {
  lineItems: ApplePayJS.ApplePayLineItem[];
  finalTotalLineItem: ApplePayJS.ApplePayLineItem;
}

interface CallbackProps {
  updateShippingMethods?: boolean;
}

/**
 * Maps our shipping options to the Apple Pay shipping methods interface for display on the Apple Pay payment sheet.
 */
function shippingOptionToApplePayShippingMethod(
  shippingOption: types.IShippingOption,
): ApplePayJS.ApplePayShippingMethod {
  return {
    amount: centsToDollars(shippingOption.priceInCents),
    label: shippingOption.name,
    identifier: shippingOption.code,
    detail: shippingOption.detail,
  };
}

/**
 * After calling the Fi pricing API, we can use this method to build the line items that will be displayed on the
 * Apple Pay payment sheet with that pricing information.
 *
 * @returns lineItems An array of line items to display on the Apple Pay payment sheet.
 * @returns finalTotalLineItem The final total to display on the Apple Pay payment sheet.
 */
function paymentSheetLineItems(
  cartPricing: types.CartPricing,
  paymentState: ApplePayPaymentState,
  upgradeCreditsPurchaseInvoice: boolean,
): PaymentSheetLineItems {
  const lineItems: ApplePayJS.ApplePayLineItem[] = [
    {
      label: 'Subtotal',
      amount: centsToDollars(cartPricing.subtotalInCents),
    },
  ];

  if (paymentState.selectedShippingCode) {
    lineItems.push({
      label: 'Shipping',
      amount: centsToDollars(cartPricing.shippingInCents ?? 0),
    });
  }

  if (cartPricing.appliedDiscountInCents) {
    lineItems.push({
      label: 'Discount',
      amount: `-${centsToDollars(cartPricing.appliedDiscountInCents)}`,
    });
  }

  if (cartPricing.appliedGiftCardAmountInCents) {
    lineItems.push({
      label: 'Gift Card',
      amount: `-${centsToDollars(cartPricing.appliedGiftCardAmountInCents)}`,
    });
  }

  if (upgradeCreditsPurchaseInvoice && cartPricing.upgradeCreditAmountInCents) {
    lineItems.push({
      label: 'Series 2 Credit',
      amount: `-${centsToDollars(cartPricing.upgradeCreditAmountInCents)}`,
    });
  }

  if (cartPricing.appliedAccountBalanceInCents) {
    lineItems.push({
      label: 'Account Balance',
      amount: `-${centsToDollars(cartPricing.appliedAccountBalanceInCents)}`,
    });
  }

  lineItems.push({
    label: 'Estimated Tax',
    amount: centsToDollars(cartPricing.taxInCents ?? 0),
  });

  // The S3 upgrade process will charge the full amount of the collar + subscription that's currently in the cart,
  // then it will execute a prorated refund the last invoice of the S2 subscription. Therefore, it's possible that the
  // refund would go to a different card than what is being used for the upgrade checkout. The Total returned by
  // the pricing API will include the credits amount since that's what we want displayed during checkout, however
  // the total on the Apple Pay payment sheet should reflect the amount we are actually charging to their card for
  // this Apple Pay checkout.
  //
  // UPDATE: upgradeCreditsPurchaseInvoice is now a site config flag that determines whether or not we are using the
  // new upgrade charge process. This new process will be crediting the prorated refund of the S2 subscription to the
  // invoice from purchasing the S3 upgrade so it will be _excluded_ from the total charged to the card.
  const totalToBeChargedToCard = upgradeCreditsPurchaseInvoice
    ? cartPricing.totalInCents
    : cartPricing.totalInCents + (cartPricing.upgradeCreditAmountInCents ?? 0);

  return {
    lineItems,
    finalTotalLineItem: {
      label: 'Total',
      amount: centsToDollars(totalToBeChargedToCard),
    },
  };
}

/**
 * Returns any errors we can display when the user selects their shipping address on the Apple Pay payment sheet.
 * At that time, we only have access to the country and postal code.
 */
function errorsFromPaymentState(paymentState: ApplePayPaymentState): ApplePayJS.ApplePayError[] {
  const errors: ApplePayJS.ApplePayError[] = [];

  if (paymentState.country !== 'US') {
    errors.push({
      code: APPLE_PAY_ERROR_CODE_SHIPPING,
      contactField: 'country',
      message: 'We do not currently ship outside of the US',
    });
  }

  return errors;
}

/**
 * @returns a callback that can be used to update the Apple Pay payment sheet with the latest pricing information
 *
 * This callback is intended to be called when a user makes a selection to shipping address or shipping method
 * on the Apple Pay payment sheet. The callback returns an object with errors to display on the Apple Pay payment
 * sheet, if any, or updates to the payment sheet line items.
 */
export default function useUpdatePaymentSheet({
  events,
  paymentState,
  setCartPricing,
}: UpdatePricingHookProps): (callbackProps?: CallbackProps) => Promise<RecurlyApplePayUpdateResult> {
  const { cart } = useContext(CheckoutContext);
  const { checkoutType } = useCartMode();

  const upgradeCreditsPurchaseInvoice = useSelector(
    (state: types.AppState) => state.config.siteConfig.series3UpgradeCreditsPurchaseInvoice ?? false,
  );

  const shippingOptions = useShippingOptions();

  const shippingMethods = useMemo(
    () => shippingOptions?.map(shippingOptionToApplePayShippingMethod) ?? [],
    [shippingOptions],
  );

  return useCallback(
    async ({ updateShippingMethods }: CallbackProps = {}) => {
      const addressErrors = errorsFromPaymentState(paymentState);
      if (addressErrors.length > 0) {
        return { errors: addressErrors };
      }

      try {
        const updatedCartPricing = await priceCartForCheckout(cart, {
          checkoutType,
          address: paymentState.toAddress() || undefined,
          shippingCode: paymentState.selectedShippingCode || undefined,
        });

        setCartPricing(updatedCartPricing);

        const { finalTotalLineItem, lineItems } = paymentSheetLineItems(
          updatedCartPricing,
          paymentState,
          upgradeCreditsPurchaseInvoice,
        );

        return {
          newTotal: finalTotalLineItem,
          newLineItems: lineItems,
          newShippingMethods: updateShippingMethods ? shippingMethods : undefined,
        };
      } catch (err) {
        // Error when trying to calculate pricing for the selected address and shipping method
        events.applePayError(err.message);
        logInternalError(err);

        return {
          errors: [
            {
              code: APPLE_PAY_ERROR_CODE_CUSTOM_API,
              message: err.message,
            },
          ],
        };
      }
    },
    [cart, checkoutType, events, paymentState, setCartPricing, shippingMethods, upgradeCreditsPurchaseInvoice],
  );
}
