import * as types from '../../types';
import { skusForCartItem } from '../cart';
import { getProductsBySku } from '../product';
import store from '../reduxStore';
import { centsToDollarsNumber } from '../util';

export interface CartInformation {
  purchasedCollar: boolean;
  purchasedBand: boolean;
  revenueIncludingFreeTrials: number;
  /**
   * Maximum number of years of any subscription in the cart.
   */
  maxYears: number;
}

export function getCartInformation(
  cartItems: types.CartItem[],
  cartPricing: types.CartPricing,
): CartInformation | null {
  let maxYears = 0;
  let revenueIncludingFreeTrials = centsToDollarsNumber(cartPricing.totalInCents);

  const products = store.getState().config.products;
  const productsBySku = getProductsBySku(products);
  let purchasedCollar = false;
  let purchasedBand = false;

  const skus = cartItems.flatMap((cartItem) => skusForCartItem(cartItem));
  for (const sku of skus) {
    const product = productsBySku.get(sku);
    if (!product) {
      continue;
    }

    if (types.isModuleSubscriptionProduct(product)) {
      if (product.renewalYears) {
        maxYears = Math.max(maxYears, product.renewalYears);
      }
      if (product.freeTrial) {
        revenueIncludingFreeTrials += centsToDollarsNumber(product.priceInCents);
      }
    }

    if (product.id.startsWith('smart-collar')) {
      purchasedCollar = true;
    }

    if (product.id.startsWith('additional-band')) {
      purchasedBand = true;
    }
  }

  return {
    purchasedCollar,
    purchasedBand,
    revenueIncludingFreeTrials,
    maxYears, // TODO: Figure out what the data team wants to see for monthly subscriptions.
  };
}

// Variables for Google Tag Manager.
const ORDER_ID_VARIABLE_NAME = `fi__orderIdForConversionTracking`;
const ORDER_TOTAL_VARIABLE_NAME = `fi__orderTotalForConversionTracking`;
const ORDER_EMAIL_VARIABLE_NAME = `fi__orderEmailForConversionTracking`;

export function setGlobalVariablesForConversionTracking(
  orderId: string,
  cartItems: types.CartItem[],
  cartPricing: types.CartPricing,
  email?: string,
) {
  const cartInformation = getCartInformation(cartItems, cartPricing);
  if (cartInformation && cartInformation.revenueIncludingFreeTrials) {
    (window as any)[ORDER_ID_VARIABLE_NAME] = orderId;
    (window as any)[ORDER_TOTAL_VARIABLE_NAME] = cartInformation.revenueIncludingFreeTrials;
    (window as any)[ORDER_EMAIL_VARIABLE_NAME] = email;
  }
}

export function clearGlobalVariablesForConversionTracking() {
  (window as any)[ORDER_ID_VARIABLE_NAME] = undefined;
  (window as any)[ORDER_TOTAL_VARIABLE_NAME] = undefined;
  (window as any)[ORDER_EMAIL_VARIABLE_NAME] = undefined;
}
