import { GrowthBook } from '@growthbook/growthbook-react';
import Cookies from 'js-cookie';

const GROWTHBOOK_FEATURE_LOAD_TIMEOUT_MS = 3_000;
// This prefix is defined in `web` repo as that is where the cookie is set
const OVERRIDE_COOKIE_PREFIX = 'fi_gb_ee_';

/**
 * A singleton wrapper around GrowthBook
 */
class FiGrowthBook {
  public readonly growthBook: GrowthBook;
  private loadedFeatures = false;

  constructor() {
    this.growthBook = new GrowthBook({
      apiHost: 'https://cdn.growthbook.io',
      // This is the client key for the Fi GrowthBook project
      // It can be found at https://app.growthbook.io/sdks
      clientKey: process.env.REACT_APP_GROWTHBOOK_CLIENT_KEY,
      // Update the instance in realtime as features change in GrowthBook
      subscribeToChanges: true,
      // Only required for A/B testing
      // Called every time a user is put into an experiment
      trackingCallback: (experiment, result) => {
        if (this.willBeForced(experiment.key)) {
          // Don't track feature variants that are being forced
          return;
        }
        // -1 indicates that user is not in the experiment
        const variantValue = result.inExperiment ? result.variationId.toString() : '-1';
        // Track analytics event
        analytics.track('User Viewed Experiment', {
          name: experiment.key,
          variant: variantValue,
        });
      },
    });
  }

  /**
   * Load all features from GrowthBook service
   * This is only necessary on initial load as `subscribeToChanges: true` will keep the instance up to date
   * If this fails, move on gracefully which will result in default feature value being used and reload will be
   * attempted on next instance retrieval
   */
  public async loadFeatures() {
    if (!this.loadedFeatures) {
      try {
        await this.growthBook.loadFeatures({ timeout: GROWTHBOOK_FEATURE_LOAD_TIMEOUT_MS });
        this.loadedFeatures = true;
      } catch (err) {
        // If GrowthBook is unavailable, we will still be able to load the app, but still attempt to load features
        // on the next request
        console.error({ err }, 'Error loading features from GrowthBook');
      }
    }

    this.setForcedOverrides();
  }

  /**
   * Set attributes that will be used to determine which variant the user is in
   */
  public setAttributes() {
    // It's possible for this to not exist due to ad blockers
    if (window.analytics && typeof window.analytics.user === 'function') {
      const anonymousId = window.analytics.user().anonymousId();
      const userId = window.analytics.user().id();

      this.growthBook.setAttributes({
        anonymousId: anonymousId,
        userEncodedUuid: userId,
        isLoggedIn: !!userId,
      });
    }
  }

  /**
   * Retrieves any force override cookies for all features and sets them in GrowthBook
   * @private
   */
  private setForcedOverrides(): void {
    const features = this.growthBook.getFeatures();
    const forcedFeatures: Map<string, any> = new Map();
    for (const [featureName] of Object.entries(features)) {
      // Check if override cookie is set
      const cookie = Cookies.get(FiGrowthBook.getCookieNameForForcedFeatureVariant(featureName));
      if (cookie) {
        // Add override to forced features
        forcedFeatures.set(featureName, coerceCookieValue(cookie));
      }
    }
    if (forcedFeatures.size > 0) {
      // Set overrides
      this.growthBook.setForcedFeatures(forcedFeatures);
    }
  }

  /**
   * Returns the value of a forced override cookie if it exists
   */
  private getForcedOverrideCookieValue(featureName: string): string | number | boolean | undefined {
    const cookie = Cookies.get(FiGrowthBook.getCookieNameForForcedFeatureVariant(featureName));
    if (cookie) {
      return coerceCookieValue(cookie);
    }
    return undefined;
  }

  /**
   * Returns the value of a feature, returning a forced override cookie first if present
   */
  public getFeatureValue<T>(featureName: string, defaultValue: T): T {
    const cookie = this.getForcedOverrideCookieValue(featureName);
    if (cookie !== undefined) {
      return cookie as T;
    }

    return this.growthBook.getFeatureValue<T>(featureName, defaultValue) as T;
  }

  /**
   * Returns true if the feature is being forced
   */
  private willBeForced(featureName: string): boolean {
    return !!Cookies.get(FiGrowthBook.getCookieNameForForcedFeatureVariant(featureName));
  }

  private static getCookieNameForForcedFeatureVariant(featureName: string) {
    return `${OVERRIDE_COOKIE_PREFIX}${featureName}`;
  }
}

const fiGrowthBook = new FiGrowthBook();

export function getFiGrowthBook() {
  return fiGrowthBook;
}

/**
 * Coerce cookie string value into a boolean, number, or string
 */
function coerceCookieValue(value: string): string | number | boolean {
  if (value === 'true') {
    return true;
  } else if (value === 'false') {
    return false;
  } else if (!isNaN(Number(value))) {
    return Number(value);
  }
  return value;
}
