import React from 'react';
import { ReactComponent as IconBone } from '../../../../../assets/images/icons/icon_bone.svg';
import { ReactComponent as IconHeart } from '../../../../../assets/images/icons/icon_heart.svg';
import { ReactComponent as IconStomach } from '../../../../../assets/images/icons/icon_stomach.svg';
import { ReactComponent as IconVirus } from '../../../../../assets/images/icons/icon_virus.svg';
import styles from '../../../../ProductDetails/ProductDetails.module.scss';
import { ValueProp } from '../../../../ProductDetails/Series3CollarDetails/components/ValuePropsBox';

export const BENEFITS_VALUE_PROPS: ValueProp[] = [
  {
    icon: <IconBone />,
    text: (
      <div className={styles.valueProp}>
        <span className={styles.valuePropHeader}>Promotes healthy joints and reduces inflammation</span>
      </div>
    ),
  },
  {
    icon: <IconStomach />,
    text: (
      <div className={styles.valueProp}>
        <span className={styles.valuePropHeader}>Supports gut function and metabolism</span>
      </div>
    ),
  },
  {
    icon: <IconHeart />,
    text: (
      <div className={styles.valueProp}>
        <span className={styles.valuePropHeader}>Supports heart health, liver health and brain function</span>
      </div>
    ),
  },
  {
    icon: <IconVirus />,
    text: (
      <div className={styles.valueProp}>
        <span className={styles.valuePropHeader}>Helps improve antioxidant levels and overall immune system</span>
      </div>
    ),
  },
];
